

@import './custom';

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
// @import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// @import "~select2/dist/css/select2";
// @import "~/slick-carousel/slick/slick.scss";
// @import "~/slick-carousel/slick/slick-theme.scss";

$keyColor: #43a5d7;
$baseColor: #f5f7f8;
$ligheBlue:#edf8ff;

// @import './component/easing';
@import './component/position';
@import './component/line-height';

@import './layout';
@import './module/header';
@import './module/footer';
@import './page/index';
@import './page/under';
@import './page/service';




body{
    // background: rgba(0,0,255,0.66);
    // @include media-breakpoint-down(lg) { background: rgba(255,0,0,0.1);}
    // @include media-breakpoint-down(md) { background: rgba(0,255,0,0.1);}
    // @include media-breakpoint-down(sm) { background: rgba(0,0,255,0.1);}
    // @include media-breakpoint-down(xs) { background: rgba(0,0,0,0.1);}
}









