// ==========================================

// ヘッダー

// ==========================================
header#header .global{ background: #FFF; }
@include media-breakpoint-down(lg) {}
@include media-breakpoint-down(md) {}
@include media-breakpoint-down(sm) {}
@include media-breakpoint-down(xs) {}

// - - - - - - - - - - - - - - - - - - - - 

// ロゴ

// - - - - - - - - - - - - - - - - - - - - 
header#header .global .id {
    width: 140px;
    top: 0;
    left: 0;
    line-height: 1;
    @include media-breakpoint-down(lg) { width: 130px;}
    @include media-breakpoint-down(md) { width: 115px;}
    @include media-breakpoint-down(sm) { width: 95px;}
    @include media-breakpoint-down(xs) { width: 75px;}
    a {
        opacity: 1;
        transition: 0.7s;
        &:hover {
            opacity: 0.5;
        }
    }
}

// - - - - - - - - - - - - - - - - - - - - 

// グローバルナビ

// - - - - - - - - - - - - - - - - - - - - 
header#header .global .gnav {
    > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        > li {
            display: block;
            margin: 0;
            padding: 0.25em 0.5em;
            border-left: #e7e7e7 solid 1px;
            &:first-child,
            &:last-child { border-left: none; }
            a {
                display: block;
                padding: 0 2.5em;
                line-height: 1.6;
                color: #333;
                font-size: 13px;
                font-weight: 900;
                position: relative;
                transition: color 0.2s 0.06s;
                &:after {
                    content: "";
                    display: block;
                    width: 0;
                    height: 100%;
                    background: $keyColor;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 0;
                    transition: width 0.2s;
                }
                // &:before {
                //     content: "";
                //     display: block;
                //     width: 0;
                //     height: 0;
                //     border-style: solid;
                //     border-width: 6px 6px 0 0;
                //     border-color: #ffffff transparent transparent transparent;
                //     position: absolute;
                //     top: 1px;
                //     left: 1px;
                //     z-index: 1;
                // }
                > span {position: relative; z-index: 1;}
            }
            &.contact {
                padding: 0;
                a {
                    height: 94px;
                    line-height: 94px;
                    padding: 0 6em;
                    background: #ffcb3e;
                    &:before { content: none; }
                }
            }
            @include media-breakpoint-up(md) {
                a:hover {
                    color: #FFF;
                    &:after { width: 100%; }
                }
            }
            @include media-breakpoint-down(lg) {
                padding: 0.25em 0.25em;
                a { padding: 0 2em; }
                &.contact {
                    a {
                        height: 85px;
                        line-height: 85px;
                        padding: 0 5em;
                    }
                }
            }
            @include media-breakpoint-down(md) {
                padding: 0.25em 0.15em;
                a {
                    padding: 0 1.5em;
                    font-size: 12px;
                }
                &.contact {
                    a {
                        height: 75px;
                        line-height: 75px;
                        padding: 0 3em;
                    }
                }
            }
        }
    }
}

// ==========================================

// グローバルナビ／バーガー（スマホ）

// ==========================================
header#header .global .burger {
    justify-content: flex-end;
    width: 100%;
    height: 70px;
    @include media-breakpoint-down(xs) { height: 55px; }
    .triggerwrap {
        width: 70px;
        height: 100%;
        position: relative;
        @include media-breakpoint-down(xs) { width: 55px; }
    }
}

// ==========================================

// バーガーメニュー

// ==========================================
body {
    header#header .global .burger .trigger {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        transition: all .3s;
        span {
            display: block;
            transition: all .3s;
            box-sizing: border-box;
            position: absolute;
            left: calc((100% - 55%) * 0.5);
            width: 55%;
            height: 1px;
            background-color: #333;
            border-radius: 10px;
            &:nth-of-type(1) { top: 27%; }
            &:nth-of-type(2) { top: calc(50% - 1px); }
            &:nth-of-type(3) { bottom: 27%; }
        }
    }
    &.navOn header#header .global .burger .trigger {
        transform: rotate(270deg);
        span {
            &:nth-of-type(1) {
                height: 1px;
                top: calc(50% - 1px);
                transform: rotate(-45deg);
            }
            &:nth-of-type(2) {
                height: 0;
                opacity: 0;
            }
            &:nth-of-type(3) {
                height: 1px;
                bottom: calc(50% - 1px);
                transform: rotate(45deg);
            }
        }
    }
}

// ==========================================

// グローバルナビ（モバイル）

// ==========================================
#modileGnav { display: none; }
@include media-breakpoint-down(sm) {
    body {
        #modileGnav {
            display: block;
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255,255,255,0.97);
            .navflex {
                width: 100%;
                height: 100%;
            }
            ul.navwrap {
                width: 100%;
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    a {
                        padding: 10px 0;
                        text-align: center;
                        color: $keyColor;
                        font-size: 5vw;
                        font-weight: 700;
                        opacity: 0;
                        transform: translateY(15px) scale(0.95);
                    }
                    &:last-child a { border-bottom: none;}
                }
            }
        }
    }
    body.navOn {
        overflow: hidden;
        #modileGnav {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.24s;
            position: relative;
            ul.navwrap {
                position: relative;
                z-index: 1;
                li {
                    a {
                        opacity: 1;
                        transform: translateY(0) scale(1);
                        transition-property: all;
                        transition-duration: 0.2s;
                    }
                    &:nth-child(1) a {transition-delay: calc(1 * 0.05s);}
                    &:nth-child(2) a {transition-delay: calc(2 * 0.05s);}
                    &:nth-child(3) a {transition-delay: calc(3 * 0.05s);}
                    &:nth-child(4) a {transition-delay: calc(4 * 0.05s);}
                    &:nth-child(5) a {transition-delay: calc(5 * 0.05s);}
                    &:nth-child(6) a {transition-delay: calc(6 * 0.05s);}
                }
            }
            p.closer {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
