// ==========================================

// 下層ページ

// ==========================================
main#mainContent.under { background: $baseColor;}

.link a {
	position: relative;
	display: inline-block;
	transition: .3s;
	font-weight: 900;
	color: #43a5d7;
	&::after {
		position: absolute;
		bottom: 0;
		left: 0;
		content: '';
		width: 0;
		height: 1px;
		background-color: #43a5d7;
		transition: .3s;
	}
	&:hover::after {
		width: 100%;
	}
}
// ==========================================

// コンテンツ見出し

// ==========================================
main#mainContent.under .page-head{
	height: 324px;
	background: $baseColor;
	@include media-breakpoint-down(lg) { height: 300px;}
	@include media-breakpoint-down(md) { height: 276px;}
	@include media-breakpoint-down(sm) { height: 242px;}
	@include media-breakpoint-down(xs) { height: 218px;}
	.title {
		text-align: center;
		color: #333;
		font-size: 28px;
		font-weight: 900;
		@include media-breakpoint-down(lg) { font-size: 27px;}
		@include media-breakpoint-down(md) { font-size: 26px;}
		@include media-breakpoint-down(sm) { font-size: 24px;}
		@include media-breakpoint-down(xs) { font-size: 22px;}
		small {
			display: block;
			margin: 2em 0 0;
			font-size: 12px;
			font-weight: 700;
			@include media-breakpoint-down(sm) { font-size: 11px;}
			@include media-breakpoint-down(xs) { font-size: 10px;}
		}
		&:after {
			content: "";
			display: block;
			width: 1.25em;
			height: 5px;
			margin: 1.25em auto 0;
			padding: 0;
			background: #000;
		}
	}
}


// ==========================================

// パンくず

// ==========================================
main#mainContent.under .breadcrumb {
    margin: 0;
    padding: 0;
    background: #FFF;
    ul {
		margin: 0;
        padding: 2.25em 0;
        list-style: none;
        font-size: 11px;
        li {
            &:not(:last-child) {
				a {color: #959595;}
				&:after {
					display: inline-block;
					content: ">";
					width: 2em;
					text-align: center;
					color: #333;
				}
			}
			&:last-child {
				a {
					color: #333;
					font-weight: 700;
				}
			}
        }
    }
    @include media-breakpoint-down(sm) { display: none;}
}



@include media-breakpoint-down(lg) {}
@include media-breakpoint-down(md) {}
@include media-breakpoint-down(sm) {}
@include media-breakpoint-down(xs) {}

// ==========================================

// コンテンツ カラムレイアウト

// ==========================================
main#mainContent.under .underColumn {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin: 0;
	padding: 45px 0 0;
	> .main { width: 800px; }
	> .sub { 
		width: 350px;
		position: sticky;
		top: 30px;
		}
	@include media-breakpoint-down(lg) {
		padding: 40px 0 0;
		> .main { width: 600px; }
		> .sub { width: 280px; }
	}
	@include media-breakpoint-down(md) {
		padding: 35px 0 0;
		flex-direction: column;
		> .main { width: 100%; }
		> .sub { width: 100%; margin-top: 50px;}
	}
	@include media-breakpoint-down(sm) {
		padding: 0;
		> .sub { margin-top: 45px;}
	}
	@include media-breakpoint-down(xs) {
		> .sub { margin-top: 40px;}
	}
}

main#mainContent.under .underColumn .main .content{
	display: block;
	background: #FFF;
	border-radius: 18px 18px 0 0;
	overflow: hidden;
	.pad { padding: 55px 40px; }
	@include media-breakpoint-down(lg) {
		.pad { padding: 45px 36px; }
	}
	@include media-breakpoint-down(sm) {
		.pad { padding: 35px 30px; }
	}
	@include media-breakpoint-down(xs) {
		.pad { padding: 25px 15px; }
	}
}

main#mainContent.under .underColumn .main .head {
	.title {
		color: $keyColor;
		font-size: 24px;
		@include media-breakpoint-down(lg) {}
		@include media-breakpoint-down(md) {font-size: 22px;}
		@include media-breakpoint-down(sm) {font-size: 20px;}
		@include media-breakpoint-down(xs) {}
	}
	.client {
		font-size: 16px;
		@include media-breakpoint-down(lg) {}
		@include media-breakpoint-down(md) {font-size: 15px;}
		@include media-breakpoint-down(sm) {}
		@include media-breakpoint-down(xs) {}
	}
	.read {
		font-size: 13px;
		@include media-breakpoint-down(lg) {}
		@include media-breakpoint-down(md) {font-size: 12px;}
		@include media-breakpoint-down(sm) {}
		@include media-breakpoint-down(xs) {}
	}
}

main#mainContent.under .underColumn .main .body {
	line-height: 2;
	font-size: 14px;
	h4 {
		display: block;
		margin: 2em 0 0;
		padding: 0.125em 0 0.125em 0.5em;
		font-size: 24px;
		font-weight: 700;
		line-height: 1.6;
		border-left: #43a3d7 solid 7px;
		@include media-breakpoint-down(lg) {}
		@include media-breakpoint-down(md) {
			font-size: 22px;
			border-left-width: 6px;
		}
		@include media-breakpoint-down(sm) {
			font-size: 20px;
			border-left-width: 5px;
		}
	}
	p {
		margin: 2em 0 0;
		@include media-breakpoint-down(sm) {
			margin: 1.8em 0 0;
		}
	}
	figure.wp-block-image {
		margin: 2em 0 0;
	}
	figure.wp-block-embed-youtube {
		margin: 2em 0 0;
		.wp-block-embed__wrapper{
			position:relative;
			width:100%;
			padding-top:56.25%;
		}
		.wp-block-embed__wrapper iframe{
		position:absolute;
		top:0;
		right:0;
		width:100%;
		height:100%;
		}
	}
}

main#mainContent.under .underColumn .main .foot {
	margin: 50px 0 0;
	@include media-breakpoint-down(lg) { margin: 45px 0 0;}
	@include media-breakpoint-down(sm) { margin: 40px 0 0;}
	/*
	.link {
		a {
			color: #1c75ad;
			font-weight: 700;
			text-decoration: underline;
		}
	}
	*/
	.section-link {
		margin: 75px 0 0;
		@include media-breakpoint-down(lg) { margin: 70px 0 0;}
		@include media-breakpoint-down(md) { margin: 60px 0 0;}
		@include media-breakpoint-down(sm) { margin: 55px 0 0;}
		@include media-breakpoint-down(xs) { margin: 50px 0 0;}
		a {
			display: block;
			width: auto;
			max-width: 375px;
			height: auto;
			margin: 0 auto;
			padding: 1.5em 0;
			line-height: 1;
			text-align: center;
			color: #FFF;
			font-size: 18px;
			font-weight: 700;
			border-radius: 100px;
			background: linear-gradient(to right, #43a2d7 0%,#43b6d7 100%);
			position: relative;
			overflow: hidden;
			transition: all 0.3s 0.06s;
			> span { position: relative; z-index: 1;}
			&:before,
			&:after {
				content: "";
				display: block;
				width: 50%;
				height: 100%;
				position: absolute;
				top: 0;
				z-index: 0;
				background: #FFF;
				opacity: 0;
				transition: all 0.3s;
			}
			&:before {
				transform: translateX(-110%) skew(-45deg);
				left: 0;
			}
			&:after {
				transform: translateX(110%) skew(-45deg);
				right: 0;
			}
			@include media-breakpoint-down(lg) {
				font-size: 17px;
			}
			@include media-breakpoint-down(md) {
				font-size: 16px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 15px;
			}
			@include media-breakpoint-down(xs) {
				font-size: 15px;
			}
			@include media-breakpoint-up(md) {
				&:hover {
					color: $keyColor;
					&:before,
					&:after {
						transform: translateX(0) skew(5deg);
						opacity: 0.77;
					}
				}
			}
		}
	}
}

main#mainContent.under .underColumn .sub .base{
	background: #FFF;
	border-radius: 10px;
	box-shadow:0px 1px 15px 0 rgba(0,0,0,0.08);
	.pad {
		padding: 30px;
		font-size: 13px;
		@include media-breakpoint-down(lg) { padding: 22px;}
		@include media-breakpoint-down(md) { padding: 30px;}
		@include media-breakpoint-down(sm) { padding: 26px;}
		@include media-breakpoint-down(xs) { padding: 22px;}
		.info {
			display: table;
			table-layout: fixed;
			width: 100%;
			> dl {
				display: table-row;
				line-height: 1.4;
				> dt {
					display: table-cell;
					width: 5.5em;
				}
				> dd {
					display: table-cell;
					padding: 0 0 1.25em 0.5em;
				}
			}
		}
		/*
		.link {
			a {
				color: #1c75ad;
				font-weight: 700;
				text-decoration: underline;
			}
		}
		*/
		.detail {
			margin: 2em 0 0;
			line-height: 1.8;
		}
	}
}

main#mainContent.under .underColumn .sub {
	.inquiry {
		margin: 40px 0 0;
		@include media-breakpoint-down(lg) { margin: 40px 0 0;}
		@include media-breakpoint-down(md) { margin: 40px 0 0;}
        a {
			display: block;
            margin: 0;
            padding: 1.75em 0;
			line-height: 1;
			text-align: center;
            color: #000;
			font-size: 15px;
			font-weight: 700;
            background: #ffcb3e;
            border-radius: 55px;
            position: relative;
            overflow: hidden;
            transition: color 0.16s 0.06s;
            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 0;
                background: $keyColor;
                position: absolute;
                top: 50%;
                left: 0;
                transform: rotate(-12deg);
                transition: all 0.16s;
            }
            span { position: relative; z-index: 1;}
            @include media-breakpoint-up(md) {
                &:hover {
                    color: #FFF;
                    &:after {
                        height: 100%;
                        top: 0;
                        transform: rotate(0);
                    }
                }
            }
        }
	}
}





// ==========================================

// その他の導入事例

// ==========================================
main#mainContent.under section.case {
	padding: 80px 0;
	background: $baseColor;
	@include media-breakpoint-down(lg) { padding: 70px 0;}
	@include media-breakpoint-down(md) { padding: 60px 0 0 0;}
	@include media-breakpoint-down(sm) { padding: 50px 0 0 0;}
	@include media-breakpoint-down(xs) { padding: 40px 0 0 0;}

	.section-head {
		color: #333;
		font-size: 28px;
		font-weight: 900;
		@include media-breakpoint-down(lg) { font-size: 27px;}
		@include media-breakpoint-down(md) { font-size: 26px;}
		@include media-breakpoint-down(sm) { font-size: 24px;}
		@include media-breakpoint-down(xs) { font-size: 22px;}
	}

	.row {
		margin-top: 50px;
		@include media-breakpoint-down(lg) { margin-top: 45px;}
		@include media-breakpoint-down(md) { margin-top: 40px;}
		@include media-breakpoint-down(sm) { margin-top: 35px;}
		@include media-breakpoint-down(xs) { margin-top: 30px;}
	}
	.item {
		&:not(:first-child) {
			@include media-breakpoint-down(sm) { margin-top: 30px; }
			@include media-breakpoint-down(xs) { margin-top: 25px; }
		}
		> a {
			box-shadow: 0 0 8px #DDD;
			height: 100%;
			background: #FFF;
			dl {
				display: block;
				margin: 0;
				padding: 22px 24px;
				@include media-breakpoint-down(lg) { padding: 20px 22px;}
				@include media-breakpoint-down(md) { padding: 16px 20px;}
				@include media-breakpoint-down(sm) { padding: 1em 1.5em;}
				@include media-breakpoint-down(xs) { padding: 0.75em 1em;}
				> dt {
					color: $keyColor;
					@include media-breakpoint-down(lg) { font-size: 15px;}
					@include media-breakpoint-down(sm) { font-size: 14px;}
					@include media-breakpoint-down(xs) { font-size: 13px;}
				}
				> dd {
					color: #333;
					font-size: 14px;
					font-weight: 900;
					@include media-breakpoint-down(sm) { font-size: 12px;}
					@include media-breakpoint-down(xs) { font-size: 11px;}
				}
			}
			@include media-breakpoint-up(md) {
				position: relative;
				&:before,
				&:after {
					content: "";
					display: block;
					width: 0;
					height: 0;
					box-sizing: border-box;
					position: absolute;
					border-color: $keyColor;
					border-style: solid;
					opacity: 0;
					transition: all 0.2s;
				}
				&:before {
					top: 0;
					left: 0;
					border-width: 6px 0 0 6px;
				}
				&:after {
					bottom: 0;
					right: 0;
					border-width: 0 6px 6px 0;
				}
				&:hover {
					&:before,
					&:after {
						opacity: 1;
						width: 100%;
						height: 100%;
						transition: all 0.3s;
					}
				}
			}
		}
	}
	.section-link {
		display: block;
		width: auto;
		max-width: 530px;
		margin: 0 auto;
		padding: 80px 0 0;
		@include media-breakpoint-down(lg) { padding: 70px 0 0;}
		@include media-breakpoint-down(md) { padding: 60px 0 0;}
		@include media-breakpoint-down(sm) { margin: 0 15px; padding: 50px 0 0;}
		@include media-breakpoint-down(xs) { padding: 40px 0 0;}
	}
}

main#mainContent.under section.case_list {
	padding: 80px 0;
	background: $baseColor;
	@include media-breakpoint-down(lg) { padding: 70px 0;}
	@include media-breakpoint-down(md) { padding: 60px 0;}
	@include media-breakpoint-down(sm) { padding: 0;}
	@include media-breakpoint-down(xs) { padding: 0;}

	.section-head {
		color: #333;
		font-size: 28px;
		font-weight: 900;
		@include media-breakpoint-down(lg) { font-size: 27px;}
		@include media-breakpoint-down(md) { font-size: 26px;}
		@include media-breakpoint-down(sm) { font-size: 24px;}
		@include media-breakpoint-down(xs) { font-size: 22px;}
	}

	.row {
		@include media-breakpoint-down(lg) { margin-top: 0;}
		@include media-breakpoint-down(md) { margin-top: 0;}
		@include media-breakpoint-down(sm) { margin-top: 0;}
		@include media-breakpoint-down(xs) { margin-top: 0;}
	}
	.item {
		&:not(:first-child) {
			@include media-breakpoint-down(sm) { margin-top: 30px; }
			@include media-breakpoint-down(xs) { margin-top: 0; }
		}
		> a {
			box-shadow: 0 0 8px #DDD;
			height: 100%;
			background: #FFF;
			dl {
				display: block;
				margin: 0;
				padding: 22px 24px;
				@include media-breakpoint-down(lg) { padding: 20px 22px;}
				@include media-breakpoint-down(md) { padding: 16px 20px;}
				@include media-breakpoint-down(sm) { padding: 1em 1.5em;}
				@include media-breakpoint-down(xs) { padding: 0.75em 1em;}
				> dt {
					color: $keyColor;
					@include media-breakpoint-down(lg) { font-size: 15px;}
					@include media-breakpoint-down(sm) { font-size: 14px;}
					@include media-breakpoint-down(xs) { font-size: 13px;}
				}
				> dd {
					color: #333;
					font-size: 14px;
					font-weight: 900;
					@include media-breakpoint-down(sm) { font-size: 12px;}
					@include media-breakpoint-down(xs) { font-size: 11px;}
				}
			}
			@include media-breakpoint-up(md) {
				position: relative;
				&:before,
				&:after {
					content: "";
					display: block;
					width: 0;
					height: 0;
					box-sizing: border-box;
					position: absolute;
					border-color: $keyColor;
					border-style: solid;
					opacity: 0;
					transition: all 0.2s;
				}
				&:before {
					top: 0;
					left: 0;
					border-width: 6px 0 0 6px;
				}
				&:after {
					bottom: 0;
					right: 0;
					border-width: 0 6px 6px 0;
				}
				&:hover {
					&:before,
					&:after {
						opacity: 1;
						width: 100%;
						height: 100%;
						transition: all 0.3s;
					}
				}
			}
		}
	}
	.section-link {
		display: block;
		width: auto;
		max-width: 530px;
		margin: 0 auto;
		padding: 80px 0 0;
		@include media-breakpoint-down(lg) { padding: 70px 0 0;}
		@include media-breakpoint-down(md) { padding: 60px 0 0;}
		@include media-breakpoint-down(sm) { margin: 0 15px; padding: 50px 0 0;}
		@include media-breakpoint-down(xs) { padding: 40px 0 0;}
	}
}

// ==========================================

// NEWS

// ==========================================
main#mainContent.under section.news_list {
	padding: 80px 0;
	@include media-breakpoint-down(xs) { padding: 0; }
	.item {
		a {
			background-color: #FFF;
			padding: 20px 30px;
			transition: background-color 0.5s;
			&:hover {
				background-color: #cfe7f4;
			}
			dl {
				margin: 0;
				display: flex;
				@include media-breakpoint-down(md) {display: block; }
				dt {
					width: 15%;
					@include media-breakpoint-down(lg) {width: 25%;}
					@include media-breakpoint-down(md) {width: 100%; margin-bottom: 5px; }
					color: #333;
				}
				dd {
					width: 85%;
					@include media-breakpoint-down(lg) {width: 75%;}
					@include media-breakpoint-down(md) {width: 100%; }
				}
			}
		}

	}
}

main#mainContent.under section.news {
	max-width: 900px;
	margin: 0 auto;
	padding: 80px 0 0 0;
	@include media-breakpoint-down(sm) { padding: 0;}
	article {
		background-color: #FFF;
		padding: 50px;
		@include media-breakpoint-down(md) {padding: 30px; }
		line-height: 1.6;
		figure {
			margin-bottom: 2em;
			figcaption {
				font-size: 0.8em;
				margin-top: 1em;
				color: #555;
			}
		}
		dl {
			margin-bottom: 20px;
			dt {
				margin-bottom: 10px;
			}
			dd {
				font-size: 1.3em;
				color: #43a2d7;
				font-weight: 900;
			}
		}
		h4 {
			font-weight: 900;
			font-size: 1.1em;
			margin-bottom: 1em;
			background: #f5f7f8;
			padding: 1em 1.5em;
		}
		p {
			margin-bottom: 2em;
			&:last-child {
				margin: 0;
			}
		}
		ul {
			margin-bottom: 2em;
			&.fw-5 {
				font-size: 1.2em;
			}
		}
	}
}

// ==========================================

// CONTACT

// ==========================================
main#mainContent.under section.contact {
	padding: 80px 0 0 0;
	@include media-breakpoint-down(sm) { padding: 0; }
	.container {
		max-width: 900px;
		background-color: #FFF;
		padding: 50px;
		@include media-breakpoint-down(md) { padding: 30px; }
		.cp {
			margin-bottom: 3em;
		}
		.inquiry_body {
			word-break: break-all;
			form {
				.form_table {
					width: 100%;
					.form1,.form2 {
						width: 100%;
					}
				}
			}
		}
	}
}

.table-row dt {
	margin-bottom: 0.5em;
	span.required {
		font-size: 0.7em;
		background: #e6562f;
		border-radius: 5px;
		color: #FFF;
		margin-right: 10px;
		padding: 2px 5px;
	}
}
input[type="submit"],
input[type="text"],
select,
textarea,
button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: none;
}
 
 
input[type="text"],
textarea {
  background: #f3f3f3;
  display: block;
  font-size: 16px;
  padding: 12px 15px;
  transition: 0.8s;
  border-radius: 0;
  &.error {
    background: #e7f6ff;
  }
}
 
input[type="text"]:focus,
textarea:focus {
  background: #e7f6ff;
  &.error {
    background: #e7f6ff;
  }
}
 
textarea[name="content"] {
  display: inline-block;
  width: 100%;
  height: 200px;
}
 
input::placeholder,
textarea::placeholder {
  color: #ccc;
}
 
::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}
 
::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}
 
:-ms-input-placeholder {
  color: #ccc;
  opacity: 1;
}

.error {
	color: #cd1e1e;
	font-size: 0.9em;
}

.contact-btn-wrap .btn{
    display: block;
    width: 100%;
    max-width: 530px;
    height: 4em;
    margin: 0 auto;
    padding: 0;
    line-height: 4;
    color: #FFF;
    font-size: 18px;
    font-weight: 700;
    background: #1f9cd6;
    @include media-breakpoint-down(md) {  font-size: 16px; }
    @include media-breakpoint-down(sm) {  font-size: 14px; }
    @include media-breakpoint-down(xs) {  font-size: 13px; }
    @include media-breakpoint-up(lg) {
        &:hover {
            background: #0e86bd;
            transition: all 0.2s;
        }
    }

}

.confirm-btn-wrap {
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
    }

    .btn-wrap {
        margin: 0 10px;
        @include media-breakpoint-down(sm) { margin: 0 auto;}
    }
    
    .btn {
        display: block;
        color: #FFF;
        &.send {
            width: 280px;
            height: 4em;
            margin: 0 auto;
            padding: 0;
            line-height: 1;
            font-size: 18px;
            font-weight: 700;
            background: #1e9bd5;
            @include media-breakpoint-down(md) {  font-size: 16px; }
            @include media-breakpoint-down(sm) {  font-size: 14px; }
            @include media-breakpoint-down(xs) {  font-size: 13px; }
            @include media-breakpoint-up(lg) {
                &:hover {
                    background: #0e8ac3;
                    transition: all 0.2s;
                }
            }

        }
        &.back {
            height: 3em;
            width: 180px;
            margin: 1em auto;
            line-height: 1;
            font-size: 85%;
            background: #949494;
            @include media-breakpoint-up(lg) {
                &:hover {
                    background: #505050;
                    transition: all 0.2s;
                }
            }
        }


    }

}


.confirm {
    dl {
        dd {
            background: #EEE;
            padding: 1em;
        }
    }
}

section.privacy {
	padding: 80px 0 0 0;
	@include media-breakpoint-down(xs) { padding: 0; }
	.container {
		background-color: #FFF;
		padding: 50px;
		.cp {
			margin-bottom: 2em;
		}
		dl {
			margin-bottom: 2em;
			dt {
				margin-bottom: 1em;
			}
		}
		.p_area {
			h3 {
				font-size: 1em;
			}
		}
	}
}

section.company {
	padding: 80px 0 0 0;
	@include media-breakpoint-down(xs) { padding: 0; }
	.container {
		background-color: #FFF;
		padding: 30px;
		.content {
			display: table;
			width: 100%;
			max-width: 100%;
			font-size: .9em;
			dl {
				display: table-row;
				line-height: 1.6;
				&:last-child {
					dt,dd {
						border: none;
					}
				}
				dt {
					display: table-cell;
					padding: 1.5em;
					width: 15em;
					font-weight: 900;
					border-bottom: 1px solid #3ea3d8;
					@include media-breakpoint-down(md) {  width: 15em; }
					@include media-breakpoint-down(sm) {  width: 8em; }
					@include media-breakpoint-down(xs) {  width: 6em; }
				}
				dd {
					display: table-cell;
					padding: 1.5em;
					border-bottom: 1px solid #ccc;
					ul {
						padding: 0;
						li {
							list-style-type: none;
						}
					}
					div {
						margin-bottom: 0.5em;
					}
				}
			}
		}
	}
}

main#mainContent.under {
	padding-bottom: 120px;
	@include media-breakpoint-down(sm) {padding-bottom: 60px;}
	div.about {
		margin-top: 80px;
		padding: 120px 0 100px;
		@include media-breakpoint-down(lg) { padding: 80px 0 60px;}
		@include media-breakpoint-down(md) {padding: 60px 0 20px;}
		@include media-breakpoint-down(sm) {padding: 60px 0 20px; margin-top:0;}
    	background: #FFF;
		> .content {
			width: auto;
			max-width: 1000px;
			margin: 0 auto;
			@include media-breakpoint-down(lg) { max-width: 800px;}
			@include media-breakpoint-down(md) { max-width: 620px;}
			@include media-breakpoint-down(sm) { max-width: 460px;}
			@include media-breakpoint-down(xs) { max-width: 100%; margin: 0 15px;}
			.kv {
				margin-bottom: 3em;
			}
			h2 {
				font-size: 1.5em;
				line-height: 2;
				font-family: TsukuARdGothicStd-E;
				letter-spacing: .1em;
				@include media-breakpoint-down(xs) { font-size: 1.2em; }
				p {
					margin-bottom: 2em;
				}
				br {
					@include media-breakpoint-down(xs) { display: none; }
				}
			}
		}
	}
}

// ==========================================

// FARMER

// ==========================================

main#mainContent.under {
	.farmer {
		margin-top: 80px;
		@include media-breakpoint-down(lg) {}
		@include media-breakpoint-down(md) {}
		@include media-breakpoint-down(sm) { margin: 0;}
		@include media-breakpoint-down(xs) { margin: 0;}
		.content {
			.inner {
			background-color: #fff;
    		padding: 40px;
			@include media-breakpoint-down(sm) { padding: 20px;}
				.subtitle {
					margin-top: 40px;
					font-weight: 900;
					text-align: center;
					font-size: 30px;
					margin-bottom: 20px;
					@include media-breakpoint-down(md) { 
						margin-top: 20px;
						font-size: 20px;
					}
				}
				.subdesc {
					margin: 0 auto;
					max-width: 780px;
				}
				.point {
					margin-top: 40px;
					display: flex;
					flex-wrap: wrap;
					.single {
						width: 25%;
						padding: 0 20px;
						@include media-breakpoint-down(lg) {width: 50%;}
						@include media-breakpoint-down(md) {padding: 10px;}
						dl {
							margin-top: 10px;
							dt {
								margin-bottom: 5px;
								font-size: 18px;
								@include media-breakpoint-down(md) {font-size: 16px;}
							}
						}
					}
				}
				.subtitle2 {
					margin-top: 40px;
					font-weight: 900;
					text-align: center;
					font-size: 40px;
					margin-bottom: 20px;
					color: #43a2d7;
					br {
						@include media-breakpoint-down(sm) { display: none;}
					}
					@include media-breakpoint-down(sm) { 
						margin-top: 20px;
						font-size: 22px;
					}
				}
			}
		}
	}
}


main#mainContent.under {
	.farmer {
		.content {
			.inner {
				.merit {
					margin-top: 40px;
					.title {
					}
					.merit_point {
						max-width: 1000px;
						margin: 40px auto 0 auto;
						.single {
							display: flex;
							border: 1px solid #ddd;
							margin-bottom: 20px;
							@include media-breakpoint-down(sm) { display: block;}
							p {
								width: 600px;
								img {
								}
							}
							dl {
								padding: 40px 40px 0 40px;
								margin: 0;
								@include media-breakpoint-down(lg) {
									padding: 20px 20px 0 20px;
								}
								@include media-breakpoint-down(sm) { padding: 20px;}
								dt {
									font-size: 28px;
									font-weight: 900;
									margin-bottom: 5px;
									color: #43a2d7;
									@include media-breakpoint-down(lg) {
										font-size: 20px
									}
								}
							}
						}
					}
					.check {
						max-width: 1000px;
						margin: 40px auto 0 auto;
						padding: 30px ;
						border: 10px solid #c6e2f1;
						border-radius: 50px;
						font-weight: 900;
						text-align: center;
						font-size: 28px;
						color: #43a2d7;
						br {
							@include media-breakpoint-down(lg) {display: none;}
						}
						@include media-breakpoint-down(lg) {
							font-size: 20px;
						}
					}
				}
			}
		}
	}
}

// ==========================================

//  独自のサポート体制

// ==========================================
main#mainContent.under .support {
	max-width: 1000px;
	margin: 60px auto 0 auto;
	background: #FFF;
	padding: 100px 0 60px;
	@include media-breakpoint-down(md) { padding: 70px 0 50px;}
	@include media-breakpoint-down(sm) { padding: 50px 0 40px; margin: 20px auto 0 auto;}
	@include media-breakpoint-down(xs) { padding: 40px 0 30px;}

	.section-head {
		display: inline-block;
		margin: 0 auto 1em;
		padding: 0 0 0.5em;
		text-align: center;
		color: #474343;
		font-size: 32px;
		font-weight: 900;
		position: relative;
		text-decoration: underline;
	}
	// 
	.substance {
		margin: 100px 0 0;
		@include media-breakpoint-down(lg) { margin: 100px 0 0;}
		@include media-breakpoint-down(md) { margin: 80px 0 0;}
		@include media-breakpoint-down(sm) { margin: 60px 0 0;}
		@include media-breakpoint-down(xs) { margin: 50px 0 0;}
		> dl {
			display: block;
			&:first-child {
				margin: 0 0 45px;
				@include media-breakpoint-down(md) { margin: 0 0 40px;}
			}
			> dt {
				display: block;
				padding: 0.75em 0;
				width: 340px;
				text-align: center;
				color: #FFF;
				font-size: 16px;
				background: #0959a7;
				@include media-breakpoint-down(lg) {
					width: 320px;
					font-size: 16px;
				}
				@include media-breakpoint-down(md) {
					padding: 0.8em 0;
					width: 320px;
					font-size: 15px;
				}
				@include media-breakpoint-down(sm) { width: 100%; }
			}
			> dd {
				display: block;
				margin: 0;
				padding: 30px 40px;
				font-size: 20px;
				background: $baseColor;
				span.red { color: #eb1818;}
				@include media-breakpoint-down(lg) {
					padding: 28px 38px;
					font-size: 19px;
				}
				@include media-breakpoint-down(md) {
					padding: 22px 34px;
					font-size: 18px;
				}
				@include media-breakpoint-down(sm) {
					padding: 18px 30px;
					font-size: 17px;
				}
				@include media-breakpoint-down(xs) {
					padding: 1em 24px;
					font-size: 16px;
				}
			}
		}
	}

	// 配属までのスケジュール
	.schedule {
		margin: 50px 0 0 0;
		.bighead {
			margin: 0 0 1em;
			color: #0959a7;
			font-size: 30px;
			@include media-breakpoint-down(lg) { font-size: 28px;}
			@include media-breakpoint-down(md) { font-size: 26px;}
			@include media-breakpoint-down(sm) { font-size: 22px;}
			@include media-breakpoint-down(xs) { font-size: 18px;}
		}
		.wrap {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.column {
				display: block;
				width: calc(50% - 5px);
				.columnin {
					display: block;
					height: 100%;
					background: $baseColor;
				}
				@include media-breakpoint-down(sm) {
					width: 100%;
					&:last-child { margin-top: 35px;}
				}
			}
		}
		.head {
			padding: 0.75em 0;
			text-align: center;
			color: #FFF;
			font-size: 22px;
			background: #378adb;
			@include media-breakpoint-down(lg) {
				padding: 0.8em 0;
				font-size: 20px;
			}
			@include media-breakpoint-down(md) {
				padding: 0.85em 0;
				font-size: 18px;
			}
			@include media-breakpoint-down(sm) {
				padding: 0.9em 0;
				font-size: 17px;
			}
			@include media-breakpoint-down(xs) {
				padding: 0.95em 0;
				font-size: 16px;
			}
		}
		ul.step {
			display: block;
			margin: 30px 0;
			padding: 0 30px;
			list-style: none;
			position: relative;
			&:after {
				content: "";
				display: block;
				width: 20px;
				height: 100%;
				position: absolute;
				top: 0;
				left: calc(50% - 10px);
				z-index: 0;
			}
			@include media-breakpoint-down(lg) {
				margin: 28px 0;
				padding: 0 28px;
				&:after { width: 18px; left: calc(50% - 9px); }
			}
			@include media-breakpoint-down(md) {
				margin: 26px 0;
				padding: 0 26px;
				&:after { width: 16px; left: calc(50% - 6px); }
			}
			@include media-breakpoint-down(sm) {
				margin: 20px 0;
				padding: 0 20px;
				&:after { width: 14px; left: calc(50% - 7px); }
			}
			@include media-breakpoint-down(xs) {
				margin: 16px 0;
				padding: 0 16px;
			}

			&.gr {
				&:after { background: #d1f7a0;}
				> li:not(.diff) { background: #8fd830; }
			}
			&.og {
				&:after { background: #ffe199;}
				> li:not(.diff) { background: #efb11d; }
			}
			li:not(.diff) {
				position: relative;
				z-index: 1;
				display: block;
				width: 100%;
				padding: 0.8em 0;
				text-align: center;
				line-height: 1;
				color: #FFF;
				font-size: 20px;
				font-weight: 700;
				border-radius: 50px;
				@include media-breakpoint-down(lg) {
					font-size: 17px;
				}
				@include media-breakpoint-down(md) {
					padding: 1em 0;
					font-size: 14px;
				}
				@include media-breakpoint-down(sm) {
					padding: 1em 0;
					font-size: 16px;
				}
				@include media-breakpoint-down(xs) {
					font-size: 13px;
				}
			}
			li.diff {
				position: relative;
				z-index: 1;
				margin: 26px auto;
				p {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 160px;
					line-height: 1;
					text-align: center;
					margin: 0 auto;
					padding: 0.25em 0;
					color: #e11414;
					font-size: 26px;
					font-weight: 900;
					background: #FFF;
					border: #df4c6b solid 2px;
					border-radius: 3px;
					small {
						font-size: 16px;
						font-weight: 900;
					}
				}
				@include media-breakpoint-down(lg) {
					margin: 24px auto;
					p {
						width: 140px;
						padding: 0.2em 0;
						font-size: 22px;
						small { font-size: 14px; }
					}
				}
				@include media-breakpoint-down(md) {
					margin: 22px auto;
					p {
						width: 120px;
						padding: 0.3em 0;
						font-size: 18px;
						small { font-size: 13px; }
					}
				}
				@include media-breakpoint-down(sm) { margin: 20px auto;}
				@include media-breakpoint-down(xs) { margin: 18px auto;}
			}
		}
	}
}

main#mainContent.under .farmer {
	.contact_up {
		text-align: center;
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 40px;
		@include media-breakpoint-down(md) {font-size: 16px;}
	}
	.contact_sec {
		display: flex;
		margin-bottom: 40px;
		@include media-breakpoint-down(md) {display: block;}
		div {
			width: 50%;
			padding: 0 20px;
			text-align: center;
			@include media-breakpoint-down(md) {width: 100%;}
			a {
				display: block;
				width: 100%;
				text-align: center;
				padding: 1.25em 0;
				border-radius: 55px;
				font-weight: 900;
				&.tel {
					background-color: #43a2d7;
					color: #FFF;
					@include media-breakpoint-down(md) {
						display: block; margin-bottom: 20px;}
				}
				&.form {
					background-color: #ffcb3e;
					color: #000;
					@include media-breakpoint-down(md) {
						display: block; margin-bottom: 20px;
					}
				}
				&:hover {
					opacity: 0.6;
				}
			}
		}
	}
}