// ==========================================

// トップページ

// ==========================================

// ==========================================

// 共通（セクション見出し）

// ==========================================
main#mainContent.index .section-head {
    color: #333;
    font-size: 28px;
    font-weight: 900;

    @include media-breakpoint-down(lg) {
        font-size: 27px;
    }

    @include media-breakpoint-down(md) {
        font-size: 26px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 24px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 22px;
    }

    small {
        font-size: 12px;
        font-weight: 700;

        @include media-breakpoint-down(sm) {
            font-size: 11px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 10px;
        }
    }
}



// ==========================================

// 共通（セクション リンク）

// ==========================================
main#mainContent.index .section-link {
    a {
        display: block;
        width: auto;
        height: auto;
        padding: 1.5em 0;
        line-height: 1;
        text-align: center;
        color: #FFF;
        font-size: 18px;
        font-weight: 700;
        border-radius: 100px;
        background: linear-gradient(to right, #43a2d7 0%, #43b6d7 100%);
        position: relative;
        overflow: hidden;
        transition: all 0.3s 0.06s;

        >span {
            position: relative;
            z-index: 1;
        }

        &:before,
        &:after {
            content: "";
            display: block;
            width: 50%;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 0;
            background: #FFF;
            opacity: 0;
            transition: all 0.3s;
        }

        &:before {
            transform: translateX(-110%) skew(-45deg);
            left: 0;
        }

        &:after {
            transform: translateX(110%) skew(-45deg);
            right: 0;
        }

        @include media-breakpoint-down(lg) {
            font-size: 17px;
        }

        @include media-breakpoint-down(md) {
            font-size: 16px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 15px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 15px;
        }

        @include media-breakpoint-up(md) {
            &:hover {
                color: $keyColor;

                &:before,
                &:after {
                    transform: translateX(0) skew(5deg);
                    opacity: 0.77;
                }
            }
        }
    }
}

main#mainContent.index .section-link2 {
    a {
        display: block;
        width: auto;
        height: auto;
        padding: 1.5em 0;
        line-height: 1;
        text-align: center;
        color: #FFF;
        font-size: 18px;
        font-weight: 700;
        border-radius: 100px;
        background: linear-gradient(to right, #2fb383 0%, #18a86b 100%);
        position: relative;
        overflow: hidden;
        transition: all 0.3s 0.06s;

        >span {
            position: relative;
            z-index: 1;
        }

        &:before,
        &:after {
            content: "";
            display: block;
            width: 50%;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 0;
            background: #FFF;
            opacity: 0;
            transition: all 0.3s;
        }

        &:before {
            transform: translateX(-110%) skew(-45deg);
            left: 0;
        }

        &:after {
            transform: translateX(110%) skew(-45deg);
            right: 0;
        }

        @include media-breakpoint-down(lg) {
            font-size: 17px;
        }

        @include media-breakpoint-down(md) {
            font-size: 16px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 15px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 15px;
        }

        @include media-breakpoint-up(md) {
            &:hover {
                color: $keyColor;

                &:before,
                &:after {
                    transform: translateX(0) skew(5deg);
                    opacity: 0.77;
                }
            }
        }
    }
}


// ==========================================

// メインビジュアル

// ==========================================
main#mainContent.index section.mainvisual {
    width: 100%;
    position: relative;
    z-index: 0;
    height: auto;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
        height: 40vh;
    }

    @include media-breakpoint-down(xs) {
        height: 46vh;
    }
    #intro_ban {
        position: absolute;
        left: 2em;
        bottom: 3em;
        z-index: 5;
        a {
            &:hover {
                opacity: 0.8;
            }
        }
        @include media-breakpoint-down(md) {
            left: 2em;
            bottom: 3em;
        }
        @include media-breakpoint-down(sm) {
            bottom: 2em;
            img {
                width: 80%;
            }
        }
    }
    #er {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;

        p {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            line-height: 1;

            span {
                font-feature-settings: unset;
                font-family: 'DINNextLTPro-Bold', sans-serif;
                color: #FFF;
                font-size: 100px;
                background: url(./img/index/mv/er.png) no-repeat;
                display: inline-block;
                height: 72px;
                line-height: 86px;
                margin: 0;
                padding: 0 100px 0 0;
                background-size: auto 100%;
                background-position: 100% 0;
                text-shadow: -2px -1px 8px #bdbdbd;

                @include media-breakpoint-down(lg) {
                    font-size: 90px;
                    height: 64px;
                    line-height: 79px;
                    padding: 0 88px 0 0;
                }

                @include media-breakpoint-down(md) {
                    font-size: 80px;
                    height: 57px;
                    line-height: 60px;
                    padding: 0 80px 0 0;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 65px;
                    height: 46px;
                    line-height: 55px;
                    padding: 0 63px 0 0;
                }

                @include media-breakpoint-down(xs) {
                    font-size: 9vw;
                    height: 6.5vw;
                    line-height: 8.6vw;
                    padding: 0 9vw 0 0;
                }
            }

            img {
                /*				display: none;*/
                margin: 50px auto 0;
                width: auto;
                max-width: 60%;

                @include media-breakpoint-down(lg) {
                    margin: 40px auto 0;
                    max-width: 75%;
                }

                @include media-breakpoint-down(md) {
                    margin: 35px auto 0;
                    max-width: 85%;
                }

                @include media-breakpoint-down(sm) {
                    margin: 40px auto 0;
                    max-width: 90%;
                }

                @include media-breakpoint-down(xs) {
                    margin: 30px auto 0;
                    max-width: 95%;
                }
            }
        }
    }

    .wrap {
        position: relative;
        z-index: 0;
        overflow: hidden;
        height: 52.6666666vw;

        >img {
            visibility: hidden;
            width: 100%;
            height: auto;
            line-height: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
        }

        @include media-breakpoint-down(sm) {
            height: 100%;
            width: calc(40vh * 1.86335403727);
            left: calc(50% - (20vh * 1.86335403727));
        }

        @include media-breakpoint-down(xs) {
            width: calc(46vh * 1.86335403727);
            left: calc(50% - (23vh * 1.86335403727));
        }
    }

    .loading {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        svg {
            width: 100px;
            height: auto;
        }
    }
}

// ==========================================

// トピックス

// ==========================================
main#mainContent.index section.topics {
    position: relative;
    z-index: 1;
    background: $baseColor;

    .conttent {
        transform: translateY(-50%);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        @include media-breakpoint-down(sm) {
            justify-content: flex-start;
            transform: translateY(0);
            padding: 35px 0;
        }

        @include media-breakpoint-down(xs) {
            padding: 25px 0;
        }

        >dl {
            width: 600px;
            margin: 0;
            border-radius: 9px;
            overflow: hidden;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            dt,
            dd {
                display: block;
                width: auto;
                height: auto;
                margin: 0;
                padding: 2em 3.5em;
                font-size: 14px;

                @include media-breakpoint-down(lg) {
                    padding: 1.8em 3.5em;
                }

                @include media-breakpoint-down(md) {
                    padding: 1.6em 2.8em;
                }

                @include media-breakpoint-down(sm) {
                    padding: 1.5em 2em;
                    font-size: 13px;
                }

                @include media-breakpoint-down(xs) {
                    padding: 1.1em 1.2em;
                }
            }

            dt {
                color: #FFF;
                background: $keyColor;
            }

            dd {
                flex: 1;
                color: #333;
                background: #FFF;
            }
        }
    }
}


// ==========================================

// ERについて

// ==========================================
main#mainContent.index section.about {
    padding: 0 0 95px;
    background: $baseColor;

    @include media-breakpoint-down(lg) {
        padding: 0 0 80px;
    }

    @include media-breakpoint-down(md) {
        padding: 0;
    }

    .content {
        @include media-breakpoint-up(lg) {
            width: auto;
            max-width: 1500px;
            margin: 0 auto;
        }
    }

    .wrap {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
            flex-direction: column-reverse;
        }

        .photo {
            width: 970px;
            padding: 0 0 50px;

            @include media-breakpoint-down(md) {
                width: 100%;
                padding: 0;
            }
        }

        .boxwrap {
            z-index: 10;
            width: auto;
            position: relative;

            @include media-breakpoint-down(md) {
                width: 100%;
                padding: 0;
            }

            .box {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 725px;
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 0 18px 18px 0;

                @include media-breakpoint-down(lg) {
                    width: 540px;
                }

                @include media-breakpoint-down(md) {
                    position: static;
                    width: 92%;
                    margin: 0 auto;
                    border-radius: 18px;
                    transform: translateY(-50px);
                }

                @include media-breakpoint-down(xs) {
                    width: 94%;
                    border-radius: 14px;
                    transform: translateY(-35px);
                }

                .pad {
                    padding: 50px 115px 50px 70px;

                    @include media-breakpoint-down(lg) {
                        padding: 45px 55px;
                    }

                    @include media-breakpoint-down(md) {
                        padding: 35px 40px;
                    }

                    @include media-breakpoint-down(sm) {
                        padding: 30px 35px;
                    }

                    @include media-breakpoint-down(xs) {
                        padding: 25px 30px;
                    }
                }

                .iiii {
                    padding: 45px 0;
                    line-height: 0;

                    @include media-breakpoint-down(lg) {
                        padding: 45px 0;
                    }

                    @include media-breakpoint-down(md) {
                        padding: 40px 0;
                    }

                    @include media-breakpoint-down(sm) {
                        padding: 35px 0;
                    }

                    i {
                        display: inline-block;
                        width: 3px;
                        height: 6px;
                        margin: 0 3px 0 0;
                        background: $keyColor;
                        transform: skewX(-24deg);
                    }
                }

                .text {
                    font-size: 14px;
                    line-height: 2;
                }

                .section-link {
                    margin: 45px 0 0;

                    @include media-breakpoint-down(lg) {
                        margin: 40px 0 0;
                    }

                    @include media-breakpoint-down(md) {
                        margin: 35px 0 0;
                    }

                    @include media-breakpoint-down(sm) {
                        margin: 30px 0 0;
                    }
                }
            }
        }
    }
}


// ==========================================

// サービス

// ==========================================
main#mainContent.index section.service {
    padding: 80px 0;
    background: #FFF;

    @include media-breakpoint-down(lg) {
        padding: 70px 0;
    }

    @include media-breakpoint-down(md) {
        padding: 60px 0;
    }

    @include media-breakpoint-down(sm) {
        padding: 50px 0;
    }

    @include media-breakpoint-down(xs) {
        padding: 40px 0;
    }

    .mess {
        width: 920px;
        margin: 75px auto 0;
        padding: 0;
        text-align: center;
        line-height: 1.5;
        font-family: TsukuARdGothicStd-E;

        @include media-breakpoint-down(lg) {
            width: 100%;
            margin: 65px auto 0;
        }

        @include media-breakpoint-down(md) {
            margin: 55px auto 0;
        }

        @include media-breakpoint-down(sm) {
            margin: 45px auto 0;
            text-align: left;
            font-size: 0.9em;
        }

        @include media-breakpoint-down(xs) {
            margin: 35px auto 0;
        }

        br {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    .row {
        margin-top: 45px;

        @include media-breakpoint-down(md) {
            margin-top: 40px 0;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 35px 0;
        }

        @include media-breakpoint-down(xs) {
            margin-top: 30px 0;
        }

        .num1 {
            order: 1;
        }

        .num2 {
            order: 2;
        }

        .num3 {
            order: 3;
        }

        .cont1 {
            order: 4;
        }

        .cont2 {
            order: 5;
        }

        .cont3 {
            order: 6;
        }

        @include media-breakpoint-down(sm) {
            .num1 {
                order: 1;
            }

            .cont1 {
                order: 2;
            }

            .num2 {
                order: 3;
                margin-top: 40px;
            }

            .cont2 {
                order: 4;
            }

            .num3 {
                order: 5;
                margin-top: 40px;
            }

            .cont3 {
                order: 6;
            }
        }
    }

    .point_num {
        display: block;
        text-align: center;

        img {
            display: block;
            width: 48px;
            height: auto;
            margin: 0 auto;
        }

        span {
            display: block;
            margin: 1.25em 0;
            padding: 0;
            line-height: 1.4;
            color: $keyColor;

            @include media-breakpoint-down(lg) {
                margin: 1.15em 0;
            }

            @include media-breakpoint-down(md) {
                margin: 1em 0;
                font-size: 15px;
            }

            @include media-breakpoint-down(sm) {
                margin: 1.15em 0;
                font-size: 18px;
            }

            @include media-breakpoint-down(xs) {
                margin: 1em 0;
                font-size: 16px;
            }
        }
    }

    .point_cont {
        .cont_in {
            display: block;
            margin: 0;
            background: $baseColor;
            border-radius: 18px;
            overflow: hidden;
            height: 100%;

            @include media-breakpoint-down(xs) {
                border-radius: 14px;
            }

            .txtwrap {
                margin-top: 0;
                display: block;
                padding: 2em 1.75em;
                font-size: 14px;

                @include media-breakpoint-down(lg) {
                    padding: 1.8em 1.75em;
                }

                @include media-breakpoint-down(md) {
                    padding: 1.6em 1.5em;
                }

                @include media-breakpoint-down(sm) {
                    padding: 1.5em 1.375em;
                }

                @include media-breakpoint-down(xs) {
                    padding: 1.4em 1.25em;
                }
            }
        }
    }

    .section-link {
        display: block;
        width: auto;
        max-width: 530px;
        margin: 0 auto;
        padding: 80px 0 0;

        @include media-breakpoint-down(lg) {
            padding: 70px 0 0;
        }

        @include media-breakpoint-down(md) {
            padding: 60px 0 0;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 15px;
            padding: 50px 0 0;
        }

        @include media-breakpoint-down(xs) {
            padding: 40px 0 0;
        }
    }
    .section-link2 {
        display: block;
        width: auto;
        max-width: 530px;
        margin: 0 auto;
        padding: 40px 0 0;

        @include media-breakpoint-down(lg) {
            padding: 70px 0 0;
        }

        @include media-breakpoint-down(md) {
            padding: 60px 0 0;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 15px;
            padding: 50px 0 0;
        }

        @include media-breakpoint-down(xs) {
            padding: 40px 0 0;
        }
    }
}


// ==========================================

// 導入事例

// ==========================================
main#mainContent.index section.case {
    padding: 80px 0;
    background: $baseColor;

    @include media-breakpoint-down(lg) {
        padding: 70px 0;
    }

    @include media-breakpoint-down(md) {
        padding: 60px 0;
    }

    @include media-breakpoint-down(sm) {
        padding: 50px 0;
    }

    @include media-breakpoint-down(xs) {
        padding: 40px 0;
    }

    .pickup {
        margin: 60px 0 0;

        @include media-breakpoint-down(lg) {
            margin: 55px 0;
        }

        @include media-breakpoint-down(md) {
            margin: 45px 0;
        }

        @include media-breakpoint-down(sm) {
            margin: 40px 0 0;
        }

        @include media-breakpoint-down(xs) {
            margin: 35px 0 0;
        }

        .pickup_in {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;

            @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
            }

            .photo {
                width: 870px;

                @include media-breakpoint-down(lg) {
                    width: 820px;
                }

                @include media-breakpoint-down(md) {
                    width: 620px;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }

            .boxwrap {
                width: auto;
                position: relative;

                .box {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    display: block;
                    width: 490px;
                    margin: 0;
                    background: #FFF;
                    z-index: 100;

                    @include media-breakpoint-down(lg) {
                        width: 420px;
                    }

                    @include media-breakpoint-down(md) {
                        width: 380px;
                    }

                    @include media-breakpoint-down(sm) {
                        position: static;
                        width: 90%;
                        margin: 0 auto;
                        transform: translateY(-45px);
                    }

                    @include media-breakpoint-down(xs) {
                        width: 96%;
                        transform: translateY(-35px);
                    }

                    @include media-breakpoint-up(md) {

                        &:before,
                        &:after {
                            content: "";
                            display: block;
                            width: 0;
                            height: 0;
                            box-sizing: border-box;
                            position: absolute;
                            border-color: $keyColor;
                            border-style: solid;
                            opacity: 0;
                            transition: all 0.2s;
                        }

                        &:before {
                            top: 0;
                            left: 0;
                            border-width: 6px 0 0 6px;
                        }

                        &:after {
                            bottom: 0;
                            right: 0;
                            border-width: 0 6px 6px 0;
                        }

                        &:hover {

                            &:before,
                            &:after {
                                opacity: 1;
                                width: 100%;
                                height: 100%;
                                transition: all 0.3s;
                            }
                        }
                    }

                    >dl {
                        display: block;
                        margin: 0;
                        padding: 54px 36px;

                        @include media-breakpoint-down(lg) {
                            padding: 35px 30px;
                        }

                        @include media-breakpoint-down(md) {
                            padding: 24px 20px;
                        }

                        @include media-breakpoint-down(sm) {
                            padding: 35px 30px;
                        }

                        @include media-breakpoint-down(xs) {
                            padding: 24px 20px;
                        }

                        >dt {
                            color: $keyColor;
                            font-size: 24px;
                            font-weight: 700;

                            @include media-breakpoint-down(lg) {
                                font-size: 22px;
                            }

                            @include media-breakpoint-down(md) {
                                font-size: 19px;
                            }

                            @include media-breakpoint-down(sm) {
                                font-size: 22px;
                            }

                            @include media-breakpoint-down(xs) {
                                font-size: 17px;
                            }
                        }

                        >dd {
                            display: block;
                            margin: 0;
                            padding: 1.75em 0 0;
                            color: #333;
                            font-size: 16px;

                            @include media-breakpoint-down(lg) {
                                padding: 1.5em 0 0;
                                font-size: 15px;
                            }

                            @include media-breakpoint-down(md) {
                                padding: 1.25em 0 0;
                                font-size: 14px;
                            }

                            @include media-breakpoint-down(sm) {
                                padding: 1em 0 0;
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }

    .row {
        margin-top: 50px;

        @include media-breakpoint-down(lg) {
            margin-top: 45px;
        }

        @include media-breakpoint-down(md) {
            margin-top: 40px;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }
    }

    .item {
        &:not(:first-child) {
            @include media-breakpoint-down(sm) {
                margin-top: 30px;
            }

            @include media-breakpoint-down(xs) {
                margin-top: 25px;
            }
        }

        >a {
            height: 100%;
            background: #FFF;

            dl {
                display: block;
                margin: 0;
                padding: 22px 24px;

                @include media-breakpoint-down(lg) {
                    padding: 20px 22px;
                }

                @include media-breakpoint-down(md) {
                    padding: 16px 20px;
                }

                @include media-breakpoint-down(sm) {
                    padding: 1em 1.5em;
                }

                @include media-breakpoint-down(xs) {
                    padding: 0.75em 1em;
                }

                >dt {
                    color: $keyColor;

                    @include media-breakpoint-down(lg) {
                        font-size: 15px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 14px;
                    }

                    @include media-breakpoint-down(xs) {
                        font-size: 13px;
                    }
                }

                >dd {
                    color: #333;
                    font-size: 14px;

                    @include media-breakpoint-down(sm) {
                        font-size: 12px;
                    }

                    @include media-breakpoint-down(xs) {
                        font-size: 11px;
                    }
                }
            }

            @include media-breakpoint-up(md) {
                position: relative;

                &:before,
                &:after {
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    box-sizing: border-box;
                    position: absolute;
                    border-color: $keyColor;
                    border-style: solid;
                    opacity: 0;
                    transition: all 0.2s;
                }

                &:before {
                    top: 0;
                    left: 0;
                    border-width: 6px 0 0 6px;
                }

                &:after {
                    bottom: 0;
                    right: 0;
                    border-width: 0 6px 6px 0;
                }

                &:hover {

                    &:before,
                    &:after {
                        opacity: 1;
                        width: 100%;
                        height: 100%;
                        transition: all 0.3s;
                        z-index: 10;
                    }
                }
            }
        }
    }

    .section-link {
        display: block;
        width: auto;
        max-width: 530px;
        margin: 0 auto;
        padding: 80px 0 0;

        @include media-breakpoint-down(lg) {
            padding: 70px 0 0;
        }

        @include media-breakpoint-down(md) {
            padding: 60px 0 0;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 15px;
            padding: 50px 0 0;
        }

        @include media-breakpoint-down(xs) {
            padding: 40px 0 0;
        }
    }
}


// ==========================================

// ニュース／会社案内 らっぱー

// ==========================================
main#mainContent.index .grade_bg {
    padding: 100px 60px;
    background: linear-gradient(to right, #FFFFFF 0%, #e4f3f7 100%);

    @include media-breakpoint-down(lg) {
        padding: 85px 50px;
    }

    @include media-breakpoint-down(md) {
        padding: 65px 30px;
    }

    @include media-breakpoint-down(sm) {
        padding: 50px 15px;
    }

    @include media-breakpoint-down(xs) {
        padding: 40px 0;
    }
}


// ==========================================

// ニュース

// ==========================================
main#mainContent.index section.news {
    .content {
        margin: 75px 0 0;
        padding: 0;

        @include media-breakpoint-down(lg) {
            margin: 70px 0 0;
        }

        @include media-breakpoint-down(md) {
            margin: 60px 0 0;
        }

        @include media-breakpoint-down(sm) {
            margin: 50px 0 0;
        }

        @include media-breakpoint-down(xs) {
            margin: 40px 0 0;
        }

        .item {
            justify-content: flex-start;
            align-items: center;
            margin: 0 0 2em;

            @include media-breakpoint-down(lg) {
                margin: 0 0 1.8em;
            }

            @include media-breakpoint-down(md) {
                margin: 0 0 1.6em;
            }

            @include media-breakpoint-down(sm) {
                margin: 0 0 1.5em;
            }

            @include media-breakpoint-down(xs) {
                margin: 0 0 1.4em;
            }

            .date {
                width: 10em;
                font-size: 14px;
                word-wrap: none;

                @include media-breakpoint-down(md) {
                    font-size: 13px;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                }
            }

            .cats {
                width: 6em;
                word-wrap: none;

                span {
                    display: inline-block;
                    margin: 0;
                    padding: 0.5em 1em;
                    line-height: 1;
                    color: #474747;
                    font-size: 12px;
                    font-weight: 700;
                    background: #eeedcc;
                    border-radius: 5px;
                    word-wrap: none;

                    @include media-breakpoint-down(sm) {
                        font-size: 11px;
                    }
                }
            }

            .title {
                width: auto;
                font-size: 14px;

                @include media-breakpoint-down(md) {
                    font-size: 13px;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                }

                a {
                    color: #333;
                    text-decoration: underline;

                    @include media-breakpoint-up(md) {
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    .link {
        margin: 45px 0 0;

        @include media-breakpoint-down(lg) {
            margin: 40px 0 0;
        }

        @include media-breakpoint-down(md) {
            margin: 35px 0 0;
        }

        @include media-breakpoint-down(sm) {
            margin: 30px 0 0;
        }

        a {
            color: #333;
            text-decoration: none;

            @include media-breakpoint-down(md) {
                font-size: 13px;
            }

            @include media-breakpoint-up(md) {
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}


// ==========================================

// 会社紹介

// ==========================================
main#mainContent.index section.company {
    padding: 0;

    .content {
        margin: 35px 0 0;
        padding: 0;

        @include media-breakpoint-down(md) {
            margin: 30px 0 0;
        }

        @include media-breakpoint-down(sm) {
            margin: 25px 0 0;
        }

        @include media-breakpoint-down(xs) {
            margin: 20px 0 0;
        }

        .link {
            bottom: 0;
            right: 0;

            @include media-breakpoint-down(xs) {
                width: 100%;
            }

            a {
                display: block;
                width: 390px;
                padding: 2em 0;
                color: #FFF;
                background: linear-gradient(to right, rgba(67, 162, 215, 0.75) 0%, rgba(67, 182, 215, 0.75) 100%);
                transition: opacity 0.2s;

                @include media-breakpoint-down(lg) {
                    width: 320px;
                    padding: 1.6em 0;
                }

                @include media-breakpoint-down(sm) {
                    width: 300px;
                    padding: 1.4em 0;
                    font-size: 15px;
                }

                @include media-breakpoint-down(xs) {
                    width: 100%;
                    font-size: 14px;
                }

                @include media-breakpoint-up(md) {
                    &:hover {
                        opacity: 0.66;
                    }
                }
            }
        }
    }
}


// ==========================================

// 外国人雇用が初めての企業さまへ

// ==========================================
main#mainContent.service section.intro1 {
    font-size: 14px;
    background: #FFF;
    padding: 120px 0 0 0;

    @include media-breakpoint-down(lg) {
        padding: 60px 0;
    }

    @include media-breakpoint-down(md) {
        padding: 50px 0;
    }

    @include media-breakpoint-down(sm) {
        padding: 40px 0;
    }

    @include media-breakpoint-down(xs) {
        padding: 40px 0;
    }

    &:first-child {
        margin-top: 60px;

        @include media-breakpoint-down(lg) {
            margin-top: 50px;
        }

        @include media-breakpoint-down(md) {
            margin-top: 40px;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }

        @include media-breakpoint-down(xs) {
            margin-top: 0;
        }
    }

    .row {
        .cont1 {
            order: 4;
        }

        .cont2 {
            order: 5;
        }

        .cont3 {
            order: 6;
        }

        @include media-breakpoint-down(sm) {
            .cont1 {
                order: 2;
            }

            .cont2 {
                order: 4;
            }

            .cont3 {
                order: 6;
            }
        }
    }

    .point_num {
        display: block;
        text-align: center;

        img {
            display: block;
            width: 48px;
            height: auto;
            margin: 0 auto;
        }

        span {
            font-size: 125%;
            display: block;
            margin: 1.25em 0;
            padding: 0;
            line-height: 1.4;
            color: $keyColor;

            @include media-breakpoint-down(lg) {
                margin: 1.15em 0;
            }

            @include media-breakpoint-down(md) {
                margin: 1em 0;
                font-size: 15px;
            }

            @include media-breakpoint-down(sm) {
                margin: 1.15em 0;
                font-size: 18px;
            }

            @include media-breakpoint-down(xs) {
                margin: 1em 0;
                font-size: 16px;
            }
        }
    }

    .point_cont {

        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }

        @include media-breakpoint-down(xs) {
            margin-bottom: 30px;

        }

        .cont_in {
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
            display: block;
            margin: 0;
            background: #FFF;
            border-radius: 18px;
            overflow: hidden;
            height: 100%;

            @include media-breakpoint-down(xs) {
                border-radius: 14px;
            }

            .txtwrap {
                margin-top: 0;
                display: block;
                padding: 0 2em 1.75em;
                font-size: 14px;

                @include media-breakpoint-down(lg) {
                    padding: 0 1.8em 1.75em;
                }

                @include media-breakpoint-down(md) {
                    padding: 1.6em 1.5em;
                }

                @include media-breakpoint-down(sm) {
                    padding: 0 1.5em 1.375em;
                }

                @include media-breakpoint-down(xs) {
                    padding: 0 1.4em 1.25em;
                }
            }
        }
    }

    .wrap {
        >dl {
            width: 48.5%;
            padding: 1.2em;
            display: block;
            margin: 0 0 30px;
            background: #FFF;
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            @include media-breakpoint-down(xs) {
                width: 100%;
            }

            dt {
                margin-bottom: 1.2em;

                @include media-breakpoint-down(xs) {
                    margin-bottom: 0;
                }

                .q::before {
                    content: '';
                    display: block;
                    width: 44px;
                    height: 44px;
                    background: url(./img/intro/q.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    vertical-align: middle;
                    margin-right: 13px;
                    background-position: right;
                }
            }

            p {
                flex: 1;
                display: block;
                width: auto;
                margin: 0;
                background: #FFF;

                @include media-breakpoint-down(md) {
                    padding: 0.75em 1em;
                    font-size: 15px;
                }

                @include media-breakpoint-down(sm) {
                    padding: 0.75em 1em;
                    font-size: 14px;
                }
            }
        }

        dd {
            .a::before {
                content: '';
                display: block;
                text-align: right;
                width: 44px;
                height: 44px;
                background: url(./img/intro/a.svg);
                background-repeat: no-repeat;
                background-size: contain;
                vertical-align: middle;
                margin-right: 13px;
            }
        }

        p {
            flex: 1;
            display: block;
            width: auto;
            margin: 0;
            background: #FFF;

            @include media-breakpoint-down(md) {
                padding: 0.75em 1em;
                font-size: 15px;
            }

            @include media-breakpoint-down(sm) {
                padding: 0.75em 1em;
                font-size: 14px;
            }
        }
    }

    .flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .arrow {
        width: 100px;
        margin: 0 auto;
        padding: 3em 0;

        @include media-breakpoint-down(md) {
            padding: 2.5em 0;
        }

        @include media-breakpoint-down(sm) {
            padding: 2.5em 0;
        }

        @include media-breakpoint-down(xs) {
            padding: 2em 0;
        }
    }

    .arrowwrap {
        display: block;

        p {
            text-align: center;
            color: rgb(255, 255, 255);
            font-size: 190%;
            font-weight: 600;
            background-color: $keyColor;
            padding: 1em 0;

            @include media-breakpoint-down(md) {
                // width: 68px;
                font-size: 150%;
                // position: 0.65em 0;
            }

            @include media-breakpoint-down(sm) {
                // width: 60px;
                font-size: 130%;
            }

            @include media-breakpoint-down(xs) {
                // width: 50px;
                font-size: 105%;
            }
        }
    }

    .fsb {
        font-size: 130%;
    }

}



// ==========================================

// animation

// ==========================================
.anima {
    position: relative;
    overflow: hidden;

    &:before {
        background: #43a2d7;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transform: translateX(-100%);
        transition: all .6s 0s ease;
        width: 100%;
        z-index: 1;
    }

    img {
        display: block;
        position: relative;
        opacity: 0;
        transition: all .5s .3s ease;
        z-index: 0;
    }

    &.active {
        img {
            opacity: 1;
        }

        &:before {
            transform: translateX(100%);

            img {
                opacity: 1;
            }
        }
    }
}


.fdu {
    opacity: 0;

    &.active {
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
        -webkit-animation-duration: 0.5s;
        animation-duration: 0.5s;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 20px, 0);
        transform: translate3d(0, 20px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
