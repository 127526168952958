// ==========================================

// position

// ==========================================
.p-rel {position: relative;}
.p-fix {position: fixed;}
.p-abs { position: absolute; }
.p-just {
    top: 0;
    left: 0;
    line-height: 1;
    display: block;
    width: 100%;
    height: auto;
}
