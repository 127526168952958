
// ベース
html,
body {
    min-width:320px; 
    font-family: 'Noto Sans JP', sans-serif;
    font-feature-settings: "palt" 1;
    text-justify: inter-character;
    letter-spacing: 2px;
    .fwr {font-weight: 400;}
    .fwb {font-weight: 700;}
    .fwbb {font-weight: 900;}
    > #page {
        > header#header { position: relative; z-index: 50; }
        > main#mainContent { position: relative; z-index: 30; }
        > footer#footer { position: relative; z-index: 10;}
        > nav#modileGnav { position: absolute; z-index: 40;}
    }
    img {
        width: 100%;
        height: auto;
        max-width: 100%;
    }
}


#page-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 77%;
    display: none;
    z-index: 50;
    a {
        background: #000;
        text-decoration: none;
        color: #fff;
        padding: 10px 20px;
        text-align: center;
        display: block;
        border-radius: 5px;
        opacity: 0.2;
        &:hover {
            text-decoration: none;
            background: #999;
        }
    }
}