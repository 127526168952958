:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #19a64a;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #19a64a;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1400px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  text-align: left;
  background-color: #FFF; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: none; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFF;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1400px) {
    .container {
      max-width: 1230px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1230px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1400px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #333; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #333;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #bfe6cc; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #87d1a1; }

.table-hover .table-success:hover {
  background-color: #addfbd; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #addfbd; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1399.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #19a64a; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(25, 166, 74, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #19a64a;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2319a64a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #19a64a;
    box-shadow: 0 0 0 0.2rem rgba(25, 166, 74, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #19a64a;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2319a64a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #19a64a;
    box-shadow: 0 0 0 0.2rem rgba(25, 166, 74, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #19a64a; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #19a64a; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #19a64a; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #20d25e;
  background-color: #20d25e; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(25, 166, 74, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #19a64a; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #19a64a; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #19a64a;
  box-shadow: 0 0 0 0.2rem rgba(25, 166, 74, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #333;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #333;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #19a64a;
  border-color: #19a64a; }
  .btn-success:hover {
    color: #fff;
    background-color: #14853b;
    border-color: #127a36; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #14853b;
    border-color: #127a36;
    box-shadow: 0 0 0 0.2rem rgba(60, 179, 101, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #19a64a;
    border-color: #19a64a; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #127a36;
    border-color: #116f31; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(60, 179, 101, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #19a64a;
  border-color: #19a64a; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #19a64a;
    border-color: #19a64a; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(25, 166, 74, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #19a64a;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #19a64a;
    border-color: #19a64a; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(25, 166, 74, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #333;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #FFF;
    border-color: #dee2e6 #dee2e6 #FFF; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1399.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1400px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #19a64a; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #127a36; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(25, 166, 74, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #0d5626;
  background-color: #d1eddb;
  border-color: #bfe6cc; }
  .alert-success hr {
    border-top-color: #addfbd; }
  .alert-success .alert-link {
    color: #062a12; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #333;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #0d5626;
  background-color: #bfe6cc; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0d5626;
    background-color: #addfbd; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0d5626;
    border-color: #0d5626; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1400px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #333; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #19a64a !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #127a36 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #19a64a !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1400px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1400px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1400px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-10 {
  margin: 10px !important; }

.mt-10,
.my-10 {
  margin-top: 10px !important; }

.mr-10,
.mx-10 {
  margin-right: 10px !important; }

.mb-10,
.my-10 {
  margin-bottom: 10px !important; }

.ml-10,
.mx-10 {
  margin-left: 10px !important; }

.m-15 {
  margin: 15px !important; }

.mt-15,
.my-15 {
  margin-top: 15px !important; }

.mr-15,
.mx-15 {
  margin-right: 15px !important; }

.mb-15,
.my-15 {
  margin-bottom: 15px !important; }

.ml-15,
.mx-15 {
  margin-left: 15px !important; }

.m-20 {
  margin: 20px !important; }

.mt-20,
.my-20 {
  margin-top: 20px !important; }

.mr-20,
.mx-20 {
  margin-right: 20px !important; }

.mb-20,
.my-20 {
  margin-bottom: 20px !important; }

.ml-20,
.mx-20 {
  margin-left: 20px !important; }

.m-25 {
  margin: 25px !important; }

.mt-25,
.my-25 {
  margin-top: 25px !important; }

.mr-25,
.mx-25 {
  margin-right: 25px !important; }

.mb-25,
.my-25 {
  margin-bottom: 25px !important; }

.ml-25,
.mx-25 {
  margin-left: 25px !important; }

.m-30 {
  margin: 30px !important; }

.mt-30,
.my-30 {
  margin-top: 30px !important; }

.mr-30,
.mx-30 {
  margin-right: 30px !important; }

.mb-30,
.my-30 {
  margin-bottom: 30px !important; }

.ml-30,
.mx-30 {
  margin-left: 30px !important; }

.m-35 {
  margin: 35px !important; }

.mt-35,
.my-35 {
  margin-top: 35px !important; }

.mr-35,
.mx-35 {
  margin-right: 35px !important; }

.mb-35,
.my-35 {
  margin-bottom: 35px !important; }

.ml-35,
.mx-35 {
  margin-left: 35px !important; }

.m-40 {
  margin: 40px !important; }

.mt-40,
.my-40 {
  margin-top: 40px !important; }

.mr-40,
.mx-40 {
  margin-right: 40px !important; }

.mb-40,
.my-40 {
  margin-bottom: 40px !important; }

.ml-40,
.mx-40 {
  margin-left: 40px !important; }

.m-45 {
  margin: 45px !important; }

.mt-45,
.my-45 {
  margin-top: 45px !important; }

.mr-45,
.mx-45 {
  margin-right: 45px !important; }

.mb-45,
.my-45 {
  margin-bottom: 45px !important; }

.ml-45,
.mx-45 {
  margin-left: 45px !important; }

.m-50 {
  margin: 50px !important; }

.mt-50,
.my-50 {
  margin-top: 50px !important; }

.mr-50,
.mx-50 {
  margin-right: 50px !important; }

.mb-50,
.my-50 {
  margin-bottom: 50px !important; }

.ml-50,
.mx-50 {
  margin-left: 50px !important; }

.m-55 {
  margin: 55px !important; }

.mt-55,
.my-55 {
  margin-top: 55px !important; }

.mr-55,
.mx-55 {
  margin-right: 55px !important; }

.mb-55,
.my-55 {
  margin-bottom: 55px !important; }

.ml-55,
.mx-55 {
  margin-left: 55px !important; }

.m-60 {
  margin: 60px !important; }

.mt-60,
.my-60 {
  margin-top: 60px !important; }

.mr-60,
.mx-60 {
  margin-right: 60px !important; }

.mb-60,
.my-60 {
  margin-bottom: 60px !important; }

.ml-60,
.mx-60 {
  margin-left: 60px !important; }

.m-65 {
  margin: 65px !important; }

.mt-65,
.my-65 {
  margin-top: 65px !important; }

.mr-65,
.mx-65 {
  margin-right: 65px !important; }

.mb-65,
.my-65 {
  margin-bottom: 65px !important; }

.ml-65,
.mx-65 {
  margin-left: 65px !important; }

.m-70 {
  margin: 70px !important; }

.mt-70,
.my-70 {
  margin-top: 70px !important; }

.mr-70,
.mx-70 {
  margin-right: 70px !important; }

.mb-70,
.my-70 {
  margin-bottom: 70px !important; }

.ml-70,
.mx-70 {
  margin-left: 70px !important; }

.m-75 {
  margin: 75px !important; }

.mt-75,
.my-75 {
  margin-top: 75px !important; }

.mr-75,
.mx-75 {
  margin-right: 75px !important; }

.mb-75,
.my-75 {
  margin-bottom: 75px !important; }

.ml-75,
.mx-75 {
  margin-left: 75px !important; }

.m-80 {
  margin: 80px !important; }

.mt-80,
.my-80 {
  margin-top: 80px !important; }

.mr-80,
.mx-80 {
  margin-right: 80px !important; }

.mb-80,
.my-80 {
  margin-bottom: 80px !important; }

.ml-80,
.mx-80 {
  margin-left: 80px !important; }

.m-85 {
  margin: 85px !important; }

.mt-85,
.my-85 {
  margin-top: 85px !important; }

.mr-85,
.mx-85 {
  margin-right: 85px !important; }

.mb-85,
.my-85 {
  margin-bottom: 85px !important; }

.ml-85,
.mx-85 {
  margin-left: 85px !important; }

.m-90 {
  margin: 90px !important; }

.mt-90,
.my-90 {
  margin-top: 90px !important; }

.mr-90,
.mx-90 {
  margin-right: 90px !important; }

.mb-90,
.my-90 {
  margin-bottom: 90px !important; }

.ml-90,
.mx-90 {
  margin-left: 90px !important; }

.m-95 {
  margin: 95px !important; }

.mt-95,
.my-95 {
  margin-top: 95px !important; }

.mr-95,
.mx-95 {
  margin-right: 95px !important; }

.mb-95,
.my-95 {
  margin-bottom: 95px !important; }

.ml-95,
.mx-95 {
  margin-left: 95px !important; }

.m-100 {
  margin: 100px !important; }

.mt-100,
.my-100 {
  margin-top: 100px !important; }

.mr-100,
.mx-100 {
  margin-right: 100px !important; }

.mb-100,
.my-100 {
  margin-bottom: 100px !important; }

.ml-100,
.mx-100 {
  margin-left: 100px !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-10 {
  padding: 10px !important; }

.pt-10,
.py-10 {
  padding-top: 10px !important; }

.pr-10,
.px-10 {
  padding-right: 10px !important; }

.pb-10,
.py-10 {
  padding-bottom: 10px !important; }

.pl-10,
.px-10 {
  padding-left: 10px !important; }

.p-15 {
  padding: 15px !important; }

.pt-15,
.py-15 {
  padding-top: 15px !important; }

.pr-15,
.px-15 {
  padding-right: 15px !important; }

.pb-15,
.py-15 {
  padding-bottom: 15px !important; }

.pl-15,
.px-15 {
  padding-left: 15px !important; }

.p-20 {
  padding: 20px !important; }

.pt-20,
.py-20 {
  padding-top: 20px !important; }

.pr-20,
.px-20 {
  padding-right: 20px !important; }

.pb-20,
.py-20 {
  padding-bottom: 20px !important; }

.pl-20,
.px-20 {
  padding-left: 20px !important; }

.p-25 {
  padding: 25px !important; }

.pt-25,
.py-25 {
  padding-top: 25px !important; }

.pr-25,
.px-25 {
  padding-right: 25px !important; }

.pb-25,
.py-25 {
  padding-bottom: 25px !important; }

.pl-25,
.px-25 {
  padding-left: 25px !important; }

.p-30 {
  padding: 30px !important; }

.pt-30,
.py-30 {
  padding-top: 30px !important; }

.pr-30,
.px-30 {
  padding-right: 30px !important; }

.pb-30,
.py-30 {
  padding-bottom: 30px !important; }

.pl-30,
.px-30 {
  padding-left: 30px !important; }

.p-35 {
  padding: 35px !important; }

.pt-35,
.py-35 {
  padding-top: 35px !important; }

.pr-35,
.px-35 {
  padding-right: 35px !important; }

.pb-35,
.py-35 {
  padding-bottom: 35px !important; }

.pl-35,
.px-35 {
  padding-left: 35px !important; }

.p-40 {
  padding: 40px !important; }

.pt-40,
.py-40 {
  padding-top: 40px !important; }

.pr-40,
.px-40 {
  padding-right: 40px !important; }

.pb-40,
.py-40 {
  padding-bottom: 40px !important; }

.pl-40,
.px-40 {
  padding-left: 40px !important; }

.p-45 {
  padding: 45px !important; }

.pt-45,
.py-45 {
  padding-top: 45px !important; }

.pr-45,
.px-45 {
  padding-right: 45px !important; }

.pb-45,
.py-45 {
  padding-bottom: 45px !important; }

.pl-45,
.px-45 {
  padding-left: 45px !important; }

.p-50 {
  padding: 50px !important; }

.pt-50,
.py-50 {
  padding-top: 50px !important; }

.pr-50,
.px-50 {
  padding-right: 50px !important; }

.pb-50,
.py-50 {
  padding-bottom: 50px !important; }

.pl-50,
.px-50 {
  padding-left: 50px !important; }

.p-55 {
  padding: 55px !important; }

.pt-55,
.py-55 {
  padding-top: 55px !important; }

.pr-55,
.px-55 {
  padding-right: 55px !important; }

.pb-55,
.py-55 {
  padding-bottom: 55px !important; }

.pl-55,
.px-55 {
  padding-left: 55px !important; }

.p-60 {
  padding: 60px !important; }

.pt-60,
.py-60 {
  padding-top: 60px !important; }

.pr-60,
.px-60 {
  padding-right: 60px !important; }

.pb-60,
.py-60 {
  padding-bottom: 60px !important; }

.pl-60,
.px-60 {
  padding-left: 60px !important; }

.p-65 {
  padding: 65px !important; }

.pt-65,
.py-65 {
  padding-top: 65px !important; }

.pr-65,
.px-65 {
  padding-right: 65px !important; }

.pb-65,
.py-65 {
  padding-bottom: 65px !important; }

.pl-65,
.px-65 {
  padding-left: 65px !important; }

.p-70 {
  padding: 70px !important; }

.pt-70,
.py-70 {
  padding-top: 70px !important; }

.pr-70,
.px-70 {
  padding-right: 70px !important; }

.pb-70,
.py-70 {
  padding-bottom: 70px !important; }

.pl-70,
.px-70 {
  padding-left: 70px !important; }

.p-75 {
  padding: 75px !important; }

.pt-75,
.py-75 {
  padding-top: 75px !important; }

.pr-75,
.px-75 {
  padding-right: 75px !important; }

.pb-75,
.py-75 {
  padding-bottom: 75px !important; }

.pl-75,
.px-75 {
  padding-left: 75px !important; }

.p-80 {
  padding: 80px !important; }

.pt-80,
.py-80 {
  padding-top: 80px !important; }

.pr-80,
.px-80 {
  padding-right: 80px !important; }

.pb-80,
.py-80 {
  padding-bottom: 80px !important; }

.pl-80,
.px-80 {
  padding-left: 80px !important; }

.p-85 {
  padding: 85px !important; }

.pt-85,
.py-85 {
  padding-top: 85px !important; }

.pr-85,
.px-85 {
  padding-right: 85px !important; }

.pb-85,
.py-85 {
  padding-bottom: 85px !important; }

.pl-85,
.px-85 {
  padding-left: 85px !important; }

.p-90 {
  padding: 90px !important; }

.pt-90,
.py-90 {
  padding-top: 90px !important; }

.pr-90,
.px-90 {
  padding-right: 90px !important; }

.pb-90,
.py-90 {
  padding-bottom: 90px !important; }

.pl-90,
.px-90 {
  padding-left: 90px !important; }

.p-95 {
  padding: 95px !important; }

.pt-95,
.py-95 {
  padding-top: 95px !important; }

.pr-95,
.px-95 {
  padding-right: 95px !important; }

.pb-95,
.py-95 {
  padding-bottom: 95px !important; }

.pl-95,
.px-95 {
  padding-left: 95px !important; }

.p-100 {
  padding: 100px !important; }

.pt-100,
.py-100 {
  padding-top: 100px !important; }

.pr-100,
.px-100 {
  padding-right: 100px !important; }

.pb-100,
.py-100 {
  padding-bottom: 100px !important; }

.pl-100,
.px-100 {
  padding-left: 100px !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-n10 {
  margin: -10px !important; }

.mt-n10,
.my-n10 {
  margin-top: -10px !important; }

.mr-n10,
.mx-n10 {
  margin-right: -10px !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -10px !important; }

.ml-n10,
.mx-n10 {
  margin-left: -10px !important; }

.m-n15 {
  margin: -15px !important; }

.mt-n15,
.my-n15 {
  margin-top: -15px !important; }

.mr-n15,
.mx-n15 {
  margin-right: -15px !important; }

.mb-n15,
.my-n15 {
  margin-bottom: -15px !important; }

.ml-n15,
.mx-n15 {
  margin-left: -15px !important; }

.m-n20 {
  margin: -20px !important; }

.mt-n20,
.my-n20 {
  margin-top: -20px !important; }

.mr-n20,
.mx-n20 {
  margin-right: -20px !important; }

.mb-n20,
.my-n20 {
  margin-bottom: -20px !important; }

.ml-n20,
.mx-n20 {
  margin-left: -20px !important; }

.m-n25 {
  margin: -25px !important; }

.mt-n25,
.my-n25 {
  margin-top: -25px !important; }

.mr-n25,
.mx-n25 {
  margin-right: -25px !important; }

.mb-n25,
.my-n25 {
  margin-bottom: -25px !important; }

.ml-n25,
.mx-n25 {
  margin-left: -25px !important; }

.m-n30 {
  margin: -30px !important; }

.mt-n30,
.my-n30 {
  margin-top: -30px !important; }

.mr-n30,
.mx-n30 {
  margin-right: -30px !important; }

.mb-n30,
.my-n30 {
  margin-bottom: -30px !important; }

.ml-n30,
.mx-n30 {
  margin-left: -30px !important; }

.m-n35 {
  margin: -35px !important; }

.mt-n35,
.my-n35 {
  margin-top: -35px !important; }

.mr-n35,
.mx-n35 {
  margin-right: -35px !important; }

.mb-n35,
.my-n35 {
  margin-bottom: -35px !important; }

.ml-n35,
.mx-n35 {
  margin-left: -35px !important; }

.m-n40 {
  margin: -40px !important; }

.mt-n40,
.my-n40 {
  margin-top: -40px !important; }

.mr-n40,
.mx-n40 {
  margin-right: -40px !important; }

.mb-n40,
.my-n40 {
  margin-bottom: -40px !important; }

.ml-n40,
.mx-n40 {
  margin-left: -40px !important; }

.m-n45 {
  margin: -45px !important; }

.mt-n45,
.my-n45 {
  margin-top: -45px !important; }

.mr-n45,
.mx-n45 {
  margin-right: -45px !important; }

.mb-n45,
.my-n45 {
  margin-bottom: -45px !important; }

.ml-n45,
.mx-n45 {
  margin-left: -45px !important; }

.m-n50 {
  margin: -50px !important; }

.mt-n50,
.my-n50 {
  margin-top: -50px !important; }

.mr-n50,
.mx-n50 {
  margin-right: -50px !important; }

.mb-n50,
.my-n50 {
  margin-bottom: -50px !important; }

.ml-n50,
.mx-n50 {
  margin-left: -50px !important; }

.m-n55 {
  margin: -55px !important; }

.mt-n55,
.my-n55 {
  margin-top: -55px !important; }

.mr-n55,
.mx-n55 {
  margin-right: -55px !important; }

.mb-n55,
.my-n55 {
  margin-bottom: -55px !important; }

.ml-n55,
.mx-n55 {
  margin-left: -55px !important; }

.m-n60 {
  margin: -60px !important; }

.mt-n60,
.my-n60 {
  margin-top: -60px !important; }

.mr-n60,
.mx-n60 {
  margin-right: -60px !important; }

.mb-n60,
.my-n60 {
  margin-bottom: -60px !important; }

.ml-n60,
.mx-n60 {
  margin-left: -60px !important; }

.m-n65 {
  margin: -65px !important; }

.mt-n65,
.my-n65 {
  margin-top: -65px !important; }

.mr-n65,
.mx-n65 {
  margin-right: -65px !important; }

.mb-n65,
.my-n65 {
  margin-bottom: -65px !important; }

.ml-n65,
.mx-n65 {
  margin-left: -65px !important; }

.m-n70 {
  margin: -70px !important; }

.mt-n70,
.my-n70 {
  margin-top: -70px !important; }

.mr-n70,
.mx-n70 {
  margin-right: -70px !important; }

.mb-n70,
.my-n70 {
  margin-bottom: -70px !important; }

.ml-n70,
.mx-n70 {
  margin-left: -70px !important; }

.m-n75 {
  margin: -75px !important; }

.mt-n75,
.my-n75 {
  margin-top: -75px !important; }

.mr-n75,
.mx-n75 {
  margin-right: -75px !important; }

.mb-n75,
.my-n75 {
  margin-bottom: -75px !important; }

.ml-n75,
.mx-n75 {
  margin-left: -75px !important; }

.m-n80 {
  margin: -80px !important; }

.mt-n80,
.my-n80 {
  margin-top: -80px !important; }

.mr-n80,
.mx-n80 {
  margin-right: -80px !important; }

.mb-n80,
.my-n80 {
  margin-bottom: -80px !important; }

.ml-n80,
.mx-n80 {
  margin-left: -80px !important; }

.m-n85 {
  margin: -85px !important; }

.mt-n85,
.my-n85 {
  margin-top: -85px !important; }

.mr-n85,
.mx-n85 {
  margin-right: -85px !important; }

.mb-n85,
.my-n85 {
  margin-bottom: -85px !important; }

.ml-n85,
.mx-n85 {
  margin-left: -85px !important; }

.m-n90 {
  margin: -90px !important; }

.mt-n90,
.my-n90 {
  margin-top: -90px !important; }

.mr-n90,
.mx-n90 {
  margin-right: -90px !important; }

.mb-n90,
.my-n90 {
  margin-bottom: -90px !important; }

.ml-n90,
.mx-n90 {
  margin-left: -90px !important; }

.m-n95 {
  margin: -95px !important; }

.mt-n95,
.my-n95 {
  margin-top: -95px !important; }

.mr-n95,
.mx-n95 {
  margin-right: -95px !important; }

.mb-n95,
.my-n95 {
  margin-bottom: -95px !important; }

.ml-n95,
.mx-n95 {
  margin-left: -95px !important; }

.m-n100 {
  margin: -100px !important; }

.mt-n100,
.my-n100 {
  margin-top: -100px !important; }

.mr-n100,
.mx-n100 {
  margin-right: -100px !important; }

.mb-n100,
.my-n100 {
  margin-bottom: -100px !important; }

.ml-n100,
.mx-n100 {
  margin-left: -100px !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-10 {
    margin: 10px !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10px !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10px !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10px !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10px !important; }
  .m-sm-15 {
    margin: 15px !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 15px !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 15px !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 15px !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 15px !important; }
  .m-sm-20 {
    margin: 20px !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 20px !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 20px !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 20px !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 20px !important; }
  .m-sm-25 {
    margin: 25px !important; }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 25px !important; }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 25px !important; }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 25px !important; }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 25px !important; }
  .m-sm-30 {
    margin: 30px !important; }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 30px !important; }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 30px !important; }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 30px !important; }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 30px !important; }
  .m-sm-35 {
    margin: 35px !important; }
  .mt-sm-35,
  .my-sm-35 {
    margin-top: 35px !important; }
  .mr-sm-35,
  .mx-sm-35 {
    margin-right: 35px !important; }
  .mb-sm-35,
  .my-sm-35 {
    margin-bottom: 35px !important; }
  .ml-sm-35,
  .mx-sm-35 {
    margin-left: 35px !important; }
  .m-sm-40 {
    margin: 40px !important; }
  .mt-sm-40,
  .my-sm-40 {
    margin-top: 40px !important; }
  .mr-sm-40,
  .mx-sm-40 {
    margin-right: 40px !important; }
  .mb-sm-40,
  .my-sm-40 {
    margin-bottom: 40px !important; }
  .ml-sm-40,
  .mx-sm-40 {
    margin-left: 40px !important; }
  .m-sm-45 {
    margin: 45px !important; }
  .mt-sm-45,
  .my-sm-45 {
    margin-top: 45px !important; }
  .mr-sm-45,
  .mx-sm-45 {
    margin-right: 45px !important; }
  .mb-sm-45,
  .my-sm-45 {
    margin-bottom: 45px !important; }
  .ml-sm-45,
  .mx-sm-45 {
    margin-left: 45px !important; }
  .m-sm-50 {
    margin: 50px !important; }
  .mt-sm-50,
  .my-sm-50 {
    margin-top: 50px !important; }
  .mr-sm-50,
  .mx-sm-50 {
    margin-right: 50px !important; }
  .mb-sm-50,
  .my-sm-50 {
    margin-bottom: 50px !important; }
  .ml-sm-50,
  .mx-sm-50 {
    margin-left: 50px !important; }
  .m-sm-55 {
    margin: 55px !important; }
  .mt-sm-55,
  .my-sm-55 {
    margin-top: 55px !important; }
  .mr-sm-55,
  .mx-sm-55 {
    margin-right: 55px !important; }
  .mb-sm-55,
  .my-sm-55 {
    margin-bottom: 55px !important; }
  .ml-sm-55,
  .mx-sm-55 {
    margin-left: 55px !important; }
  .m-sm-60 {
    margin: 60px !important; }
  .mt-sm-60,
  .my-sm-60 {
    margin-top: 60px !important; }
  .mr-sm-60,
  .mx-sm-60 {
    margin-right: 60px !important; }
  .mb-sm-60,
  .my-sm-60 {
    margin-bottom: 60px !important; }
  .ml-sm-60,
  .mx-sm-60 {
    margin-left: 60px !important; }
  .m-sm-65 {
    margin: 65px !important; }
  .mt-sm-65,
  .my-sm-65 {
    margin-top: 65px !important; }
  .mr-sm-65,
  .mx-sm-65 {
    margin-right: 65px !important; }
  .mb-sm-65,
  .my-sm-65 {
    margin-bottom: 65px !important; }
  .ml-sm-65,
  .mx-sm-65 {
    margin-left: 65px !important; }
  .m-sm-70 {
    margin: 70px !important; }
  .mt-sm-70,
  .my-sm-70 {
    margin-top: 70px !important; }
  .mr-sm-70,
  .mx-sm-70 {
    margin-right: 70px !important; }
  .mb-sm-70,
  .my-sm-70 {
    margin-bottom: 70px !important; }
  .ml-sm-70,
  .mx-sm-70 {
    margin-left: 70px !important; }
  .m-sm-75 {
    margin: 75px !important; }
  .mt-sm-75,
  .my-sm-75 {
    margin-top: 75px !important; }
  .mr-sm-75,
  .mx-sm-75 {
    margin-right: 75px !important; }
  .mb-sm-75,
  .my-sm-75 {
    margin-bottom: 75px !important; }
  .ml-sm-75,
  .mx-sm-75 {
    margin-left: 75px !important; }
  .m-sm-80 {
    margin: 80px !important; }
  .mt-sm-80,
  .my-sm-80 {
    margin-top: 80px !important; }
  .mr-sm-80,
  .mx-sm-80 {
    margin-right: 80px !important; }
  .mb-sm-80,
  .my-sm-80 {
    margin-bottom: 80px !important; }
  .ml-sm-80,
  .mx-sm-80 {
    margin-left: 80px !important; }
  .m-sm-85 {
    margin: 85px !important; }
  .mt-sm-85,
  .my-sm-85 {
    margin-top: 85px !important; }
  .mr-sm-85,
  .mx-sm-85 {
    margin-right: 85px !important; }
  .mb-sm-85,
  .my-sm-85 {
    margin-bottom: 85px !important; }
  .ml-sm-85,
  .mx-sm-85 {
    margin-left: 85px !important; }
  .m-sm-90 {
    margin: 90px !important; }
  .mt-sm-90,
  .my-sm-90 {
    margin-top: 90px !important; }
  .mr-sm-90,
  .mx-sm-90 {
    margin-right: 90px !important; }
  .mb-sm-90,
  .my-sm-90 {
    margin-bottom: 90px !important; }
  .ml-sm-90,
  .mx-sm-90 {
    margin-left: 90px !important; }
  .m-sm-95 {
    margin: 95px !important; }
  .mt-sm-95,
  .my-sm-95 {
    margin-top: 95px !important; }
  .mr-sm-95,
  .mx-sm-95 {
    margin-right: 95px !important; }
  .mb-sm-95,
  .my-sm-95 {
    margin-bottom: 95px !important; }
  .ml-sm-95,
  .mx-sm-95 {
    margin-left: 95px !important; }
  .m-sm-100 {
    margin: 100px !important; }
  .mt-sm-100,
  .my-sm-100 {
    margin-top: 100px !important; }
  .mr-sm-100,
  .mx-sm-100 {
    margin-right: 100px !important; }
  .mb-sm-100,
  .my-sm-100 {
    margin-bottom: 100px !important; }
  .ml-sm-100,
  .mx-sm-100 {
    margin-left: 100px !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-10 {
    padding: 10px !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10px !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10px !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10px !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10px !important; }
  .p-sm-15 {
    padding: 15px !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 15px !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 15px !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 15px !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 15px !important; }
  .p-sm-20 {
    padding: 20px !important; }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 20px !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 20px !important; }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 20px !important; }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 20px !important; }
  .p-sm-25 {
    padding: 25px !important; }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 25px !important; }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 25px !important; }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 25px !important; }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 25px !important; }
  .p-sm-30 {
    padding: 30px !important; }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 30px !important; }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 30px !important; }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 30px !important; }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 30px !important; }
  .p-sm-35 {
    padding: 35px !important; }
  .pt-sm-35,
  .py-sm-35 {
    padding-top: 35px !important; }
  .pr-sm-35,
  .px-sm-35 {
    padding-right: 35px !important; }
  .pb-sm-35,
  .py-sm-35 {
    padding-bottom: 35px !important; }
  .pl-sm-35,
  .px-sm-35 {
    padding-left: 35px !important; }
  .p-sm-40 {
    padding: 40px !important; }
  .pt-sm-40,
  .py-sm-40 {
    padding-top: 40px !important; }
  .pr-sm-40,
  .px-sm-40 {
    padding-right: 40px !important; }
  .pb-sm-40,
  .py-sm-40 {
    padding-bottom: 40px !important; }
  .pl-sm-40,
  .px-sm-40 {
    padding-left: 40px !important; }
  .p-sm-45 {
    padding: 45px !important; }
  .pt-sm-45,
  .py-sm-45 {
    padding-top: 45px !important; }
  .pr-sm-45,
  .px-sm-45 {
    padding-right: 45px !important; }
  .pb-sm-45,
  .py-sm-45 {
    padding-bottom: 45px !important; }
  .pl-sm-45,
  .px-sm-45 {
    padding-left: 45px !important; }
  .p-sm-50 {
    padding: 50px !important; }
  .pt-sm-50,
  .py-sm-50 {
    padding-top: 50px !important; }
  .pr-sm-50,
  .px-sm-50 {
    padding-right: 50px !important; }
  .pb-sm-50,
  .py-sm-50 {
    padding-bottom: 50px !important; }
  .pl-sm-50,
  .px-sm-50 {
    padding-left: 50px !important; }
  .p-sm-55 {
    padding: 55px !important; }
  .pt-sm-55,
  .py-sm-55 {
    padding-top: 55px !important; }
  .pr-sm-55,
  .px-sm-55 {
    padding-right: 55px !important; }
  .pb-sm-55,
  .py-sm-55 {
    padding-bottom: 55px !important; }
  .pl-sm-55,
  .px-sm-55 {
    padding-left: 55px !important; }
  .p-sm-60 {
    padding: 60px !important; }
  .pt-sm-60,
  .py-sm-60 {
    padding-top: 60px !important; }
  .pr-sm-60,
  .px-sm-60 {
    padding-right: 60px !important; }
  .pb-sm-60,
  .py-sm-60 {
    padding-bottom: 60px !important; }
  .pl-sm-60,
  .px-sm-60 {
    padding-left: 60px !important; }
  .p-sm-65 {
    padding: 65px !important; }
  .pt-sm-65,
  .py-sm-65 {
    padding-top: 65px !important; }
  .pr-sm-65,
  .px-sm-65 {
    padding-right: 65px !important; }
  .pb-sm-65,
  .py-sm-65 {
    padding-bottom: 65px !important; }
  .pl-sm-65,
  .px-sm-65 {
    padding-left: 65px !important; }
  .p-sm-70 {
    padding: 70px !important; }
  .pt-sm-70,
  .py-sm-70 {
    padding-top: 70px !important; }
  .pr-sm-70,
  .px-sm-70 {
    padding-right: 70px !important; }
  .pb-sm-70,
  .py-sm-70 {
    padding-bottom: 70px !important; }
  .pl-sm-70,
  .px-sm-70 {
    padding-left: 70px !important; }
  .p-sm-75 {
    padding: 75px !important; }
  .pt-sm-75,
  .py-sm-75 {
    padding-top: 75px !important; }
  .pr-sm-75,
  .px-sm-75 {
    padding-right: 75px !important; }
  .pb-sm-75,
  .py-sm-75 {
    padding-bottom: 75px !important; }
  .pl-sm-75,
  .px-sm-75 {
    padding-left: 75px !important; }
  .p-sm-80 {
    padding: 80px !important; }
  .pt-sm-80,
  .py-sm-80 {
    padding-top: 80px !important; }
  .pr-sm-80,
  .px-sm-80 {
    padding-right: 80px !important; }
  .pb-sm-80,
  .py-sm-80 {
    padding-bottom: 80px !important; }
  .pl-sm-80,
  .px-sm-80 {
    padding-left: 80px !important; }
  .p-sm-85 {
    padding: 85px !important; }
  .pt-sm-85,
  .py-sm-85 {
    padding-top: 85px !important; }
  .pr-sm-85,
  .px-sm-85 {
    padding-right: 85px !important; }
  .pb-sm-85,
  .py-sm-85 {
    padding-bottom: 85px !important; }
  .pl-sm-85,
  .px-sm-85 {
    padding-left: 85px !important; }
  .p-sm-90 {
    padding: 90px !important; }
  .pt-sm-90,
  .py-sm-90 {
    padding-top: 90px !important; }
  .pr-sm-90,
  .px-sm-90 {
    padding-right: 90px !important; }
  .pb-sm-90,
  .py-sm-90 {
    padding-bottom: 90px !important; }
  .pl-sm-90,
  .px-sm-90 {
    padding-left: 90px !important; }
  .p-sm-95 {
    padding: 95px !important; }
  .pt-sm-95,
  .py-sm-95 {
    padding-top: 95px !important; }
  .pr-sm-95,
  .px-sm-95 {
    padding-right: 95px !important; }
  .pb-sm-95,
  .py-sm-95 {
    padding-bottom: 95px !important; }
  .pl-sm-95,
  .px-sm-95 {
    padding-left: 95px !important; }
  .p-sm-100 {
    padding: 100px !important; }
  .pt-sm-100,
  .py-sm-100 {
    padding-top: 100px !important; }
  .pr-sm-100,
  .px-sm-100 {
    padding-right: 100px !important; }
  .pb-sm-100,
  .py-sm-100 {
    padding-bottom: 100px !important; }
  .pl-sm-100,
  .px-sm-100 {
    padding-left: 100px !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-n10 {
    margin: -10px !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -10px !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -10px !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -10px !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -10px !important; }
  .m-sm-n15 {
    margin: -15px !important; }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -15px !important; }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -15px !important; }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -15px !important; }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -15px !important; }
  .m-sm-n20 {
    margin: -20px !important; }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -20px !important; }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -20px !important; }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -20px !important; }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -20px !important; }
  .m-sm-n25 {
    margin: -25px !important; }
  .mt-sm-n25,
  .my-sm-n25 {
    margin-top: -25px !important; }
  .mr-sm-n25,
  .mx-sm-n25 {
    margin-right: -25px !important; }
  .mb-sm-n25,
  .my-sm-n25 {
    margin-bottom: -25px !important; }
  .ml-sm-n25,
  .mx-sm-n25 {
    margin-left: -25px !important; }
  .m-sm-n30 {
    margin: -30px !important; }
  .mt-sm-n30,
  .my-sm-n30 {
    margin-top: -30px !important; }
  .mr-sm-n30,
  .mx-sm-n30 {
    margin-right: -30px !important; }
  .mb-sm-n30,
  .my-sm-n30 {
    margin-bottom: -30px !important; }
  .ml-sm-n30,
  .mx-sm-n30 {
    margin-left: -30px !important; }
  .m-sm-n35 {
    margin: -35px !important; }
  .mt-sm-n35,
  .my-sm-n35 {
    margin-top: -35px !important; }
  .mr-sm-n35,
  .mx-sm-n35 {
    margin-right: -35px !important; }
  .mb-sm-n35,
  .my-sm-n35 {
    margin-bottom: -35px !important; }
  .ml-sm-n35,
  .mx-sm-n35 {
    margin-left: -35px !important; }
  .m-sm-n40 {
    margin: -40px !important; }
  .mt-sm-n40,
  .my-sm-n40 {
    margin-top: -40px !important; }
  .mr-sm-n40,
  .mx-sm-n40 {
    margin-right: -40px !important; }
  .mb-sm-n40,
  .my-sm-n40 {
    margin-bottom: -40px !important; }
  .ml-sm-n40,
  .mx-sm-n40 {
    margin-left: -40px !important; }
  .m-sm-n45 {
    margin: -45px !important; }
  .mt-sm-n45,
  .my-sm-n45 {
    margin-top: -45px !important; }
  .mr-sm-n45,
  .mx-sm-n45 {
    margin-right: -45px !important; }
  .mb-sm-n45,
  .my-sm-n45 {
    margin-bottom: -45px !important; }
  .ml-sm-n45,
  .mx-sm-n45 {
    margin-left: -45px !important; }
  .m-sm-n50 {
    margin: -50px !important; }
  .mt-sm-n50,
  .my-sm-n50 {
    margin-top: -50px !important; }
  .mr-sm-n50,
  .mx-sm-n50 {
    margin-right: -50px !important; }
  .mb-sm-n50,
  .my-sm-n50 {
    margin-bottom: -50px !important; }
  .ml-sm-n50,
  .mx-sm-n50 {
    margin-left: -50px !important; }
  .m-sm-n55 {
    margin: -55px !important; }
  .mt-sm-n55,
  .my-sm-n55 {
    margin-top: -55px !important; }
  .mr-sm-n55,
  .mx-sm-n55 {
    margin-right: -55px !important; }
  .mb-sm-n55,
  .my-sm-n55 {
    margin-bottom: -55px !important; }
  .ml-sm-n55,
  .mx-sm-n55 {
    margin-left: -55px !important; }
  .m-sm-n60 {
    margin: -60px !important; }
  .mt-sm-n60,
  .my-sm-n60 {
    margin-top: -60px !important; }
  .mr-sm-n60,
  .mx-sm-n60 {
    margin-right: -60px !important; }
  .mb-sm-n60,
  .my-sm-n60 {
    margin-bottom: -60px !important; }
  .ml-sm-n60,
  .mx-sm-n60 {
    margin-left: -60px !important; }
  .m-sm-n65 {
    margin: -65px !important; }
  .mt-sm-n65,
  .my-sm-n65 {
    margin-top: -65px !important; }
  .mr-sm-n65,
  .mx-sm-n65 {
    margin-right: -65px !important; }
  .mb-sm-n65,
  .my-sm-n65 {
    margin-bottom: -65px !important; }
  .ml-sm-n65,
  .mx-sm-n65 {
    margin-left: -65px !important; }
  .m-sm-n70 {
    margin: -70px !important; }
  .mt-sm-n70,
  .my-sm-n70 {
    margin-top: -70px !important; }
  .mr-sm-n70,
  .mx-sm-n70 {
    margin-right: -70px !important; }
  .mb-sm-n70,
  .my-sm-n70 {
    margin-bottom: -70px !important; }
  .ml-sm-n70,
  .mx-sm-n70 {
    margin-left: -70px !important; }
  .m-sm-n75 {
    margin: -75px !important; }
  .mt-sm-n75,
  .my-sm-n75 {
    margin-top: -75px !important; }
  .mr-sm-n75,
  .mx-sm-n75 {
    margin-right: -75px !important; }
  .mb-sm-n75,
  .my-sm-n75 {
    margin-bottom: -75px !important; }
  .ml-sm-n75,
  .mx-sm-n75 {
    margin-left: -75px !important; }
  .m-sm-n80 {
    margin: -80px !important; }
  .mt-sm-n80,
  .my-sm-n80 {
    margin-top: -80px !important; }
  .mr-sm-n80,
  .mx-sm-n80 {
    margin-right: -80px !important; }
  .mb-sm-n80,
  .my-sm-n80 {
    margin-bottom: -80px !important; }
  .ml-sm-n80,
  .mx-sm-n80 {
    margin-left: -80px !important; }
  .m-sm-n85 {
    margin: -85px !important; }
  .mt-sm-n85,
  .my-sm-n85 {
    margin-top: -85px !important; }
  .mr-sm-n85,
  .mx-sm-n85 {
    margin-right: -85px !important; }
  .mb-sm-n85,
  .my-sm-n85 {
    margin-bottom: -85px !important; }
  .ml-sm-n85,
  .mx-sm-n85 {
    margin-left: -85px !important; }
  .m-sm-n90 {
    margin: -90px !important; }
  .mt-sm-n90,
  .my-sm-n90 {
    margin-top: -90px !important; }
  .mr-sm-n90,
  .mx-sm-n90 {
    margin-right: -90px !important; }
  .mb-sm-n90,
  .my-sm-n90 {
    margin-bottom: -90px !important; }
  .ml-sm-n90,
  .mx-sm-n90 {
    margin-left: -90px !important; }
  .m-sm-n95 {
    margin: -95px !important; }
  .mt-sm-n95,
  .my-sm-n95 {
    margin-top: -95px !important; }
  .mr-sm-n95,
  .mx-sm-n95 {
    margin-right: -95px !important; }
  .mb-sm-n95,
  .my-sm-n95 {
    margin-bottom: -95px !important; }
  .ml-sm-n95,
  .mx-sm-n95 {
    margin-left: -95px !important; }
  .m-sm-n100 {
    margin: -100px !important; }
  .mt-sm-n100,
  .my-sm-n100 {
    margin-top: -100px !important; }
  .mr-sm-n100,
  .mx-sm-n100 {
    margin-right: -100px !important; }
  .mb-sm-n100,
  .my-sm-n100 {
    margin-bottom: -100px !important; }
  .ml-sm-n100,
  .mx-sm-n100 {
    margin-left: -100px !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-10 {
    margin: 10px !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10px !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10px !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10px !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10px !important; }
  .m-md-15 {
    margin: 15px !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 15px !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 15px !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 15px !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 15px !important; }
  .m-md-20 {
    margin: 20px !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 20px !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 20px !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 20px !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 20px !important; }
  .m-md-25 {
    margin: 25px !important; }
  .mt-md-25,
  .my-md-25 {
    margin-top: 25px !important; }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 25px !important; }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 25px !important; }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 25px !important; }
  .m-md-30 {
    margin: 30px !important; }
  .mt-md-30,
  .my-md-30 {
    margin-top: 30px !important; }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 30px !important; }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 30px !important; }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 30px !important; }
  .m-md-35 {
    margin: 35px !important; }
  .mt-md-35,
  .my-md-35 {
    margin-top: 35px !important; }
  .mr-md-35,
  .mx-md-35 {
    margin-right: 35px !important; }
  .mb-md-35,
  .my-md-35 {
    margin-bottom: 35px !important; }
  .ml-md-35,
  .mx-md-35 {
    margin-left: 35px !important; }
  .m-md-40 {
    margin: 40px !important; }
  .mt-md-40,
  .my-md-40 {
    margin-top: 40px !important; }
  .mr-md-40,
  .mx-md-40 {
    margin-right: 40px !important; }
  .mb-md-40,
  .my-md-40 {
    margin-bottom: 40px !important; }
  .ml-md-40,
  .mx-md-40 {
    margin-left: 40px !important; }
  .m-md-45 {
    margin: 45px !important; }
  .mt-md-45,
  .my-md-45 {
    margin-top: 45px !important; }
  .mr-md-45,
  .mx-md-45 {
    margin-right: 45px !important; }
  .mb-md-45,
  .my-md-45 {
    margin-bottom: 45px !important; }
  .ml-md-45,
  .mx-md-45 {
    margin-left: 45px !important; }
  .m-md-50 {
    margin: 50px !important; }
  .mt-md-50,
  .my-md-50 {
    margin-top: 50px !important; }
  .mr-md-50,
  .mx-md-50 {
    margin-right: 50px !important; }
  .mb-md-50,
  .my-md-50 {
    margin-bottom: 50px !important; }
  .ml-md-50,
  .mx-md-50 {
    margin-left: 50px !important; }
  .m-md-55 {
    margin: 55px !important; }
  .mt-md-55,
  .my-md-55 {
    margin-top: 55px !important; }
  .mr-md-55,
  .mx-md-55 {
    margin-right: 55px !important; }
  .mb-md-55,
  .my-md-55 {
    margin-bottom: 55px !important; }
  .ml-md-55,
  .mx-md-55 {
    margin-left: 55px !important; }
  .m-md-60 {
    margin: 60px !important; }
  .mt-md-60,
  .my-md-60 {
    margin-top: 60px !important; }
  .mr-md-60,
  .mx-md-60 {
    margin-right: 60px !important; }
  .mb-md-60,
  .my-md-60 {
    margin-bottom: 60px !important; }
  .ml-md-60,
  .mx-md-60 {
    margin-left: 60px !important; }
  .m-md-65 {
    margin: 65px !important; }
  .mt-md-65,
  .my-md-65 {
    margin-top: 65px !important; }
  .mr-md-65,
  .mx-md-65 {
    margin-right: 65px !important; }
  .mb-md-65,
  .my-md-65 {
    margin-bottom: 65px !important; }
  .ml-md-65,
  .mx-md-65 {
    margin-left: 65px !important; }
  .m-md-70 {
    margin: 70px !important; }
  .mt-md-70,
  .my-md-70 {
    margin-top: 70px !important; }
  .mr-md-70,
  .mx-md-70 {
    margin-right: 70px !important; }
  .mb-md-70,
  .my-md-70 {
    margin-bottom: 70px !important; }
  .ml-md-70,
  .mx-md-70 {
    margin-left: 70px !important; }
  .m-md-75 {
    margin: 75px !important; }
  .mt-md-75,
  .my-md-75 {
    margin-top: 75px !important; }
  .mr-md-75,
  .mx-md-75 {
    margin-right: 75px !important; }
  .mb-md-75,
  .my-md-75 {
    margin-bottom: 75px !important; }
  .ml-md-75,
  .mx-md-75 {
    margin-left: 75px !important; }
  .m-md-80 {
    margin: 80px !important; }
  .mt-md-80,
  .my-md-80 {
    margin-top: 80px !important; }
  .mr-md-80,
  .mx-md-80 {
    margin-right: 80px !important; }
  .mb-md-80,
  .my-md-80 {
    margin-bottom: 80px !important; }
  .ml-md-80,
  .mx-md-80 {
    margin-left: 80px !important; }
  .m-md-85 {
    margin: 85px !important; }
  .mt-md-85,
  .my-md-85 {
    margin-top: 85px !important; }
  .mr-md-85,
  .mx-md-85 {
    margin-right: 85px !important; }
  .mb-md-85,
  .my-md-85 {
    margin-bottom: 85px !important; }
  .ml-md-85,
  .mx-md-85 {
    margin-left: 85px !important; }
  .m-md-90 {
    margin: 90px !important; }
  .mt-md-90,
  .my-md-90 {
    margin-top: 90px !important; }
  .mr-md-90,
  .mx-md-90 {
    margin-right: 90px !important; }
  .mb-md-90,
  .my-md-90 {
    margin-bottom: 90px !important; }
  .ml-md-90,
  .mx-md-90 {
    margin-left: 90px !important; }
  .m-md-95 {
    margin: 95px !important; }
  .mt-md-95,
  .my-md-95 {
    margin-top: 95px !important; }
  .mr-md-95,
  .mx-md-95 {
    margin-right: 95px !important; }
  .mb-md-95,
  .my-md-95 {
    margin-bottom: 95px !important; }
  .ml-md-95,
  .mx-md-95 {
    margin-left: 95px !important; }
  .m-md-100 {
    margin: 100px !important; }
  .mt-md-100,
  .my-md-100 {
    margin-top: 100px !important; }
  .mr-md-100,
  .mx-md-100 {
    margin-right: 100px !important; }
  .mb-md-100,
  .my-md-100 {
    margin-bottom: 100px !important; }
  .ml-md-100,
  .mx-md-100 {
    margin-left: 100px !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-10 {
    padding: 10px !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10px !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10px !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10px !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10px !important; }
  .p-md-15 {
    padding: 15px !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 15px !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 15px !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 15px !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 15px !important; }
  .p-md-20 {
    padding: 20px !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 20px !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 20px !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 20px !important; }
  .pl-md-20,
  .px-md-20 {
    padding-left: 20px !important; }
  .p-md-25 {
    padding: 25px !important; }
  .pt-md-25,
  .py-md-25 {
    padding-top: 25px !important; }
  .pr-md-25,
  .px-md-25 {
    padding-right: 25px !important; }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 25px !important; }
  .pl-md-25,
  .px-md-25 {
    padding-left: 25px !important; }
  .p-md-30 {
    padding: 30px !important; }
  .pt-md-30,
  .py-md-30 {
    padding-top: 30px !important; }
  .pr-md-30,
  .px-md-30 {
    padding-right: 30px !important; }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 30px !important; }
  .pl-md-30,
  .px-md-30 {
    padding-left: 30px !important; }
  .p-md-35 {
    padding: 35px !important; }
  .pt-md-35,
  .py-md-35 {
    padding-top: 35px !important; }
  .pr-md-35,
  .px-md-35 {
    padding-right: 35px !important; }
  .pb-md-35,
  .py-md-35 {
    padding-bottom: 35px !important; }
  .pl-md-35,
  .px-md-35 {
    padding-left: 35px !important; }
  .p-md-40 {
    padding: 40px !important; }
  .pt-md-40,
  .py-md-40 {
    padding-top: 40px !important; }
  .pr-md-40,
  .px-md-40 {
    padding-right: 40px !important; }
  .pb-md-40,
  .py-md-40 {
    padding-bottom: 40px !important; }
  .pl-md-40,
  .px-md-40 {
    padding-left: 40px !important; }
  .p-md-45 {
    padding: 45px !important; }
  .pt-md-45,
  .py-md-45 {
    padding-top: 45px !important; }
  .pr-md-45,
  .px-md-45 {
    padding-right: 45px !important; }
  .pb-md-45,
  .py-md-45 {
    padding-bottom: 45px !important; }
  .pl-md-45,
  .px-md-45 {
    padding-left: 45px !important; }
  .p-md-50 {
    padding: 50px !important; }
  .pt-md-50,
  .py-md-50 {
    padding-top: 50px !important; }
  .pr-md-50,
  .px-md-50 {
    padding-right: 50px !important; }
  .pb-md-50,
  .py-md-50 {
    padding-bottom: 50px !important; }
  .pl-md-50,
  .px-md-50 {
    padding-left: 50px !important; }
  .p-md-55 {
    padding: 55px !important; }
  .pt-md-55,
  .py-md-55 {
    padding-top: 55px !important; }
  .pr-md-55,
  .px-md-55 {
    padding-right: 55px !important; }
  .pb-md-55,
  .py-md-55 {
    padding-bottom: 55px !important; }
  .pl-md-55,
  .px-md-55 {
    padding-left: 55px !important; }
  .p-md-60 {
    padding: 60px !important; }
  .pt-md-60,
  .py-md-60 {
    padding-top: 60px !important; }
  .pr-md-60,
  .px-md-60 {
    padding-right: 60px !important; }
  .pb-md-60,
  .py-md-60 {
    padding-bottom: 60px !important; }
  .pl-md-60,
  .px-md-60 {
    padding-left: 60px !important; }
  .p-md-65 {
    padding: 65px !important; }
  .pt-md-65,
  .py-md-65 {
    padding-top: 65px !important; }
  .pr-md-65,
  .px-md-65 {
    padding-right: 65px !important; }
  .pb-md-65,
  .py-md-65 {
    padding-bottom: 65px !important; }
  .pl-md-65,
  .px-md-65 {
    padding-left: 65px !important; }
  .p-md-70 {
    padding: 70px !important; }
  .pt-md-70,
  .py-md-70 {
    padding-top: 70px !important; }
  .pr-md-70,
  .px-md-70 {
    padding-right: 70px !important; }
  .pb-md-70,
  .py-md-70 {
    padding-bottom: 70px !important; }
  .pl-md-70,
  .px-md-70 {
    padding-left: 70px !important; }
  .p-md-75 {
    padding: 75px !important; }
  .pt-md-75,
  .py-md-75 {
    padding-top: 75px !important; }
  .pr-md-75,
  .px-md-75 {
    padding-right: 75px !important; }
  .pb-md-75,
  .py-md-75 {
    padding-bottom: 75px !important; }
  .pl-md-75,
  .px-md-75 {
    padding-left: 75px !important; }
  .p-md-80 {
    padding: 80px !important; }
  .pt-md-80,
  .py-md-80 {
    padding-top: 80px !important; }
  .pr-md-80,
  .px-md-80 {
    padding-right: 80px !important; }
  .pb-md-80,
  .py-md-80 {
    padding-bottom: 80px !important; }
  .pl-md-80,
  .px-md-80 {
    padding-left: 80px !important; }
  .p-md-85 {
    padding: 85px !important; }
  .pt-md-85,
  .py-md-85 {
    padding-top: 85px !important; }
  .pr-md-85,
  .px-md-85 {
    padding-right: 85px !important; }
  .pb-md-85,
  .py-md-85 {
    padding-bottom: 85px !important; }
  .pl-md-85,
  .px-md-85 {
    padding-left: 85px !important; }
  .p-md-90 {
    padding: 90px !important; }
  .pt-md-90,
  .py-md-90 {
    padding-top: 90px !important; }
  .pr-md-90,
  .px-md-90 {
    padding-right: 90px !important; }
  .pb-md-90,
  .py-md-90 {
    padding-bottom: 90px !important; }
  .pl-md-90,
  .px-md-90 {
    padding-left: 90px !important; }
  .p-md-95 {
    padding: 95px !important; }
  .pt-md-95,
  .py-md-95 {
    padding-top: 95px !important; }
  .pr-md-95,
  .px-md-95 {
    padding-right: 95px !important; }
  .pb-md-95,
  .py-md-95 {
    padding-bottom: 95px !important; }
  .pl-md-95,
  .px-md-95 {
    padding-left: 95px !important; }
  .p-md-100 {
    padding: 100px !important; }
  .pt-md-100,
  .py-md-100 {
    padding-top: 100px !important; }
  .pr-md-100,
  .px-md-100 {
    padding-right: 100px !important; }
  .pb-md-100,
  .py-md-100 {
    padding-bottom: 100px !important; }
  .pl-md-100,
  .px-md-100 {
    padding-left: 100px !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-n10 {
    margin: -10px !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -10px !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -10px !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -10px !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -10px !important; }
  .m-md-n15 {
    margin: -15px !important; }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -15px !important; }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -15px !important; }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -15px !important; }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -15px !important; }
  .m-md-n20 {
    margin: -20px !important; }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -20px !important; }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -20px !important; }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -20px !important; }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -20px !important; }
  .m-md-n25 {
    margin: -25px !important; }
  .mt-md-n25,
  .my-md-n25 {
    margin-top: -25px !important; }
  .mr-md-n25,
  .mx-md-n25 {
    margin-right: -25px !important; }
  .mb-md-n25,
  .my-md-n25 {
    margin-bottom: -25px !important; }
  .ml-md-n25,
  .mx-md-n25 {
    margin-left: -25px !important; }
  .m-md-n30 {
    margin: -30px !important; }
  .mt-md-n30,
  .my-md-n30 {
    margin-top: -30px !important; }
  .mr-md-n30,
  .mx-md-n30 {
    margin-right: -30px !important; }
  .mb-md-n30,
  .my-md-n30 {
    margin-bottom: -30px !important; }
  .ml-md-n30,
  .mx-md-n30 {
    margin-left: -30px !important; }
  .m-md-n35 {
    margin: -35px !important; }
  .mt-md-n35,
  .my-md-n35 {
    margin-top: -35px !important; }
  .mr-md-n35,
  .mx-md-n35 {
    margin-right: -35px !important; }
  .mb-md-n35,
  .my-md-n35 {
    margin-bottom: -35px !important; }
  .ml-md-n35,
  .mx-md-n35 {
    margin-left: -35px !important; }
  .m-md-n40 {
    margin: -40px !important; }
  .mt-md-n40,
  .my-md-n40 {
    margin-top: -40px !important; }
  .mr-md-n40,
  .mx-md-n40 {
    margin-right: -40px !important; }
  .mb-md-n40,
  .my-md-n40 {
    margin-bottom: -40px !important; }
  .ml-md-n40,
  .mx-md-n40 {
    margin-left: -40px !important; }
  .m-md-n45 {
    margin: -45px !important; }
  .mt-md-n45,
  .my-md-n45 {
    margin-top: -45px !important; }
  .mr-md-n45,
  .mx-md-n45 {
    margin-right: -45px !important; }
  .mb-md-n45,
  .my-md-n45 {
    margin-bottom: -45px !important; }
  .ml-md-n45,
  .mx-md-n45 {
    margin-left: -45px !important; }
  .m-md-n50 {
    margin: -50px !important; }
  .mt-md-n50,
  .my-md-n50 {
    margin-top: -50px !important; }
  .mr-md-n50,
  .mx-md-n50 {
    margin-right: -50px !important; }
  .mb-md-n50,
  .my-md-n50 {
    margin-bottom: -50px !important; }
  .ml-md-n50,
  .mx-md-n50 {
    margin-left: -50px !important; }
  .m-md-n55 {
    margin: -55px !important; }
  .mt-md-n55,
  .my-md-n55 {
    margin-top: -55px !important; }
  .mr-md-n55,
  .mx-md-n55 {
    margin-right: -55px !important; }
  .mb-md-n55,
  .my-md-n55 {
    margin-bottom: -55px !important; }
  .ml-md-n55,
  .mx-md-n55 {
    margin-left: -55px !important; }
  .m-md-n60 {
    margin: -60px !important; }
  .mt-md-n60,
  .my-md-n60 {
    margin-top: -60px !important; }
  .mr-md-n60,
  .mx-md-n60 {
    margin-right: -60px !important; }
  .mb-md-n60,
  .my-md-n60 {
    margin-bottom: -60px !important; }
  .ml-md-n60,
  .mx-md-n60 {
    margin-left: -60px !important; }
  .m-md-n65 {
    margin: -65px !important; }
  .mt-md-n65,
  .my-md-n65 {
    margin-top: -65px !important; }
  .mr-md-n65,
  .mx-md-n65 {
    margin-right: -65px !important; }
  .mb-md-n65,
  .my-md-n65 {
    margin-bottom: -65px !important; }
  .ml-md-n65,
  .mx-md-n65 {
    margin-left: -65px !important; }
  .m-md-n70 {
    margin: -70px !important; }
  .mt-md-n70,
  .my-md-n70 {
    margin-top: -70px !important; }
  .mr-md-n70,
  .mx-md-n70 {
    margin-right: -70px !important; }
  .mb-md-n70,
  .my-md-n70 {
    margin-bottom: -70px !important; }
  .ml-md-n70,
  .mx-md-n70 {
    margin-left: -70px !important; }
  .m-md-n75 {
    margin: -75px !important; }
  .mt-md-n75,
  .my-md-n75 {
    margin-top: -75px !important; }
  .mr-md-n75,
  .mx-md-n75 {
    margin-right: -75px !important; }
  .mb-md-n75,
  .my-md-n75 {
    margin-bottom: -75px !important; }
  .ml-md-n75,
  .mx-md-n75 {
    margin-left: -75px !important; }
  .m-md-n80 {
    margin: -80px !important; }
  .mt-md-n80,
  .my-md-n80 {
    margin-top: -80px !important; }
  .mr-md-n80,
  .mx-md-n80 {
    margin-right: -80px !important; }
  .mb-md-n80,
  .my-md-n80 {
    margin-bottom: -80px !important; }
  .ml-md-n80,
  .mx-md-n80 {
    margin-left: -80px !important; }
  .m-md-n85 {
    margin: -85px !important; }
  .mt-md-n85,
  .my-md-n85 {
    margin-top: -85px !important; }
  .mr-md-n85,
  .mx-md-n85 {
    margin-right: -85px !important; }
  .mb-md-n85,
  .my-md-n85 {
    margin-bottom: -85px !important; }
  .ml-md-n85,
  .mx-md-n85 {
    margin-left: -85px !important; }
  .m-md-n90 {
    margin: -90px !important; }
  .mt-md-n90,
  .my-md-n90 {
    margin-top: -90px !important; }
  .mr-md-n90,
  .mx-md-n90 {
    margin-right: -90px !important; }
  .mb-md-n90,
  .my-md-n90 {
    margin-bottom: -90px !important; }
  .ml-md-n90,
  .mx-md-n90 {
    margin-left: -90px !important; }
  .m-md-n95 {
    margin: -95px !important; }
  .mt-md-n95,
  .my-md-n95 {
    margin-top: -95px !important; }
  .mr-md-n95,
  .mx-md-n95 {
    margin-right: -95px !important; }
  .mb-md-n95,
  .my-md-n95 {
    margin-bottom: -95px !important; }
  .ml-md-n95,
  .mx-md-n95 {
    margin-left: -95px !important; }
  .m-md-n100 {
    margin: -100px !important; }
  .mt-md-n100,
  .my-md-n100 {
    margin-top: -100px !important; }
  .mr-md-n100,
  .mx-md-n100 {
    margin-right: -100px !important; }
  .mb-md-n100,
  .my-md-n100 {
    margin-bottom: -100px !important; }
  .ml-md-n100,
  .mx-md-n100 {
    margin-left: -100px !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-10 {
    margin: 10px !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10px !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10px !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10px !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10px !important; }
  .m-lg-15 {
    margin: 15px !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 15px !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 15px !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 15px !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 15px !important; }
  .m-lg-20 {
    margin: 20px !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 20px !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 20px !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 20px !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 20px !important; }
  .m-lg-25 {
    margin: 25px !important; }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 25px !important; }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 25px !important; }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 25px !important; }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 25px !important; }
  .m-lg-30 {
    margin: 30px !important; }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 30px !important; }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 30px !important; }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 30px !important; }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 30px !important; }
  .m-lg-35 {
    margin: 35px !important; }
  .mt-lg-35,
  .my-lg-35 {
    margin-top: 35px !important; }
  .mr-lg-35,
  .mx-lg-35 {
    margin-right: 35px !important; }
  .mb-lg-35,
  .my-lg-35 {
    margin-bottom: 35px !important; }
  .ml-lg-35,
  .mx-lg-35 {
    margin-left: 35px !important; }
  .m-lg-40 {
    margin: 40px !important; }
  .mt-lg-40,
  .my-lg-40 {
    margin-top: 40px !important; }
  .mr-lg-40,
  .mx-lg-40 {
    margin-right: 40px !important; }
  .mb-lg-40,
  .my-lg-40 {
    margin-bottom: 40px !important; }
  .ml-lg-40,
  .mx-lg-40 {
    margin-left: 40px !important; }
  .m-lg-45 {
    margin: 45px !important; }
  .mt-lg-45,
  .my-lg-45 {
    margin-top: 45px !important; }
  .mr-lg-45,
  .mx-lg-45 {
    margin-right: 45px !important; }
  .mb-lg-45,
  .my-lg-45 {
    margin-bottom: 45px !important; }
  .ml-lg-45,
  .mx-lg-45 {
    margin-left: 45px !important; }
  .m-lg-50 {
    margin: 50px !important; }
  .mt-lg-50,
  .my-lg-50 {
    margin-top: 50px !important; }
  .mr-lg-50,
  .mx-lg-50 {
    margin-right: 50px !important; }
  .mb-lg-50,
  .my-lg-50 {
    margin-bottom: 50px !important; }
  .ml-lg-50,
  .mx-lg-50 {
    margin-left: 50px !important; }
  .m-lg-55 {
    margin: 55px !important; }
  .mt-lg-55,
  .my-lg-55 {
    margin-top: 55px !important; }
  .mr-lg-55,
  .mx-lg-55 {
    margin-right: 55px !important; }
  .mb-lg-55,
  .my-lg-55 {
    margin-bottom: 55px !important; }
  .ml-lg-55,
  .mx-lg-55 {
    margin-left: 55px !important; }
  .m-lg-60 {
    margin: 60px !important; }
  .mt-lg-60,
  .my-lg-60 {
    margin-top: 60px !important; }
  .mr-lg-60,
  .mx-lg-60 {
    margin-right: 60px !important; }
  .mb-lg-60,
  .my-lg-60 {
    margin-bottom: 60px !important; }
  .ml-lg-60,
  .mx-lg-60 {
    margin-left: 60px !important; }
  .m-lg-65 {
    margin: 65px !important; }
  .mt-lg-65,
  .my-lg-65 {
    margin-top: 65px !important; }
  .mr-lg-65,
  .mx-lg-65 {
    margin-right: 65px !important; }
  .mb-lg-65,
  .my-lg-65 {
    margin-bottom: 65px !important; }
  .ml-lg-65,
  .mx-lg-65 {
    margin-left: 65px !important; }
  .m-lg-70 {
    margin: 70px !important; }
  .mt-lg-70,
  .my-lg-70 {
    margin-top: 70px !important; }
  .mr-lg-70,
  .mx-lg-70 {
    margin-right: 70px !important; }
  .mb-lg-70,
  .my-lg-70 {
    margin-bottom: 70px !important; }
  .ml-lg-70,
  .mx-lg-70 {
    margin-left: 70px !important; }
  .m-lg-75 {
    margin: 75px !important; }
  .mt-lg-75,
  .my-lg-75 {
    margin-top: 75px !important; }
  .mr-lg-75,
  .mx-lg-75 {
    margin-right: 75px !important; }
  .mb-lg-75,
  .my-lg-75 {
    margin-bottom: 75px !important; }
  .ml-lg-75,
  .mx-lg-75 {
    margin-left: 75px !important; }
  .m-lg-80 {
    margin: 80px !important; }
  .mt-lg-80,
  .my-lg-80 {
    margin-top: 80px !important; }
  .mr-lg-80,
  .mx-lg-80 {
    margin-right: 80px !important; }
  .mb-lg-80,
  .my-lg-80 {
    margin-bottom: 80px !important; }
  .ml-lg-80,
  .mx-lg-80 {
    margin-left: 80px !important; }
  .m-lg-85 {
    margin: 85px !important; }
  .mt-lg-85,
  .my-lg-85 {
    margin-top: 85px !important; }
  .mr-lg-85,
  .mx-lg-85 {
    margin-right: 85px !important; }
  .mb-lg-85,
  .my-lg-85 {
    margin-bottom: 85px !important; }
  .ml-lg-85,
  .mx-lg-85 {
    margin-left: 85px !important; }
  .m-lg-90 {
    margin: 90px !important; }
  .mt-lg-90,
  .my-lg-90 {
    margin-top: 90px !important; }
  .mr-lg-90,
  .mx-lg-90 {
    margin-right: 90px !important; }
  .mb-lg-90,
  .my-lg-90 {
    margin-bottom: 90px !important; }
  .ml-lg-90,
  .mx-lg-90 {
    margin-left: 90px !important; }
  .m-lg-95 {
    margin: 95px !important; }
  .mt-lg-95,
  .my-lg-95 {
    margin-top: 95px !important; }
  .mr-lg-95,
  .mx-lg-95 {
    margin-right: 95px !important; }
  .mb-lg-95,
  .my-lg-95 {
    margin-bottom: 95px !important; }
  .ml-lg-95,
  .mx-lg-95 {
    margin-left: 95px !important; }
  .m-lg-100 {
    margin: 100px !important; }
  .mt-lg-100,
  .my-lg-100 {
    margin-top: 100px !important; }
  .mr-lg-100,
  .mx-lg-100 {
    margin-right: 100px !important; }
  .mb-lg-100,
  .my-lg-100 {
    margin-bottom: 100px !important; }
  .ml-lg-100,
  .mx-lg-100 {
    margin-left: 100px !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-10 {
    padding: 10px !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10px !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10px !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10px !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10px !important; }
  .p-lg-15 {
    padding: 15px !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 15px !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 15px !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 15px !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 15px !important; }
  .p-lg-20 {
    padding: 20px !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 20px !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 20px !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 20px !important; }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 20px !important; }
  .p-lg-25 {
    padding: 25px !important; }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 25px !important; }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 25px !important; }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 25px !important; }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 25px !important; }
  .p-lg-30 {
    padding: 30px !important; }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 30px !important; }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 30px !important; }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 30px !important; }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 30px !important; }
  .p-lg-35 {
    padding: 35px !important; }
  .pt-lg-35,
  .py-lg-35 {
    padding-top: 35px !important; }
  .pr-lg-35,
  .px-lg-35 {
    padding-right: 35px !important; }
  .pb-lg-35,
  .py-lg-35 {
    padding-bottom: 35px !important; }
  .pl-lg-35,
  .px-lg-35 {
    padding-left: 35px !important; }
  .p-lg-40 {
    padding: 40px !important; }
  .pt-lg-40,
  .py-lg-40 {
    padding-top: 40px !important; }
  .pr-lg-40,
  .px-lg-40 {
    padding-right: 40px !important; }
  .pb-lg-40,
  .py-lg-40 {
    padding-bottom: 40px !important; }
  .pl-lg-40,
  .px-lg-40 {
    padding-left: 40px !important; }
  .p-lg-45 {
    padding: 45px !important; }
  .pt-lg-45,
  .py-lg-45 {
    padding-top: 45px !important; }
  .pr-lg-45,
  .px-lg-45 {
    padding-right: 45px !important; }
  .pb-lg-45,
  .py-lg-45 {
    padding-bottom: 45px !important; }
  .pl-lg-45,
  .px-lg-45 {
    padding-left: 45px !important; }
  .p-lg-50 {
    padding: 50px !important; }
  .pt-lg-50,
  .py-lg-50 {
    padding-top: 50px !important; }
  .pr-lg-50,
  .px-lg-50 {
    padding-right: 50px !important; }
  .pb-lg-50,
  .py-lg-50 {
    padding-bottom: 50px !important; }
  .pl-lg-50,
  .px-lg-50 {
    padding-left: 50px !important; }
  .p-lg-55 {
    padding: 55px !important; }
  .pt-lg-55,
  .py-lg-55 {
    padding-top: 55px !important; }
  .pr-lg-55,
  .px-lg-55 {
    padding-right: 55px !important; }
  .pb-lg-55,
  .py-lg-55 {
    padding-bottom: 55px !important; }
  .pl-lg-55,
  .px-lg-55 {
    padding-left: 55px !important; }
  .p-lg-60 {
    padding: 60px !important; }
  .pt-lg-60,
  .py-lg-60 {
    padding-top: 60px !important; }
  .pr-lg-60,
  .px-lg-60 {
    padding-right: 60px !important; }
  .pb-lg-60,
  .py-lg-60 {
    padding-bottom: 60px !important; }
  .pl-lg-60,
  .px-lg-60 {
    padding-left: 60px !important; }
  .p-lg-65 {
    padding: 65px !important; }
  .pt-lg-65,
  .py-lg-65 {
    padding-top: 65px !important; }
  .pr-lg-65,
  .px-lg-65 {
    padding-right: 65px !important; }
  .pb-lg-65,
  .py-lg-65 {
    padding-bottom: 65px !important; }
  .pl-lg-65,
  .px-lg-65 {
    padding-left: 65px !important; }
  .p-lg-70 {
    padding: 70px !important; }
  .pt-lg-70,
  .py-lg-70 {
    padding-top: 70px !important; }
  .pr-lg-70,
  .px-lg-70 {
    padding-right: 70px !important; }
  .pb-lg-70,
  .py-lg-70 {
    padding-bottom: 70px !important; }
  .pl-lg-70,
  .px-lg-70 {
    padding-left: 70px !important; }
  .p-lg-75 {
    padding: 75px !important; }
  .pt-lg-75,
  .py-lg-75 {
    padding-top: 75px !important; }
  .pr-lg-75,
  .px-lg-75 {
    padding-right: 75px !important; }
  .pb-lg-75,
  .py-lg-75 {
    padding-bottom: 75px !important; }
  .pl-lg-75,
  .px-lg-75 {
    padding-left: 75px !important; }
  .p-lg-80 {
    padding: 80px !important; }
  .pt-lg-80,
  .py-lg-80 {
    padding-top: 80px !important; }
  .pr-lg-80,
  .px-lg-80 {
    padding-right: 80px !important; }
  .pb-lg-80,
  .py-lg-80 {
    padding-bottom: 80px !important; }
  .pl-lg-80,
  .px-lg-80 {
    padding-left: 80px !important; }
  .p-lg-85 {
    padding: 85px !important; }
  .pt-lg-85,
  .py-lg-85 {
    padding-top: 85px !important; }
  .pr-lg-85,
  .px-lg-85 {
    padding-right: 85px !important; }
  .pb-lg-85,
  .py-lg-85 {
    padding-bottom: 85px !important; }
  .pl-lg-85,
  .px-lg-85 {
    padding-left: 85px !important; }
  .p-lg-90 {
    padding: 90px !important; }
  .pt-lg-90,
  .py-lg-90 {
    padding-top: 90px !important; }
  .pr-lg-90,
  .px-lg-90 {
    padding-right: 90px !important; }
  .pb-lg-90,
  .py-lg-90 {
    padding-bottom: 90px !important; }
  .pl-lg-90,
  .px-lg-90 {
    padding-left: 90px !important; }
  .p-lg-95 {
    padding: 95px !important; }
  .pt-lg-95,
  .py-lg-95 {
    padding-top: 95px !important; }
  .pr-lg-95,
  .px-lg-95 {
    padding-right: 95px !important; }
  .pb-lg-95,
  .py-lg-95 {
    padding-bottom: 95px !important; }
  .pl-lg-95,
  .px-lg-95 {
    padding-left: 95px !important; }
  .p-lg-100 {
    padding: 100px !important; }
  .pt-lg-100,
  .py-lg-100 {
    padding-top: 100px !important; }
  .pr-lg-100,
  .px-lg-100 {
    padding-right: 100px !important; }
  .pb-lg-100,
  .py-lg-100 {
    padding-bottom: 100px !important; }
  .pl-lg-100,
  .px-lg-100 {
    padding-left: 100px !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-n10 {
    margin: -10px !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -10px !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -10px !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -10px !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -10px !important; }
  .m-lg-n15 {
    margin: -15px !important; }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -15px !important; }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -15px !important; }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -15px !important; }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -15px !important; }
  .m-lg-n20 {
    margin: -20px !important; }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -20px !important; }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -20px !important; }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -20px !important; }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -20px !important; }
  .m-lg-n25 {
    margin: -25px !important; }
  .mt-lg-n25,
  .my-lg-n25 {
    margin-top: -25px !important; }
  .mr-lg-n25,
  .mx-lg-n25 {
    margin-right: -25px !important; }
  .mb-lg-n25,
  .my-lg-n25 {
    margin-bottom: -25px !important; }
  .ml-lg-n25,
  .mx-lg-n25 {
    margin-left: -25px !important; }
  .m-lg-n30 {
    margin: -30px !important; }
  .mt-lg-n30,
  .my-lg-n30 {
    margin-top: -30px !important; }
  .mr-lg-n30,
  .mx-lg-n30 {
    margin-right: -30px !important; }
  .mb-lg-n30,
  .my-lg-n30 {
    margin-bottom: -30px !important; }
  .ml-lg-n30,
  .mx-lg-n30 {
    margin-left: -30px !important; }
  .m-lg-n35 {
    margin: -35px !important; }
  .mt-lg-n35,
  .my-lg-n35 {
    margin-top: -35px !important; }
  .mr-lg-n35,
  .mx-lg-n35 {
    margin-right: -35px !important; }
  .mb-lg-n35,
  .my-lg-n35 {
    margin-bottom: -35px !important; }
  .ml-lg-n35,
  .mx-lg-n35 {
    margin-left: -35px !important; }
  .m-lg-n40 {
    margin: -40px !important; }
  .mt-lg-n40,
  .my-lg-n40 {
    margin-top: -40px !important; }
  .mr-lg-n40,
  .mx-lg-n40 {
    margin-right: -40px !important; }
  .mb-lg-n40,
  .my-lg-n40 {
    margin-bottom: -40px !important; }
  .ml-lg-n40,
  .mx-lg-n40 {
    margin-left: -40px !important; }
  .m-lg-n45 {
    margin: -45px !important; }
  .mt-lg-n45,
  .my-lg-n45 {
    margin-top: -45px !important; }
  .mr-lg-n45,
  .mx-lg-n45 {
    margin-right: -45px !important; }
  .mb-lg-n45,
  .my-lg-n45 {
    margin-bottom: -45px !important; }
  .ml-lg-n45,
  .mx-lg-n45 {
    margin-left: -45px !important; }
  .m-lg-n50 {
    margin: -50px !important; }
  .mt-lg-n50,
  .my-lg-n50 {
    margin-top: -50px !important; }
  .mr-lg-n50,
  .mx-lg-n50 {
    margin-right: -50px !important; }
  .mb-lg-n50,
  .my-lg-n50 {
    margin-bottom: -50px !important; }
  .ml-lg-n50,
  .mx-lg-n50 {
    margin-left: -50px !important; }
  .m-lg-n55 {
    margin: -55px !important; }
  .mt-lg-n55,
  .my-lg-n55 {
    margin-top: -55px !important; }
  .mr-lg-n55,
  .mx-lg-n55 {
    margin-right: -55px !important; }
  .mb-lg-n55,
  .my-lg-n55 {
    margin-bottom: -55px !important; }
  .ml-lg-n55,
  .mx-lg-n55 {
    margin-left: -55px !important; }
  .m-lg-n60 {
    margin: -60px !important; }
  .mt-lg-n60,
  .my-lg-n60 {
    margin-top: -60px !important; }
  .mr-lg-n60,
  .mx-lg-n60 {
    margin-right: -60px !important; }
  .mb-lg-n60,
  .my-lg-n60 {
    margin-bottom: -60px !important; }
  .ml-lg-n60,
  .mx-lg-n60 {
    margin-left: -60px !important; }
  .m-lg-n65 {
    margin: -65px !important; }
  .mt-lg-n65,
  .my-lg-n65 {
    margin-top: -65px !important; }
  .mr-lg-n65,
  .mx-lg-n65 {
    margin-right: -65px !important; }
  .mb-lg-n65,
  .my-lg-n65 {
    margin-bottom: -65px !important; }
  .ml-lg-n65,
  .mx-lg-n65 {
    margin-left: -65px !important; }
  .m-lg-n70 {
    margin: -70px !important; }
  .mt-lg-n70,
  .my-lg-n70 {
    margin-top: -70px !important; }
  .mr-lg-n70,
  .mx-lg-n70 {
    margin-right: -70px !important; }
  .mb-lg-n70,
  .my-lg-n70 {
    margin-bottom: -70px !important; }
  .ml-lg-n70,
  .mx-lg-n70 {
    margin-left: -70px !important; }
  .m-lg-n75 {
    margin: -75px !important; }
  .mt-lg-n75,
  .my-lg-n75 {
    margin-top: -75px !important; }
  .mr-lg-n75,
  .mx-lg-n75 {
    margin-right: -75px !important; }
  .mb-lg-n75,
  .my-lg-n75 {
    margin-bottom: -75px !important; }
  .ml-lg-n75,
  .mx-lg-n75 {
    margin-left: -75px !important; }
  .m-lg-n80 {
    margin: -80px !important; }
  .mt-lg-n80,
  .my-lg-n80 {
    margin-top: -80px !important; }
  .mr-lg-n80,
  .mx-lg-n80 {
    margin-right: -80px !important; }
  .mb-lg-n80,
  .my-lg-n80 {
    margin-bottom: -80px !important; }
  .ml-lg-n80,
  .mx-lg-n80 {
    margin-left: -80px !important; }
  .m-lg-n85 {
    margin: -85px !important; }
  .mt-lg-n85,
  .my-lg-n85 {
    margin-top: -85px !important; }
  .mr-lg-n85,
  .mx-lg-n85 {
    margin-right: -85px !important; }
  .mb-lg-n85,
  .my-lg-n85 {
    margin-bottom: -85px !important; }
  .ml-lg-n85,
  .mx-lg-n85 {
    margin-left: -85px !important; }
  .m-lg-n90 {
    margin: -90px !important; }
  .mt-lg-n90,
  .my-lg-n90 {
    margin-top: -90px !important; }
  .mr-lg-n90,
  .mx-lg-n90 {
    margin-right: -90px !important; }
  .mb-lg-n90,
  .my-lg-n90 {
    margin-bottom: -90px !important; }
  .ml-lg-n90,
  .mx-lg-n90 {
    margin-left: -90px !important; }
  .m-lg-n95 {
    margin: -95px !important; }
  .mt-lg-n95,
  .my-lg-n95 {
    margin-top: -95px !important; }
  .mr-lg-n95,
  .mx-lg-n95 {
    margin-right: -95px !important; }
  .mb-lg-n95,
  .my-lg-n95 {
    margin-bottom: -95px !important; }
  .ml-lg-n95,
  .mx-lg-n95 {
    margin-left: -95px !important; }
  .m-lg-n100 {
    margin: -100px !important; }
  .mt-lg-n100,
  .my-lg-n100 {
    margin-top: -100px !important; }
  .mr-lg-n100,
  .mx-lg-n100 {
    margin-right: -100px !important; }
  .mb-lg-n100,
  .my-lg-n100 {
    margin-bottom: -100px !important; }
  .ml-lg-n100,
  .mx-lg-n100 {
    margin-left: -100px !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1400px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl-10 {
    margin: 10px !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10px !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10px !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10px !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10px !important; }
  .m-xl-15 {
    margin: 15px !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 15px !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 15px !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 15px !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 15px !important; }
  .m-xl-20 {
    margin: 20px !important; }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 20px !important; }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 20px !important; }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 20px !important; }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 20px !important; }
  .m-xl-25 {
    margin: 25px !important; }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 25px !important; }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 25px !important; }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 25px !important; }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 25px !important; }
  .m-xl-30 {
    margin: 30px !important; }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 30px !important; }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 30px !important; }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 30px !important; }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 30px !important; }
  .m-xl-35 {
    margin: 35px !important; }
  .mt-xl-35,
  .my-xl-35 {
    margin-top: 35px !important; }
  .mr-xl-35,
  .mx-xl-35 {
    margin-right: 35px !important; }
  .mb-xl-35,
  .my-xl-35 {
    margin-bottom: 35px !important; }
  .ml-xl-35,
  .mx-xl-35 {
    margin-left: 35px !important; }
  .m-xl-40 {
    margin: 40px !important; }
  .mt-xl-40,
  .my-xl-40 {
    margin-top: 40px !important; }
  .mr-xl-40,
  .mx-xl-40 {
    margin-right: 40px !important; }
  .mb-xl-40,
  .my-xl-40 {
    margin-bottom: 40px !important; }
  .ml-xl-40,
  .mx-xl-40 {
    margin-left: 40px !important; }
  .m-xl-45 {
    margin: 45px !important; }
  .mt-xl-45,
  .my-xl-45 {
    margin-top: 45px !important; }
  .mr-xl-45,
  .mx-xl-45 {
    margin-right: 45px !important; }
  .mb-xl-45,
  .my-xl-45 {
    margin-bottom: 45px !important; }
  .ml-xl-45,
  .mx-xl-45 {
    margin-left: 45px !important; }
  .m-xl-50 {
    margin: 50px !important; }
  .mt-xl-50,
  .my-xl-50 {
    margin-top: 50px !important; }
  .mr-xl-50,
  .mx-xl-50 {
    margin-right: 50px !important; }
  .mb-xl-50,
  .my-xl-50 {
    margin-bottom: 50px !important; }
  .ml-xl-50,
  .mx-xl-50 {
    margin-left: 50px !important; }
  .m-xl-55 {
    margin: 55px !important; }
  .mt-xl-55,
  .my-xl-55 {
    margin-top: 55px !important; }
  .mr-xl-55,
  .mx-xl-55 {
    margin-right: 55px !important; }
  .mb-xl-55,
  .my-xl-55 {
    margin-bottom: 55px !important; }
  .ml-xl-55,
  .mx-xl-55 {
    margin-left: 55px !important; }
  .m-xl-60 {
    margin: 60px !important; }
  .mt-xl-60,
  .my-xl-60 {
    margin-top: 60px !important; }
  .mr-xl-60,
  .mx-xl-60 {
    margin-right: 60px !important; }
  .mb-xl-60,
  .my-xl-60 {
    margin-bottom: 60px !important; }
  .ml-xl-60,
  .mx-xl-60 {
    margin-left: 60px !important; }
  .m-xl-65 {
    margin: 65px !important; }
  .mt-xl-65,
  .my-xl-65 {
    margin-top: 65px !important; }
  .mr-xl-65,
  .mx-xl-65 {
    margin-right: 65px !important; }
  .mb-xl-65,
  .my-xl-65 {
    margin-bottom: 65px !important; }
  .ml-xl-65,
  .mx-xl-65 {
    margin-left: 65px !important; }
  .m-xl-70 {
    margin: 70px !important; }
  .mt-xl-70,
  .my-xl-70 {
    margin-top: 70px !important; }
  .mr-xl-70,
  .mx-xl-70 {
    margin-right: 70px !important; }
  .mb-xl-70,
  .my-xl-70 {
    margin-bottom: 70px !important; }
  .ml-xl-70,
  .mx-xl-70 {
    margin-left: 70px !important; }
  .m-xl-75 {
    margin: 75px !important; }
  .mt-xl-75,
  .my-xl-75 {
    margin-top: 75px !important; }
  .mr-xl-75,
  .mx-xl-75 {
    margin-right: 75px !important; }
  .mb-xl-75,
  .my-xl-75 {
    margin-bottom: 75px !important; }
  .ml-xl-75,
  .mx-xl-75 {
    margin-left: 75px !important; }
  .m-xl-80 {
    margin: 80px !important; }
  .mt-xl-80,
  .my-xl-80 {
    margin-top: 80px !important; }
  .mr-xl-80,
  .mx-xl-80 {
    margin-right: 80px !important; }
  .mb-xl-80,
  .my-xl-80 {
    margin-bottom: 80px !important; }
  .ml-xl-80,
  .mx-xl-80 {
    margin-left: 80px !important; }
  .m-xl-85 {
    margin: 85px !important; }
  .mt-xl-85,
  .my-xl-85 {
    margin-top: 85px !important; }
  .mr-xl-85,
  .mx-xl-85 {
    margin-right: 85px !important; }
  .mb-xl-85,
  .my-xl-85 {
    margin-bottom: 85px !important; }
  .ml-xl-85,
  .mx-xl-85 {
    margin-left: 85px !important; }
  .m-xl-90 {
    margin: 90px !important; }
  .mt-xl-90,
  .my-xl-90 {
    margin-top: 90px !important; }
  .mr-xl-90,
  .mx-xl-90 {
    margin-right: 90px !important; }
  .mb-xl-90,
  .my-xl-90 {
    margin-bottom: 90px !important; }
  .ml-xl-90,
  .mx-xl-90 {
    margin-left: 90px !important; }
  .m-xl-95 {
    margin: 95px !important; }
  .mt-xl-95,
  .my-xl-95 {
    margin-top: 95px !important; }
  .mr-xl-95,
  .mx-xl-95 {
    margin-right: 95px !important; }
  .mb-xl-95,
  .my-xl-95 {
    margin-bottom: 95px !important; }
  .ml-xl-95,
  .mx-xl-95 {
    margin-left: 95px !important; }
  .m-xl-100 {
    margin: 100px !important; }
  .mt-xl-100,
  .my-xl-100 {
    margin-top: 100px !important; }
  .mr-xl-100,
  .mx-xl-100 {
    margin-right: 100px !important; }
  .mb-xl-100,
  .my-xl-100 {
    margin-bottom: 100px !important; }
  .ml-xl-100,
  .mx-xl-100 {
    margin-left: 100px !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl-10 {
    padding: 10px !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10px !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10px !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10px !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10px !important; }
  .p-xl-15 {
    padding: 15px !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 15px !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 15px !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 15px !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 15px !important; }
  .p-xl-20 {
    padding: 20px !important; }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 20px !important; }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 20px !important; }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 20px !important; }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 20px !important; }
  .p-xl-25 {
    padding: 25px !important; }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 25px !important; }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 25px !important; }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 25px !important; }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 25px !important; }
  .p-xl-30 {
    padding: 30px !important; }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 30px !important; }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 30px !important; }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 30px !important; }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 30px !important; }
  .p-xl-35 {
    padding: 35px !important; }
  .pt-xl-35,
  .py-xl-35 {
    padding-top: 35px !important; }
  .pr-xl-35,
  .px-xl-35 {
    padding-right: 35px !important; }
  .pb-xl-35,
  .py-xl-35 {
    padding-bottom: 35px !important; }
  .pl-xl-35,
  .px-xl-35 {
    padding-left: 35px !important; }
  .p-xl-40 {
    padding: 40px !important; }
  .pt-xl-40,
  .py-xl-40 {
    padding-top: 40px !important; }
  .pr-xl-40,
  .px-xl-40 {
    padding-right: 40px !important; }
  .pb-xl-40,
  .py-xl-40 {
    padding-bottom: 40px !important; }
  .pl-xl-40,
  .px-xl-40 {
    padding-left: 40px !important; }
  .p-xl-45 {
    padding: 45px !important; }
  .pt-xl-45,
  .py-xl-45 {
    padding-top: 45px !important; }
  .pr-xl-45,
  .px-xl-45 {
    padding-right: 45px !important; }
  .pb-xl-45,
  .py-xl-45 {
    padding-bottom: 45px !important; }
  .pl-xl-45,
  .px-xl-45 {
    padding-left: 45px !important; }
  .p-xl-50 {
    padding: 50px !important; }
  .pt-xl-50,
  .py-xl-50 {
    padding-top: 50px !important; }
  .pr-xl-50,
  .px-xl-50 {
    padding-right: 50px !important; }
  .pb-xl-50,
  .py-xl-50 {
    padding-bottom: 50px !important; }
  .pl-xl-50,
  .px-xl-50 {
    padding-left: 50px !important; }
  .p-xl-55 {
    padding: 55px !important; }
  .pt-xl-55,
  .py-xl-55 {
    padding-top: 55px !important; }
  .pr-xl-55,
  .px-xl-55 {
    padding-right: 55px !important; }
  .pb-xl-55,
  .py-xl-55 {
    padding-bottom: 55px !important; }
  .pl-xl-55,
  .px-xl-55 {
    padding-left: 55px !important; }
  .p-xl-60 {
    padding: 60px !important; }
  .pt-xl-60,
  .py-xl-60 {
    padding-top: 60px !important; }
  .pr-xl-60,
  .px-xl-60 {
    padding-right: 60px !important; }
  .pb-xl-60,
  .py-xl-60 {
    padding-bottom: 60px !important; }
  .pl-xl-60,
  .px-xl-60 {
    padding-left: 60px !important; }
  .p-xl-65 {
    padding: 65px !important; }
  .pt-xl-65,
  .py-xl-65 {
    padding-top: 65px !important; }
  .pr-xl-65,
  .px-xl-65 {
    padding-right: 65px !important; }
  .pb-xl-65,
  .py-xl-65 {
    padding-bottom: 65px !important; }
  .pl-xl-65,
  .px-xl-65 {
    padding-left: 65px !important; }
  .p-xl-70 {
    padding: 70px !important; }
  .pt-xl-70,
  .py-xl-70 {
    padding-top: 70px !important; }
  .pr-xl-70,
  .px-xl-70 {
    padding-right: 70px !important; }
  .pb-xl-70,
  .py-xl-70 {
    padding-bottom: 70px !important; }
  .pl-xl-70,
  .px-xl-70 {
    padding-left: 70px !important; }
  .p-xl-75 {
    padding: 75px !important; }
  .pt-xl-75,
  .py-xl-75 {
    padding-top: 75px !important; }
  .pr-xl-75,
  .px-xl-75 {
    padding-right: 75px !important; }
  .pb-xl-75,
  .py-xl-75 {
    padding-bottom: 75px !important; }
  .pl-xl-75,
  .px-xl-75 {
    padding-left: 75px !important; }
  .p-xl-80 {
    padding: 80px !important; }
  .pt-xl-80,
  .py-xl-80 {
    padding-top: 80px !important; }
  .pr-xl-80,
  .px-xl-80 {
    padding-right: 80px !important; }
  .pb-xl-80,
  .py-xl-80 {
    padding-bottom: 80px !important; }
  .pl-xl-80,
  .px-xl-80 {
    padding-left: 80px !important; }
  .p-xl-85 {
    padding: 85px !important; }
  .pt-xl-85,
  .py-xl-85 {
    padding-top: 85px !important; }
  .pr-xl-85,
  .px-xl-85 {
    padding-right: 85px !important; }
  .pb-xl-85,
  .py-xl-85 {
    padding-bottom: 85px !important; }
  .pl-xl-85,
  .px-xl-85 {
    padding-left: 85px !important; }
  .p-xl-90 {
    padding: 90px !important; }
  .pt-xl-90,
  .py-xl-90 {
    padding-top: 90px !important; }
  .pr-xl-90,
  .px-xl-90 {
    padding-right: 90px !important; }
  .pb-xl-90,
  .py-xl-90 {
    padding-bottom: 90px !important; }
  .pl-xl-90,
  .px-xl-90 {
    padding-left: 90px !important; }
  .p-xl-95 {
    padding: 95px !important; }
  .pt-xl-95,
  .py-xl-95 {
    padding-top: 95px !important; }
  .pr-xl-95,
  .px-xl-95 {
    padding-right: 95px !important; }
  .pb-xl-95,
  .py-xl-95 {
    padding-bottom: 95px !important; }
  .pl-xl-95,
  .px-xl-95 {
    padding-left: 95px !important; }
  .p-xl-100 {
    padding: 100px !important; }
  .pt-xl-100,
  .py-xl-100 {
    padding-top: 100px !important; }
  .pr-xl-100,
  .px-xl-100 {
    padding-right: 100px !important; }
  .pb-xl-100,
  .py-xl-100 {
    padding-bottom: 100px !important; }
  .pl-xl-100,
  .px-xl-100 {
    padding-left: 100px !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-n10 {
    margin: -10px !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -10px !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -10px !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -10px !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -10px !important; }
  .m-xl-n15 {
    margin: -15px !important; }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -15px !important; }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -15px !important; }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -15px !important; }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -15px !important; }
  .m-xl-n20 {
    margin: -20px !important; }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -20px !important; }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -20px !important; }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -20px !important; }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -20px !important; }
  .m-xl-n25 {
    margin: -25px !important; }
  .mt-xl-n25,
  .my-xl-n25 {
    margin-top: -25px !important; }
  .mr-xl-n25,
  .mx-xl-n25 {
    margin-right: -25px !important; }
  .mb-xl-n25,
  .my-xl-n25 {
    margin-bottom: -25px !important; }
  .ml-xl-n25,
  .mx-xl-n25 {
    margin-left: -25px !important; }
  .m-xl-n30 {
    margin: -30px !important; }
  .mt-xl-n30,
  .my-xl-n30 {
    margin-top: -30px !important; }
  .mr-xl-n30,
  .mx-xl-n30 {
    margin-right: -30px !important; }
  .mb-xl-n30,
  .my-xl-n30 {
    margin-bottom: -30px !important; }
  .ml-xl-n30,
  .mx-xl-n30 {
    margin-left: -30px !important; }
  .m-xl-n35 {
    margin: -35px !important; }
  .mt-xl-n35,
  .my-xl-n35 {
    margin-top: -35px !important; }
  .mr-xl-n35,
  .mx-xl-n35 {
    margin-right: -35px !important; }
  .mb-xl-n35,
  .my-xl-n35 {
    margin-bottom: -35px !important; }
  .ml-xl-n35,
  .mx-xl-n35 {
    margin-left: -35px !important; }
  .m-xl-n40 {
    margin: -40px !important; }
  .mt-xl-n40,
  .my-xl-n40 {
    margin-top: -40px !important; }
  .mr-xl-n40,
  .mx-xl-n40 {
    margin-right: -40px !important; }
  .mb-xl-n40,
  .my-xl-n40 {
    margin-bottom: -40px !important; }
  .ml-xl-n40,
  .mx-xl-n40 {
    margin-left: -40px !important; }
  .m-xl-n45 {
    margin: -45px !important; }
  .mt-xl-n45,
  .my-xl-n45 {
    margin-top: -45px !important; }
  .mr-xl-n45,
  .mx-xl-n45 {
    margin-right: -45px !important; }
  .mb-xl-n45,
  .my-xl-n45 {
    margin-bottom: -45px !important; }
  .ml-xl-n45,
  .mx-xl-n45 {
    margin-left: -45px !important; }
  .m-xl-n50 {
    margin: -50px !important; }
  .mt-xl-n50,
  .my-xl-n50 {
    margin-top: -50px !important; }
  .mr-xl-n50,
  .mx-xl-n50 {
    margin-right: -50px !important; }
  .mb-xl-n50,
  .my-xl-n50 {
    margin-bottom: -50px !important; }
  .ml-xl-n50,
  .mx-xl-n50 {
    margin-left: -50px !important; }
  .m-xl-n55 {
    margin: -55px !important; }
  .mt-xl-n55,
  .my-xl-n55 {
    margin-top: -55px !important; }
  .mr-xl-n55,
  .mx-xl-n55 {
    margin-right: -55px !important; }
  .mb-xl-n55,
  .my-xl-n55 {
    margin-bottom: -55px !important; }
  .ml-xl-n55,
  .mx-xl-n55 {
    margin-left: -55px !important; }
  .m-xl-n60 {
    margin: -60px !important; }
  .mt-xl-n60,
  .my-xl-n60 {
    margin-top: -60px !important; }
  .mr-xl-n60,
  .mx-xl-n60 {
    margin-right: -60px !important; }
  .mb-xl-n60,
  .my-xl-n60 {
    margin-bottom: -60px !important; }
  .ml-xl-n60,
  .mx-xl-n60 {
    margin-left: -60px !important; }
  .m-xl-n65 {
    margin: -65px !important; }
  .mt-xl-n65,
  .my-xl-n65 {
    margin-top: -65px !important; }
  .mr-xl-n65,
  .mx-xl-n65 {
    margin-right: -65px !important; }
  .mb-xl-n65,
  .my-xl-n65 {
    margin-bottom: -65px !important; }
  .ml-xl-n65,
  .mx-xl-n65 {
    margin-left: -65px !important; }
  .m-xl-n70 {
    margin: -70px !important; }
  .mt-xl-n70,
  .my-xl-n70 {
    margin-top: -70px !important; }
  .mr-xl-n70,
  .mx-xl-n70 {
    margin-right: -70px !important; }
  .mb-xl-n70,
  .my-xl-n70 {
    margin-bottom: -70px !important; }
  .ml-xl-n70,
  .mx-xl-n70 {
    margin-left: -70px !important; }
  .m-xl-n75 {
    margin: -75px !important; }
  .mt-xl-n75,
  .my-xl-n75 {
    margin-top: -75px !important; }
  .mr-xl-n75,
  .mx-xl-n75 {
    margin-right: -75px !important; }
  .mb-xl-n75,
  .my-xl-n75 {
    margin-bottom: -75px !important; }
  .ml-xl-n75,
  .mx-xl-n75 {
    margin-left: -75px !important; }
  .m-xl-n80 {
    margin: -80px !important; }
  .mt-xl-n80,
  .my-xl-n80 {
    margin-top: -80px !important; }
  .mr-xl-n80,
  .mx-xl-n80 {
    margin-right: -80px !important; }
  .mb-xl-n80,
  .my-xl-n80 {
    margin-bottom: -80px !important; }
  .ml-xl-n80,
  .mx-xl-n80 {
    margin-left: -80px !important; }
  .m-xl-n85 {
    margin: -85px !important; }
  .mt-xl-n85,
  .my-xl-n85 {
    margin-top: -85px !important; }
  .mr-xl-n85,
  .mx-xl-n85 {
    margin-right: -85px !important; }
  .mb-xl-n85,
  .my-xl-n85 {
    margin-bottom: -85px !important; }
  .ml-xl-n85,
  .mx-xl-n85 {
    margin-left: -85px !important; }
  .m-xl-n90 {
    margin: -90px !important; }
  .mt-xl-n90,
  .my-xl-n90 {
    margin-top: -90px !important; }
  .mr-xl-n90,
  .mx-xl-n90 {
    margin-right: -90px !important; }
  .mb-xl-n90,
  .my-xl-n90 {
    margin-bottom: -90px !important; }
  .ml-xl-n90,
  .mx-xl-n90 {
    margin-left: -90px !important; }
  .m-xl-n95 {
    margin: -95px !important; }
  .mt-xl-n95,
  .my-xl-n95 {
    margin-top: -95px !important; }
  .mr-xl-n95,
  .mx-xl-n95 {
    margin-right: -95px !important; }
  .mb-xl-n95,
  .my-xl-n95 {
    margin-bottom: -95px !important; }
  .ml-xl-n95,
  .mx-xl-n95 {
    margin-left: -95px !important; }
  .m-xl-n100 {
    margin: -100px !important; }
  .mt-xl-n100,
  .my-xl-n100 {
    margin-top: -100px !important; }
  .mr-xl-n100,
  .mx-xl-n100 {
    margin-right: -100px !important; }
  .mb-xl-n100,
  .my-xl-n100 {
    margin-bottom: -100px !important; }
  .ml-xl-n100,
  .mx-xl-n100 {
    margin-left: -100px !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #19a64a !important; }

a.text-success:hover, a.text-success:focus {
  color: #0f642c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #333 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.p-rel {
  position: relative; }

.p-fix {
  position: fixed; }

.p-abs {
  position: absolute; }

.p-just {
  top: 0;
  left: 0;
  line-height: 1;
  display: block;
  width: 100%;
  height: auto; }

.lh-10 {
  line-height: 1; }

.lh-12 {
  line-height: 1.2; }

.lh-14 {
  line-height: 1.4; }

.lh-16 {
  line-height: 1.6; }

.lh-18 {
  line-height: 1.8; }

.lh-20 {
  line-height: 2.0; }

.lh-22 {
  line-height: 2.2; }

.lh-24 {
  line-height: 2.4; }

.lh-26 {
  line-height: 2.6; }

.lh-28 {
  line-height: 2.8; }

.lh-30 {
  line-height: 3.0; }

.lh-32 {
  line-height: 3.2; }

.lh-34 {
  line-height: 3.4; }

.lh-36 {
  line-height: 3.6; }

.lh-38 {
  line-height: 3.8; }

html,
body {
  min-width: 320px;
  font-family: 'Noto Sans JP', sans-serif;
  font-feature-settings: "palt" 1;
  text-justify: inter-character;
  letter-spacing: 2px; }
  html .fwr,
  body .fwr {
    font-weight: 400; }
  html .fwb,
  body .fwb {
    font-weight: 700; }
  html .fwbb,
  body .fwbb {
    font-weight: 900; }
  html > #page > header#header,
  body > #page > header#header {
    position: relative;
    z-index: 50; }
  html > #page > main#mainContent,
  body > #page > main#mainContent {
    position: relative;
    z-index: 30; }
  html > #page > footer#footer,
  body > #page > footer#footer {
    position: relative;
    z-index: 10; }
  html > #page > nav#modileGnav,
  body > #page > nav#modileGnav {
    position: absolute;
    z-index: 40; }
  html img,
  body img {
    width: 100%;
    height: auto;
    max-width: 100%; }

#page-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 77%;
  display: none;
  z-index: 50; }
  #page-top a {
    background: #000;
    text-decoration: none;
    color: #fff;
    padding: 10px 20px;
    text-align: center;
    display: block;
    border-radius: 5px;
    opacity: 0.2; }
    #page-top a:hover {
      text-decoration: none;
      background: #999; }

header#header .global {
  background: #FFF; }

header#header .global .id {
  width: 140px;
  top: 0;
  left: 0;
  line-height: 1; }
  @media (max-width: 1399.98px) {
    header#header .global .id {
      width: 130px; } }
  @media (max-width: 991.98px) {
    header#header .global .id {
      width: 115px; } }
  @media (max-width: 767.98px) {
    header#header .global .id {
      width: 95px; } }
  @media (max-width: 575.98px) {
    header#header .global .id {
      width: 75px; } }
  header#header .global .id a {
    opacity: 1;
    transition: 0.7s; }
    header#header .global .id a:hover {
      opacity: 0.5; }

header#header .global .gnav > ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  header#header .global .gnav > ul > li {
    display: block;
    margin: 0;
    padding: 0.25em 0.5em;
    border-left: #e7e7e7 solid 1px; }
    header#header .global .gnav > ul > li:first-child, header#header .global .gnav > ul > li:last-child {
      border-left: none; }
    header#header .global .gnav > ul > li a {
      display: block;
      padding: 0 2.5em;
      line-height: 1.6;
      color: #333;
      font-size: 13px;
      font-weight: 900;
      position: relative;
      transition: color 0.2s 0.06s; }
      header#header .global .gnav > ul > li a:after {
        content: "";
        display: block;
        width: 0;
        height: 100%;
        background: #43a5d7;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        transition: width 0.2s; }
      header#header .global .gnav > ul > li a > span {
        position: relative;
        z-index: 1; }
    header#header .global .gnav > ul > li.contact {
      padding: 0; }
      header#header .global .gnav > ul > li.contact a {
        height: 94px;
        line-height: 94px;
        padding: 0 6em;
        background: #ffcb3e; }
        header#header .global .gnav > ul > li.contact a:before {
          content: none; }
    @media (min-width: 768px) {
      header#header .global .gnav > ul > li a:hover {
        color: #FFF; }
        header#header .global .gnav > ul > li a:hover:after {
          width: 100%; } }
    @media (max-width: 1399.98px) {
      header#header .global .gnav > ul > li {
        padding: 0.25em 0.25em; }
        header#header .global .gnav > ul > li a {
          padding: 0 2em; }
        header#header .global .gnav > ul > li.contact a {
          height: 85px;
          line-height: 85px;
          padding: 0 5em; } }
    @media (max-width: 991.98px) {
      header#header .global .gnav > ul > li {
        padding: 0.25em 0.15em; }
        header#header .global .gnav > ul > li a {
          padding: 0 1.5em;
          font-size: 12px; }
        header#header .global .gnav > ul > li.contact a {
          height: 75px;
          line-height: 75px;
          padding: 0 3em; } }

header#header .global .burger {
  justify-content: flex-end;
  width: 100%;
  height: 70px; }
  @media (max-width: 575.98px) {
    header#header .global .burger {
      height: 55px; } }
  header#header .global .burger .triggerwrap {
    width: 70px;
    height: 100%;
    position: relative; }
    @media (max-width: 575.98px) {
      header#header .global .burger .triggerwrap {
        width: 55px; } }

body header#header .global .burger .trigger {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: all .3s; }
  body header#header .global .burger .trigger span {
    display: block;
    transition: all .3s;
    box-sizing: border-box;
    position: absolute;
    left: calc((100% - 55%) * 0.5);
    width: 55%;
    height: 1px;
    background-color: #333;
    border-radius: 10px; }
    body header#header .global .burger .trigger span:nth-of-type(1) {
      top: 27%; }
    body header#header .global .burger .trigger span:nth-of-type(2) {
      top: calc(50% - 1px); }
    body header#header .global .burger .trigger span:nth-of-type(3) {
      bottom: 27%; }

body.navOn header#header .global .burger .trigger {
  transform: rotate(270deg); }
  body.navOn header#header .global .burger .trigger span:nth-of-type(1) {
    height: 1px;
    top: calc(50% - 1px);
    transform: rotate(-45deg); }
  body.navOn header#header .global .burger .trigger span:nth-of-type(2) {
    height: 0;
    opacity: 0; }
  body.navOn header#header .global .burger .trigger span:nth-of-type(3) {
    height: 1px;
    bottom: calc(50% - 1px);
    transform: rotate(45deg); }

#modileGnav {
  display: none; }

@media (max-width: 767.98px) {
  body #modileGnav {
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.97); }
    body #modileGnav .navflex {
      width: 100%;
      height: 100%; }
    body #modileGnav ul.navwrap {
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0; }
      body #modileGnav ul.navwrap li {
        list-style: none;
        margin: 0;
        padding: 0; }
        body #modileGnav ul.navwrap li a {
          padding: 10px 0;
          text-align: center;
          color: #43a5d7;
          font-size: 5vw;
          font-weight: 700;
          opacity: 0;
          transform: translateY(15px) scale(0.95); }
        body #modileGnav ul.navwrap li:last-child a {
          border-bottom: none; }
  body.navOn {
    overflow: hidden; }
    body.navOn #modileGnav {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.24s;
      position: relative; }
      body.navOn #modileGnav ul.navwrap {
        position: relative;
        z-index: 1; }
        body.navOn #modileGnav ul.navwrap li a {
          opacity: 1;
          transform: translateY(0) scale(1);
          transition-property: all;
          transition-duration: 0.2s; }
        body.navOn #modileGnav ul.navwrap li:nth-child(1) a {
          transition-delay: calc(1 * 0.05s); }
        body.navOn #modileGnav ul.navwrap li:nth-child(2) a {
          transition-delay: calc(2 * 0.05s); }
        body.navOn #modileGnav ul.navwrap li:nth-child(3) a {
          transition-delay: calc(3 * 0.05s); }
        body.navOn #modileGnav ul.navwrap li:nth-child(4) a {
          transition-delay: calc(4 * 0.05s); }
        body.navOn #modileGnav ul.navwrap li:nth-child(5) a {
          transition-delay: calc(5 * 0.05s); }
        body.navOn #modileGnav ul.navwrap li:nth-child(6) a {
          transition-delay: calc(6 * 0.05s); }
      body.navOn #modileGnav p.closer {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%; } }

footer#footer {
  display: block;
  margin: 0;
  padding: 0; }

footer#footer .inquirybg {
  background-image: url(./img/footer/bg.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover; }
  footer#footer .inquirybg .inquirywrap {
    display: block;
    margin: 0;
    padding: 90px 0 120px;
    color: #FFF;
    text-align: center; }
    @media (max-width: 1399.98px) {
      footer#footer .inquirybg .inquirywrap {
        padding: 80px 0 100px; } }
    @media (max-width: 991.98px) {
      footer#footer .inquirybg .inquirywrap {
        padding: 70px 0 90px; } }
    @media (max-width: 767.98px) {
      footer#footer .inquirybg .inquirywrap {
        padding: 48px 0 60px; } }
    @media (max-width: 575.98px) {
      footer#footer .inquirybg .inquirywrap {
        padding: 36px 0 48px; } }
  footer#footer .inquirybg dl.head {
    margin-bottom: 55px; }
    footer#footer .inquirybg dl.head > dt {
      font-size: 36px; }
    footer#footer .inquirybg dl.head > dd {
      font-size: 18px; }
    @media (max-width: 1399.98px) {
      footer#footer .inquirybg dl.head {
        margin-bottom: 50px; }
        footer#footer .inquirybg dl.head > dt {
          font-size: 34px; }
        footer#footer .inquirybg dl.head > dd {
          font-size: 16px; } }
    @media (max-width: 991.98px) {
      footer#footer .inquirybg dl.head {
        margin-bottom: 40px; }
        footer#footer .inquirybg dl.head > dt {
          font-size: 32px; }
        footer#footer .inquirybg dl.head > dd {
          font-size: 16px; } }
    @media (max-width: 767.98px) {
      footer#footer .inquirybg dl.head {
        margin-bottom: 36px; }
        footer#footer .inquirybg dl.head > dt {
          font-size: 30px; }
        footer#footer .inquirybg dl.head > dd {
          font-size: 14px; } }
    @media (max-width: 575.98px) {
      footer#footer .inquirybg dl.head {
        margin-bottom: 24px; }
        footer#footer .inquirybg dl.head > dt {
          font-size: 28px; }
        footer#footer .inquirybg dl.head > dd {
          font-size: 14px; } }
  footer#footer .inquirybg .typehead > span {
    width: 33px; }
    @media (max-width: 991.98px) {
      footer#footer .inquirybg .typehead > span {
        width: 30px; } }
    @media (max-width: 767.98px) {
      footer#footer .inquirybg .typehead > span {
        width: 27px; } }
    @media (max-width: 575.98px) {
      footer#footer .inquirybg .typehead > span {
        width: 24px; } }
  footer#footer .inquirybg .typehead > p {
    margin: 0 0 0 1em;
    padding: 0 2px 0.4em;
    position: relative;
    font-size: 16px; }
    @media (max-width: 991.98px) {
      footer#footer .inquirybg .typehead > p {
        font-size: 15px;
        margin: 0 0 0 0.8em;
        padding: 0 2px 0.35em; } }
    @media (max-width: 767.98px) {
      footer#footer .inquirybg .typehead > p {
        font-size: 14px;
        margin: 0 0 0 0.6em;
        padding: 0 1px 0.3em; } }
    @media (max-width: 575.98px) {
      footer#footer .inquirybg .typehead > p {
        font-size: 13px;
        margin: 0 0 0 0.4em;
        padding: 0 1px 0.25em; } }
    footer#footer .inquirybg .typehead > p:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #FFF; }

footer#footer .call_content {
  display: block;
  margin: 0;
  padding: 20px 40px 30px; }
  @media (max-width: 1399.98px) {
    footer#footer .call_content {
      padding: 20px 30px 30px; } }
  @media (max-width: 991.98px) {
    footer#footer .call_content {
      padding: 20px 20px 30px; } }
  @media (max-width: 767.98px) {
    footer#footer .call_content {
      padding: 15px 15px 25px; } }
  footer#footer .call_content .callnum {
    justify-content: center;
    align-items: center;
    padding: 18px 24px; }
    @media (max-width: 1399.98px) {
      footer#footer .call_content .callnum {
        padding: 16px 18px;
        flex-wrap: wrap; } }
    footer#footer .call_content .callnum .area {
      width: 80%;
      margin: 0 auto; }
      footer#footer .call_content .callnum .area > span {
        display: block;
        padding: 1em 2em;
        line-height: 1.2;
        color: #115c85;
        font-size: 14px;
        background: #FFF;
        border-radius: 30px; }
        @media (max-width: 1399.98px) {
          footer#footer .call_content .callnum .area > span {
            padding: 1em 0.8em;
            font-size: 14px; } }
        @media (max-width: 991.98px) {
          footer#footer .call_content .callnum .area > span {
            padding: 0.75em 0; } }
        @media (max-width: 767.98px) {
          footer#footer .call_content .callnum .area > span {
            padding: 0.5em 0;
            font-size: 13px; } }
    footer#footer .call_content .callnum .num {
      margin: 0 0 0 1em;
      font-size: 36px;
      width: 100%;
      margin: 0;
      text-align: center;
      font-size: 30px; }
      @media (max-width: 1399.98px) {
        footer#footer .call_content .callnum .num {
          margin: 0.5em 0 0; } }
      @media (max-width: 575.98px) {
        footer#footer .call_content .callnum .num {
          font-size: 28px; } }
      footer#footer .call_content .callnum .num a {
        color: #FFF; }

footer#footer .form_content {
  display: block;
  margin: 0;
  padding: 30px 40px; }
  footer#footer .form_content .typehead {
    justify-content: flex-end; }
  footer#footer .form_content .to_form {
    text-align: left; }
    footer#footer .form_content .to_form > a {
      display: inline-block;
      line-height: 1;
      margin: 0;
      padding: 1.25em 6em;
      color: #000;
      font-size: 15px;
      background: #ffcb3e;
      border-radius: 55px;
      position: relative;
      overflow: hidden;
      transition: color 0.16s 0.06s; }
      footer#footer .form_content .to_form > a:after {
        content: "";
        display: block;
        width: 100%;
        height: 0;
        background: #43a5d7;
        position: absolute;
        top: 50%;
        left: 0;
        transform: rotate(-12deg);
        transition: all 0.16s; }
      footer#footer .form_content .to_form > a span {
        position: relative;
        z-index: 1; }
      @media (min-width: 768px) {
        footer#footer .form_content .to_form > a:hover {
          color: #FFF; }
          footer#footer .form_content .to_form > a:hover:after {
            height: 100%;
            top: 0;
            transform: rotate(0); } }
  @media (max-width: 991.98px) {
    footer#footer .form_content {
      padding: 20px; }
      footer#footer .form_content .typehead {
        justify-content: center; }
      footer#footer .form_content .to_form {
        margin: 20px 0 0;
        text-align: center; } }
  @media (max-width: 767.98px) {
    footer#footer .form_content {
      padding: 20px 15px; }
      footer#footer .form_content .to_form a {
        display: block;
        margin: 0 1em;
        padding: 1.25em 0; } }

footer#footer .footerwrap {
  padding: 100px 0 0;
  background: #f5f7f8; }
  @media (max-width: 1399.98px) {
    footer#footer .footerwrap {
      padding: 90px 0 0; } }
  @media (max-width: 991.98px) {
    footer#footer .footerwrap {
      padding: 80px 0 0; } }
  @media (max-width: 767.98px) {
    footer#footer .footerwrap {
      padding: 50px 0 0; } }
  @media (max-width: 575.98px) {
    footer#footer .footerwrap {
      padding: 36px 0 0; } }
  footer#footer .footerwrap .content {
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 991.98px) {
      footer#footer .footerwrap .content {
        flex-wrap: wrap; }
        footer#footer .footerwrap .content > .id {
          order: 1; }
        footer#footer .footerwrap .content > .gnav {
          order: 3; }
        footer#footer .footerwrap .content > .social {
          order: 2; } }
    @media (max-width: 575.98px) {
      footer#footer .footerwrap .content > .id {
        order: 1; }
      footer#footer .footerwrap .content > .gnav {
        order: 2; }
      footer#footer .footerwrap .content > .social {
        order: 3; } }

footer#footer .footerwrap .content .id {
  width: 190px;
  margin-right: auto; }
  @media (max-width: 1399.98px) {
    footer#footer .footerwrap .content .id {
      width: 175px; } }
  @media (max-width: 991.98px) {
    footer#footer .footerwrap .content .id {
      width: 175px; } }
  @media (max-width: 767.98px) {
    footer#footer .footerwrap .content .id {
      width: 150px; } }
  @media (max-width: 575.98px) {
    footer#footer .footerwrap .content .id {
      width: 36%;
      margin: 0 auto; } }

footer#footer .footerwrap .content .gnav {
  margin: 0 auto; }
  @media (max-width: 991.98px) {
    footer#footer .footerwrap .content .gnav {
      width: 100%;
      margin: 50px auto 0; } }
  @media (max-width: 767.98px) {
    footer#footer .footerwrap .content .gnav {
      margin: 35px auto 0; } }
  @media (max-width: 575.98px) {
    footer#footer .footerwrap .content .gnav {
      margin: 20px auto 0; } }
  footer#footer .footerwrap .content .gnav > ul {
    display: flex;
    justify-content: center;
    width: auto;
    margin: 0;
    padding: 0;
    list-style: none; }
    @media (max-width: 991.98px) {
      footer#footer .footerwrap .content .gnav > ul {
        justify-content: space-around; } }
    @media (max-width: 575.98px) {
      footer#footer .footerwrap .content .gnav > ul {
        flex-wrap: wrap; } }
    footer#footer .footerwrap .content .gnav > ul > li {
      display: block;
      margin: 0;
      padding: 0; }
      @media (max-width: 575.98px) {
        footer#footer .footerwrap .content .gnav > ul > li {
          width: 100%; } }
      footer#footer .footerwrap .content .gnav > ul > li a {
        display: block;
        margin: 0 0.5em;
        padding: 0.25em 1.5em;
        color: #333;
        font-size: 13px;
        font-weight: 700;
        position: relative;
        transition: color 0.2s 0.06s; }
        footer#footer .footerwrap .content .gnav > ul > li a:after {
          content: "";
          display: block;
          width: 0;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: #43a5d7;
          transition: width 0.2s; }
        footer#footer .footerwrap .content .gnav > ul > li a > span {
          position: relative;
          z-index: 1; }
        @media (min-width: 768px) {
          footer#footer .footerwrap .content .gnav > ul > li a:hover {
            color: #FFF; }
            footer#footer .footerwrap .content .gnav > ul > li a:hover:after {
              width: 100%; } }
        @media (max-width: 1399.98px) {
          footer#footer .footerwrap .content .gnav > ul > li a {
            margin: 0 0.25em;
            padding: 0.25em 1em; } }
        @media (max-width: 991.98px) {
          footer#footer .footerwrap .content .gnav > ul > li a {
            margin: 0;
            padding: 1em 0;
            font-size: 12px; } }
        @media (max-width: 575.98px) {
          footer#footer .footerwrap .content .gnav > ul > li a {
            margin: 0;
            padding: 0.75em 0;
            text-align: center;
            font-size: 14px; } }

footer#footer .footerwrap .content .social {
  margin-left: auto; }
  footer#footer .footerwrap .content .social > ul {
    display: flex;
    width: auto;
    margin: 0;
    padding: 0;
    list-style: none; }
    footer#footer .footerwrap .content .social > ul > li {
      display: block;
      margin: 0;
      padding: 0; }
      footer#footer .footerwrap .content .social > ul > li a {
        display: block;
        width: 34px;
        padding: 4px;
        transition: opacity 0.2s; }
        @media (min-width: 768px) {
          footer#footer .footerwrap .content .social > ul > li a:hover {
            opacity: 0.33; } }
  @media (max-width: 575.98px) {
    footer#footer .footerwrap .content .social {
      width: 100%;
      margin: 36px auto 0; }
      footer#footer .footerwrap .content .social > ul {
        justify-content: center; }
        footer#footer .footerwrap .content .social > ul > li a {
          margin: 0 10px;
          width: 36px;
          padding: 2px; } }

footer#footer .footerwrap .cr {
  margin: 0;
  padding: 100px 0;
  text-align: center;
  line-height: 1;
  color: #989897;
  font-size: 10px;
  font-weight: 700; }
  @media (max-width: 1399.98px) {
    footer#footer .footerwrap .cr {
      padding: 90px 0; } }
  @media (max-width: 991.98px) {
    footer#footer .footerwrap .cr {
      padding: 80px 0; } }
  @media (max-width: 767.98px) {
    footer#footer .footerwrap .cr {
      padding: 60px 0; } }
  @media (max-width: 575.98px) {
    footer#footer .footerwrap .cr {
      padding: 50px 0; } }

main#mainContent.index .section-head {
  color: #333;
  font-size: 28px;
  font-weight: 900; }
  @media (max-width: 1399.98px) {
    main#mainContent.index .section-head {
      font-size: 27px; } }
  @media (max-width: 991.98px) {
    main#mainContent.index .section-head {
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    main#mainContent.index .section-head {
      font-size: 24px; } }
  @media (max-width: 575.98px) {
    main#mainContent.index .section-head {
      font-size: 22px; } }
  main#mainContent.index .section-head small {
    font-size: 12px;
    font-weight: 700; }
    @media (max-width: 767.98px) {
      main#mainContent.index .section-head small {
        font-size: 11px; } }
    @media (max-width: 575.98px) {
      main#mainContent.index .section-head small {
        font-size: 10px; } }

main#mainContent.index .section-link a {
  display: block;
  width: auto;
  height: auto;
  padding: 1.5em 0;
  line-height: 1;
  text-align: center;
  color: #FFF;
  font-size: 18px;
  font-weight: 700;
  border-radius: 100px;
  background: linear-gradient(to right, #43a2d7 0%, #43b6d7 100%);
  position: relative;
  overflow: hidden;
  transition: all 0.3s 0.06s; }
  main#mainContent.index .section-link a > span {
    position: relative;
    z-index: 1; }
  main#mainContent.index .section-link a:before, main#mainContent.index .section-link a:after {
    content: "";
    display: block;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
    background: #FFF;
    opacity: 0;
    transition: all 0.3s; }
  main#mainContent.index .section-link a:before {
    transform: translateX(-110%) skew(-45deg);
    left: 0; }
  main#mainContent.index .section-link a:after {
    transform: translateX(110%) skew(-45deg);
    right: 0; }
  @media (max-width: 1399.98px) {
    main#mainContent.index .section-link a {
      font-size: 17px; } }
  @media (max-width: 991.98px) {
    main#mainContent.index .section-link a {
      font-size: 16px; } }
  @media (max-width: 767.98px) {
    main#mainContent.index .section-link a {
      font-size: 15px; } }
  @media (max-width: 575.98px) {
    main#mainContent.index .section-link a {
      font-size: 15px; } }
  @media (min-width: 768px) {
    main#mainContent.index .section-link a:hover {
      color: #43a5d7; }
      main#mainContent.index .section-link a:hover:before, main#mainContent.index .section-link a:hover:after {
        transform: translateX(0) skew(5deg);
        opacity: 0.77; } }

main#mainContent.index .section-link2 a {
  display: block;
  width: auto;
  height: auto;
  padding: 1.5em 0;
  line-height: 1;
  text-align: center;
  color: #FFF;
  font-size: 18px;
  font-weight: 700;
  border-radius: 100px;
  background: linear-gradient(to right, #2fb383 0%, #18a86b 100%);
  position: relative;
  overflow: hidden;
  transition: all 0.3s 0.06s; }
  main#mainContent.index .section-link2 a > span {
    position: relative;
    z-index: 1; }
  main#mainContent.index .section-link2 a:before, main#mainContent.index .section-link2 a:after {
    content: "";
    display: block;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
    background: #FFF;
    opacity: 0;
    transition: all 0.3s; }
  main#mainContent.index .section-link2 a:before {
    transform: translateX(-110%) skew(-45deg);
    left: 0; }
  main#mainContent.index .section-link2 a:after {
    transform: translateX(110%) skew(-45deg);
    right: 0; }
  @media (max-width: 1399.98px) {
    main#mainContent.index .section-link2 a {
      font-size: 17px; } }
  @media (max-width: 991.98px) {
    main#mainContent.index .section-link2 a {
      font-size: 16px; } }
  @media (max-width: 767.98px) {
    main#mainContent.index .section-link2 a {
      font-size: 15px; } }
  @media (max-width: 575.98px) {
    main#mainContent.index .section-link2 a {
      font-size: 15px; } }
  @media (min-width: 768px) {
    main#mainContent.index .section-link2 a:hover {
      color: #43a5d7; }
      main#mainContent.index .section-link2 a:hover:before, main#mainContent.index .section-link2 a:hover:after {
        transform: translateX(0) skew(5deg);
        opacity: 0.77; } }

main#mainContent.index section.mainvisual {
  width: 100%;
  position: relative;
  z-index: 0;
  height: auto;
  overflow: hidden; }
  @media (max-width: 767.98px) {
    main#mainContent.index section.mainvisual {
      height: 40vh; } }
  @media (max-width: 575.98px) {
    main#mainContent.index section.mainvisual {
      height: 46vh; } }
  main#mainContent.index section.mainvisual #intro_ban {
    position: absolute;
    left: 2em;
    bottom: 3em;
    z-index: 5; }
    main#mainContent.index section.mainvisual #intro_ban a:hover {
      opacity: 0.8; }
    @media (max-width: 991.98px) {
      main#mainContent.index section.mainvisual #intro_ban {
        left: 2em;
        bottom: 3em; } }
    @media (max-width: 767.98px) {
      main#mainContent.index section.mainvisual #intro_ban {
        bottom: 2em; }
        main#mainContent.index section.mainvisual #intro_ban img {
          width: 80%; } }
  main#mainContent.index section.mainvisual #er {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%; }
    main#mainContent.index section.mainvisual #er p {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      line-height: 1; }
      main#mainContent.index section.mainvisual #er p span {
        font-feature-settings: unset;
        font-family: 'DINNextLTPro-Bold', sans-serif;
        color: #FFF;
        font-size: 100px;
        background: url(./img/index/mv/er.png) no-repeat;
        display: inline-block;
        height: 72px;
        line-height: 86px;
        margin: 0;
        padding: 0 100px 0 0;
        background-size: auto 100%;
        background-position: 100% 0;
        text-shadow: -2px -1px 8px #bdbdbd; }
        @media (max-width: 1399.98px) {
          main#mainContent.index section.mainvisual #er p span {
            font-size: 90px;
            height: 64px;
            line-height: 79px;
            padding: 0 88px 0 0; } }
        @media (max-width: 991.98px) {
          main#mainContent.index section.mainvisual #er p span {
            font-size: 80px;
            height: 57px;
            line-height: 60px;
            padding: 0 80px 0 0; } }
        @media (max-width: 767.98px) {
          main#mainContent.index section.mainvisual #er p span {
            font-size: 65px;
            height: 46px;
            line-height: 55px;
            padding: 0 63px 0 0; } }
        @media (max-width: 575.98px) {
          main#mainContent.index section.mainvisual #er p span {
            font-size: 9vw;
            height: 6.5vw;
            line-height: 8.6vw;
            padding: 0 9vw 0 0; } }
      main#mainContent.index section.mainvisual #er p img {
        /*				display: none;*/
        margin: 50px auto 0;
        width: auto;
        max-width: 60%; }
        @media (max-width: 1399.98px) {
          main#mainContent.index section.mainvisual #er p img {
            margin: 40px auto 0;
            max-width: 75%; } }
        @media (max-width: 991.98px) {
          main#mainContent.index section.mainvisual #er p img {
            margin: 35px auto 0;
            max-width: 85%; } }
        @media (max-width: 767.98px) {
          main#mainContent.index section.mainvisual #er p img {
            margin: 40px auto 0;
            max-width: 90%; } }
        @media (max-width: 575.98px) {
          main#mainContent.index section.mainvisual #er p img {
            margin: 30px auto 0;
            max-width: 95%; } }
  main#mainContent.index section.mainvisual .wrap {
    position: relative;
    z-index: 0;
    overflow: hidden;
    height: 52.6666666vw; }
    main#mainContent.index section.mainvisual .wrap > img {
      visibility: hidden;
      width: 100%;
      height: auto;
      line-height: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0; }
    @media (max-width: 767.98px) {
      main#mainContent.index section.mainvisual .wrap {
        height: 100%;
        width: calc(40vh * 1.86335403727);
        left: calc(50% - (20vh * 1.86335403727)); } }
    @media (max-width: 575.98px) {
      main#mainContent.index section.mainvisual .wrap {
        width: calc(46vh * 1.86335403727);
        left: calc(50% - (23vh * 1.86335403727)); } }
  main#mainContent.index section.mainvisual .loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
    main#mainContent.index section.mainvisual .loading svg {
      width: 100px;
      height: auto; }

main#mainContent.index section.topics {
  position: relative;
  z-index: 1;
  background: #f5f7f8; }
  main#mainContent.index section.topics .conttent {
    transform: translateY(-50%);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%; }
    @media (max-width: 767.98px) {
      main#mainContent.index section.topics .conttent {
        justify-content: flex-start;
        transform: translateY(0);
        padding: 35px 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.index section.topics .conttent {
        padding: 25px 0; } }
    main#mainContent.index section.topics .conttent > dl {
      width: 600px;
      margin: 0;
      border-radius: 9px;
      overflow: hidden; }
      @media (max-width: 767.98px) {
        main#mainContent.index section.topics .conttent > dl {
          width: 100%; } }
      main#mainContent.index section.topics .conttent > dl dt,
      main#mainContent.index section.topics .conttent > dl dd {
        display: block;
        width: auto;
        height: auto;
        margin: 0;
        padding: 2em 3.5em;
        font-size: 14px; }
        @media (max-width: 1399.98px) {
          main#mainContent.index section.topics .conttent > dl dt,
          main#mainContent.index section.topics .conttent > dl dd {
            padding: 1.8em 3.5em; } }
        @media (max-width: 991.98px) {
          main#mainContent.index section.topics .conttent > dl dt,
          main#mainContent.index section.topics .conttent > dl dd {
            padding: 1.6em 2.8em; } }
        @media (max-width: 767.98px) {
          main#mainContent.index section.topics .conttent > dl dt,
          main#mainContent.index section.topics .conttent > dl dd {
            padding: 1.5em 2em;
            font-size: 13px; } }
        @media (max-width: 575.98px) {
          main#mainContent.index section.topics .conttent > dl dt,
          main#mainContent.index section.topics .conttent > dl dd {
            padding: 1.1em 1.2em; } }
      main#mainContent.index section.topics .conttent > dl dt {
        color: #FFF;
        background: #43a5d7; }
      main#mainContent.index section.topics .conttent > dl dd {
        flex: 1;
        color: #333;
        background: #FFF; }

main#mainContent.index section.about {
  padding: 0 0 95px;
  background: #f5f7f8; }
  @media (max-width: 1399.98px) {
    main#mainContent.index section.about {
      padding: 0 0 80px; } }
  @media (max-width: 991.98px) {
    main#mainContent.index section.about {
      padding: 0; } }
  @media (min-width: 992px) {
    main#mainContent.index section.about .content {
      width: auto;
      max-width: 1500px;
      margin: 0 auto; } }
  main#mainContent.index section.about .wrap {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 991.98px) {
      main#mainContent.index section.about .wrap {
        flex-wrap: wrap;
        flex-direction: column-reverse; } }
    main#mainContent.index section.about .wrap .photo {
      width: 970px;
      padding: 0 0 50px; }
      @media (max-width: 991.98px) {
        main#mainContent.index section.about .wrap .photo {
          width: 100%;
          padding: 0; } }
    main#mainContent.index section.about .wrap .boxwrap {
      z-index: 10;
      width: auto;
      position: relative; }
      @media (max-width: 991.98px) {
        main#mainContent.index section.about .wrap .boxwrap {
          width: 100%;
          padding: 0; } }
      main#mainContent.index section.about .wrap .boxwrap .box {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 725px;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 0 18px 18px 0; }
        @media (max-width: 1399.98px) {
          main#mainContent.index section.about .wrap .boxwrap .box {
            width: 540px; } }
        @media (max-width: 991.98px) {
          main#mainContent.index section.about .wrap .boxwrap .box {
            position: static;
            width: 92%;
            margin: 0 auto;
            border-radius: 18px;
            transform: translateY(-50px); } }
        @media (max-width: 575.98px) {
          main#mainContent.index section.about .wrap .boxwrap .box {
            width: 94%;
            border-radius: 14px;
            transform: translateY(-35px); } }
        main#mainContent.index section.about .wrap .boxwrap .box .pad {
          padding: 50px 115px 50px 70px; }
          @media (max-width: 1399.98px) {
            main#mainContent.index section.about .wrap .boxwrap .box .pad {
              padding: 45px 55px; } }
          @media (max-width: 991.98px) {
            main#mainContent.index section.about .wrap .boxwrap .box .pad {
              padding: 35px 40px; } }
          @media (max-width: 767.98px) {
            main#mainContent.index section.about .wrap .boxwrap .box .pad {
              padding: 30px 35px; } }
          @media (max-width: 575.98px) {
            main#mainContent.index section.about .wrap .boxwrap .box .pad {
              padding: 25px 30px; } }
        main#mainContent.index section.about .wrap .boxwrap .box .iiii {
          padding: 45px 0;
          line-height: 0; }
          @media (max-width: 1399.98px) {
            main#mainContent.index section.about .wrap .boxwrap .box .iiii {
              padding: 45px 0; } }
          @media (max-width: 991.98px) {
            main#mainContent.index section.about .wrap .boxwrap .box .iiii {
              padding: 40px 0; } }
          @media (max-width: 767.98px) {
            main#mainContent.index section.about .wrap .boxwrap .box .iiii {
              padding: 35px 0; } }
          main#mainContent.index section.about .wrap .boxwrap .box .iiii i {
            display: inline-block;
            width: 3px;
            height: 6px;
            margin: 0 3px 0 0;
            background: #43a5d7;
            transform: skewX(-24deg); }
        main#mainContent.index section.about .wrap .boxwrap .box .text {
          font-size: 14px;
          line-height: 2; }
        main#mainContent.index section.about .wrap .boxwrap .box .section-link {
          margin: 45px 0 0; }
          @media (max-width: 1399.98px) {
            main#mainContent.index section.about .wrap .boxwrap .box .section-link {
              margin: 40px 0 0; } }
          @media (max-width: 991.98px) {
            main#mainContent.index section.about .wrap .boxwrap .box .section-link {
              margin: 35px 0 0; } }
          @media (max-width: 767.98px) {
            main#mainContent.index section.about .wrap .boxwrap .box .section-link {
              margin: 30px 0 0; } }

main#mainContent.index section.service {
  padding: 80px 0;
  background: #FFF; }
  @media (max-width: 1399.98px) {
    main#mainContent.index section.service {
      padding: 70px 0; } }
  @media (max-width: 991.98px) {
    main#mainContent.index section.service {
      padding: 60px 0; } }
  @media (max-width: 767.98px) {
    main#mainContent.index section.service {
      padding: 50px 0; } }
  @media (max-width: 575.98px) {
    main#mainContent.index section.service {
      padding: 40px 0; } }
  main#mainContent.index section.service .mess {
    width: 920px;
    margin: 75px auto 0;
    padding: 0;
    text-align: center;
    line-height: 1.5;
    font-family: TsukuARdGothicStd-E; }
    @media (max-width: 1399.98px) {
      main#mainContent.index section.service .mess {
        width: 100%;
        margin: 65px auto 0; } }
    @media (max-width: 991.98px) {
      main#mainContent.index section.service .mess {
        margin: 55px auto 0; } }
    @media (max-width: 767.98px) {
      main#mainContent.index section.service .mess {
        margin: 45px auto 0;
        text-align: left;
        font-size: 0.9em; } }
    @media (max-width: 575.98px) {
      main#mainContent.index section.service .mess {
        margin: 35px auto 0; } }
    @media (max-width: 991.98px) {
      main#mainContent.index section.service .mess br {
        display: none; } }
  main#mainContent.index section.service .row {
    margin-top: 45px; }
    @media (max-width: 991.98px) {
      main#mainContent.index section.service .row {
        margin-top: 40px 0; } }
    @media (max-width: 767.98px) {
      main#mainContent.index section.service .row {
        margin-top: 35px 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.index section.service .row {
        margin-top: 30px 0; } }
    main#mainContent.index section.service .row .num1 {
      order: 1; }
    main#mainContent.index section.service .row .num2 {
      order: 2; }
    main#mainContent.index section.service .row .num3 {
      order: 3; }
    main#mainContent.index section.service .row .cont1 {
      order: 4; }
    main#mainContent.index section.service .row .cont2 {
      order: 5; }
    main#mainContent.index section.service .row .cont3 {
      order: 6; }
    @media (max-width: 767.98px) {
      main#mainContent.index section.service .row .num1 {
        order: 1; }
      main#mainContent.index section.service .row .cont1 {
        order: 2; }
      main#mainContent.index section.service .row .num2 {
        order: 3;
        margin-top: 40px; }
      main#mainContent.index section.service .row .cont2 {
        order: 4; }
      main#mainContent.index section.service .row .num3 {
        order: 5;
        margin-top: 40px; }
      main#mainContent.index section.service .row .cont3 {
        order: 6; } }
  main#mainContent.index section.service .point_num {
    display: block;
    text-align: center; }
    main#mainContent.index section.service .point_num img {
      display: block;
      width: 48px;
      height: auto;
      margin: 0 auto; }
    main#mainContent.index section.service .point_num span {
      display: block;
      margin: 1.25em 0;
      padding: 0;
      line-height: 1.4;
      color: #43a5d7; }
      @media (max-width: 1399.98px) {
        main#mainContent.index section.service .point_num span {
          margin: 1.15em 0; } }
      @media (max-width: 991.98px) {
        main#mainContent.index section.service .point_num span {
          margin: 1em 0;
          font-size: 15px; } }
      @media (max-width: 767.98px) {
        main#mainContent.index section.service .point_num span {
          margin: 1.15em 0;
          font-size: 18px; } }
      @media (max-width: 575.98px) {
        main#mainContent.index section.service .point_num span {
          margin: 1em 0;
          font-size: 16px; } }
  main#mainContent.index section.service .point_cont .cont_in {
    display: block;
    margin: 0;
    background: #f5f7f8;
    border-radius: 18px;
    overflow: hidden;
    height: 100%; }
    @media (max-width: 575.98px) {
      main#mainContent.index section.service .point_cont .cont_in {
        border-radius: 14px; } }
    main#mainContent.index section.service .point_cont .cont_in .txtwrap {
      margin-top: 0;
      display: block;
      padding: 2em 1.75em;
      font-size: 14px; }
      @media (max-width: 1399.98px) {
        main#mainContent.index section.service .point_cont .cont_in .txtwrap {
          padding: 1.8em 1.75em; } }
      @media (max-width: 991.98px) {
        main#mainContent.index section.service .point_cont .cont_in .txtwrap {
          padding: 1.6em 1.5em; } }
      @media (max-width: 767.98px) {
        main#mainContent.index section.service .point_cont .cont_in .txtwrap {
          padding: 1.5em 1.375em; } }
      @media (max-width: 575.98px) {
        main#mainContent.index section.service .point_cont .cont_in .txtwrap {
          padding: 1.4em 1.25em; } }
  main#mainContent.index section.service .section-link {
    display: block;
    width: auto;
    max-width: 530px;
    margin: 0 auto;
    padding: 80px 0 0; }
    @media (max-width: 1399.98px) {
      main#mainContent.index section.service .section-link {
        padding: 70px 0 0; } }
    @media (max-width: 991.98px) {
      main#mainContent.index section.service .section-link {
        padding: 60px 0 0; } }
    @media (max-width: 767.98px) {
      main#mainContent.index section.service .section-link {
        margin: 0 15px;
        padding: 50px 0 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.index section.service .section-link {
        padding: 40px 0 0; } }
  main#mainContent.index section.service .section-link2 {
    display: block;
    width: auto;
    max-width: 530px;
    margin: 0 auto;
    padding: 40px 0 0; }
    @media (max-width: 1399.98px) {
      main#mainContent.index section.service .section-link2 {
        padding: 70px 0 0; } }
    @media (max-width: 991.98px) {
      main#mainContent.index section.service .section-link2 {
        padding: 60px 0 0; } }
    @media (max-width: 767.98px) {
      main#mainContent.index section.service .section-link2 {
        margin: 0 15px;
        padding: 50px 0 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.index section.service .section-link2 {
        padding: 40px 0 0; } }

main#mainContent.index section.case {
  padding: 80px 0;
  background: #f5f7f8; }
  @media (max-width: 1399.98px) {
    main#mainContent.index section.case {
      padding: 70px 0; } }
  @media (max-width: 991.98px) {
    main#mainContent.index section.case {
      padding: 60px 0; } }
  @media (max-width: 767.98px) {
    main#mainContent.index section.case {
      padding: 50px 0; } }
  @media (max-width: 575.98px) {
    main#mainContent.index section.case {
      padding: 40px 0; } }
  main#mainContent.index section.case .pickup {
    margin: 60px 0 0; }
    @media (max-width: 1399.98px) {
      main#mainContent.index section.case .pickup {
        margin: 55px 0; } }
    @media (max-width: 991.98px) {
      main#mainContent.index section.case .pickup {
        margin: 45px 0; } }
    @media (max-width: 767.98px) {
      main#mainContent.index section.case .pickup {
        margin: 40px 0 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.index section.case .pickup {
        margin: 35px 0 0; } }
    main#mainContent.index section.case .pickup .pickup_in {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%; }
      @media (max-width: 767.98px) {
        main#mainContent.index section.case .pickup .pickup_in {
          flex-wrap: wrap; } }
      main#mainContent.index section.case .pickup .pickup_in .photo {
        width: 870px; }
        @media (max-width: 1399.98px) {
          main#mainContent.index section.case .pickup .pickup_in .photo {
            width: 820px; } }
        @media (max-width: 991.98px) {
          main#mainContent.index section.case .pickup .pickup_in .photo {
            width: 620px; } }
        @media (max-width: 767.98px) {
          main#mainContent.index section.case .pickup .pickup_in .photo {
            width: 100%; } }
      main#mainContent.index section.case .pickup .pickup_in .boxwrap {
        width: auto;
        position: relative; }
        main#mainContent.index section.case .pickup .pickup_in .boxwrap .box {
          position: absolute;
          bottom: 0;
          right: 0;
          display: block;
          width: 490px;
          margin: 0;
          background: #FFF;
          z-index: 100; }
          @media (max-width: 1399.98px) {
            main#mainContent.index section.case .pickup .pickup_in .boxwrap .box {
              width: 420px; } }
          @media (max-width: 991.98px) {
            main#mainContent.index section.case .pickup .pickup_in .boxwrap .box {
              width: 380px; } }
          @media (max-width: 767.98px) {
            main#mainContent.index section.case .pickup .pickup_in .boxwrap .box {
              position: static;
              width: 90%;
              margin: 0 auto;
              transform: translateY(-45px); } }
          @media (max-width: 575.98px) {
            main#mainContent.index section.case .pickup .pickup_in .boxwrap .box {
              width: 96%;
              transform: translateY(-35px); } }
          @media (min-width: 768px) {
            main#mainContent.index section.case .pickup .pickup_in .boxwrap .box:before, main#mainContent.index section.case .pickup .pickup_in .boxwrap .box:after {
              content: "";
              display: block;
              width: 0;
              height: 0;
              box-sizing: border-box;
              position: absolute;
              border-color: #43a5d7;
              border-style: solid;
              opacity: 0;
              transition: all 0.2s; }
            main#mainContent.index section.case .pickup .pickup_in .boxwrap .box:before {
              top: 0;
              left: 0;
              border-width: 6px 0 0 6px; }
            main#mainContent.index section.case .pickup .pickup_in .boxwrap .box:after {
              bottom: 0;
              right: 0;
              border-width: 0 6px 6px 0; }
            main#mainContent.index section.case .pickup .pickup_in .boxwrap .box:hover:before, main#mainContent.index section.case .pickup .pickup_in .boxwrap .box:hover:after {
              opacity: 1;
              width: 100%;
              height: 100%;
              transition: all 0.3s; } }
          main#mainContent.index section.case .pickup .pickup_in .boxwrap .box > dl {
            display: block;
            margin: 0;
            padding: 54px 36px; }
            @media (max-width: 1399.98px) {
              main#mainContent.index section.case .pickup .pickup_in .boxwrap .box > dl {
                padding: 35px 30px; } }
            @media (max-width: 991.98px) {
              main#mainContent.index section.case .pickup .pickup_in .boxwrap .box > dl {
                padding: 24px 20px; } }
            @media (max-width: 767.98px) {
              main#mainContent.index section.case .pickup .pickup_in .boxwrap .box > dl {
                padding: 35px 30px; } }
            @media (max-width: 575.98px) {
              main#mainContent.index section.case .pickup .pickup_in .boxwrap .box > dl {
                padding: 24px 20px; } }
            main#mainContent.index section.case .pickup .pickup_in .boxwrap .box > dl > dt {
              color: #43a5d7;
              font-size: 24px;
              font-weight: 700; }
              @media (max-width: 1399.98px) {
                main#mainContent.index section.case .pickup .pickup_in .boxwrap .box > dl > dt {
                  font-size: 22px; } }
              @media (max-width: 991.98px) {
                main#mainContent.index section.case .pickup .pickup_in .boxwrap .box > dl > dt {
                  font-size: 19px; } }
              @media (max-width: 767.98px) {
                main#mainContent.index section.case .pickup .pickup_in .boxwrap .box > dl > dt {
                  font-size: 22px; } }
              @media (max-width: 575.98px) {
                main#mainContent.index section.case .pickup .pickup_in .boxwrap .box > dl > dt {
                  font-size: 17px; } }
            main#mainContent.index section.case .pickup .pickup_in .boxwrap .box > dl > dd {
              display: block;
              margin: 0;
              padding: 1.75em 0 0;
              color: #333;
              font-size: 16px; }
              @media (max-width: 1399.98px) {
                main#mainContent.index section.case .pickup .pickup_in .boxwrap .box > dl > dd {
                  padding: 1.5em 0 0;
                  font-size: 15px; } }
              @media (max-width: 991.98px) {
                main#mainContent.index section.case .pickup .pickup_in .boxwrap .box > dl > dd {
                  padding: 1.25em 0 0;
                  font-size: 14px; } }
              @media (max-width: 767.98px) {
                main#mainContent.index section.case .pickup .pickup_in .boxwrap .box > dl > dd {
                  padding: 1em 0 0;
                  font-size: 13px; } }
  main#mainContent.index section.case .row {
    margin-top: 50px; }
    @media (max-width: 1399.98px) {
      main#mainContent.index section.case .row {
        margin-top: 45px; } }
    @media (max-width: 991.98px) {
      main#mainContent.index section.case .row {
        margin-top: 40px; } }
    @media (max-width: 767.98px) {
      main#mainContent.index section.case .row {
        margin-top: 0; } }
  @media (max-width: 767.98px) {
    main#mainContent.index section.case .item:not(:first-child) {
      margin-top: 30px; } }
  @media (max-width: 575.98px) {
    main#mainContent.index section.case .item:not(:first-child) {
      margin-top: 25px; } }
  main#mainContent.index section.case .item > a {
    height: 100%;
    background: #FFF; }
    main#mainContent.index section.case .item > a dl {
      display: block;
      margin: 0;
      padding: 22px 24px; }
      @media (max-width: 1399.98px) {
        main#mainContent.index section.case .item > a dl {
          padding: 20px 22px; } }
      @media (max-width: 991.98px) {
        main#mainContent.index section.case .item > a dl {
          padding: 16px 20px; } }
      @media (max-width: 767.98px) {
        main#mainContent.index section.case .item > a dl {
          padding: 1em 1.5em; } }
      @media (max-width: 575.98px) {
        main#mainContent.index section.case .item > a dl {
          padding: 0.75em 1em; } }
      main#mainContent.index section.case .item > a dl > dt {
        color: #43a5d7; }
        @media (max-width: 1399.98px) {
          main#mainContent.index section.case .item > a dl > dt {
            font-size: 15px; } }
        @media (max-width: 767.98px) {
          main#mainContent.index section.case .item > a dl > dt {
            font-size: 14px; } }
        @media (max-width: 575.98px) {
          main#mainContent.index section.case .item > a dl > dt {
            font-size: 13px; } }
      main#mainContent.index section.case .item > a dl > dd {
        color: #333;
        font-size: 14px; }
        @media (max-width: 767.98px) {
          main#mainContent.index section.case .item > a dl > dd {
            font-size: 12px; } }
        @media (max-width: 575.98px) {
          main#mainContent.index section.case .item > a dl > dd {
            font-size: 11px; } }
    @media (min-width: 768px) {
      main#mainContent.index section.case .item > a {
        position: relative; }
        main#mainContent.index section.case .item > a:before, main#mainContent.index section.case .item > a:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          box-sizing: border-box;
          position: absolute;
          border-color: #43a5d7;
          border-style: solid;
          opacity: 0;
          transition: all 0.2s; }
        main#mainContent.index section.case .item > a:before {
          top: 0;
          left: 0;
          border-width: 6px 0 0 6px; }
        main#mainContent.index section.case .item > a:after {
          bottom: 0;
          right: 0;
          border-width: 0 6px 6px 0; }
        main#mainContent.index section.case .item > a:hover:before, main#mainContent.index section.case .item > a:hover:after {
          opacity: 1;
          width: 100%;
          height: 100%;
          transition: all 0.3s;
          z-index: 10; } }
  main#mainContent.index section.case .section-link {
    display: block;
    width: auto;
    max-width: 530px;
    margin: 0 auto;
    padding: 80px 0 0; }
    @media (max-width: 1399.98px) {
      main#mainContent.index section.case .section-link {
        padding: 70px 0 0; } }
    @media (max-width: 991.98px) {
      main#mainContent.index section.case .section-link {
        padding: 60px 0 0; } }
    @media (max-width: 767.98px) {
      main#mainContent.index section.case .section-link {
        margin: 0 15px;
        padding: 50px 0 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.index section.case .section-link {
        padding: 40px 0 0; } }

main#mainContent.index .grade_bg {
  padding: 100px 60px;
  background: linear-gradient(to right, #FFFFFF 0%, #e4f3f7 100%); }
  @media (max-width: 1399.98px) {
    main#mainContent.index .grade_bg {
      padding: 85px 50px; } }
  @media (max-width: 991.98px) {
    main#mainContent.index .grade_bg {
      padding: 65px 30px; } }
  @media (max-width: 767.98px) {
    main#mainContent.index .grade_bg {
      padding: 50px 15px; } }
  @media (max-width: 575.98px) {
    main#mainContent.index .grade_bg {
      padding: 40px 0; } }

main#mainContent.index section.news .content {
  margin: 75px 0 0;
  padding: 0; }
  @media (max-width: 1399.98px) {
    main#mainContent.index section.news .content {
      margin: 70px 0 0; } }
  @media (max-width: 991.98px) {
    main#mainContent.index section.news .content {
      margin: 60px 0 0; } }
  @media (max-width: 767.98px) {
    main#mainContent.index section.news .content {
      margin: 50px 0 0; } }
  @media (max-width: 575.98px) {
    main#mainContent.index section.news .content {
      margin: 40px 0 0; } }
  main#mainContent.index section.news .content .item {
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 2em; }
    @media (max-width: 1399.98px) {
      main#mainContent.index section.news .content .item {
        margin: 0 0 1.8em; } }
    @media (max-width: 991.98px) {
      main#mainContent.index section.news .content .item {
        margin: 0 0 1.6em; } }
    @media (max-width: 767.98px) {
      main#mainContent.index section.news .content .item {
        margin: 0 0 1.5em; } }
    @media (max-width: 575.98px) {
      main#mainContent.index section.news .content .item {
        margin: 0 0 1.4em; } }
    main#mainContent.index section.news .content .item .date {
      width: 10em;
      font-size: 14px;
      word-wrap: none; }
      @media (max-width: 991.98px) {
        main#mainContent.index section.news .content .item .date {
          font-size: 13px; } }
      @media (max-width: 767.98px) {
        main#mainContent.index section.news .content .item .date {
          font-size: 12px; } }
    main#mainContent.index section.news .content .item .cats {
      width: 6em;
      word-wrap: none; }
      main#mainContent.index section.news .content .item .cats span {
        display: inline-block;
        margin: 0;
        padding: 0.5em 1em;
        line-height: 1;
        color: #474747;
        font-size: 12px;
        font-weight: 700;
        background: #eeedcc;
        border-radius: 5px;
        word-wrap: none; }
        @media (max-width: 767.98px) {
          main#mainContent.index section.news .content .item .cats span {
            font-size: 11px; } }
    main#mainContent.index section.news .content .item .title {
      width: auto;
      font-size: 14px; }
      @media (max-width: 991.98px) {
        main#mainContent.index section.news .content .item .title {
          font-size: 13px; } }
      @media (max-width: 767.98px) {
        main#mainContent.index section.news .content .item .title {
          font-size: 12px; } }
      main#mainContent.index section.news .content .item .title a {
        color: #333;
        text-decoration: underline; }
        @media (min-width: 768px) {
          main#mainContent.index section.news .content .item .title a:hover {
            text-decoration: none; } }

main#mainContent.index section.news .link {
  margin: 45px 0 0; }
  @media (max-width: 1399.98px) {
    main#mainContent.index section.news .link {
      margin: 40px 0 0; } }
  @media (max-width: 991.98px) {
    main#mainContent.index section.news .link {
      margin: 35px 0 0; } }
  @media (max-width: 767.98px) {
    main#mainContent.index section.news .link {
      margin: 30px 0 0; } }
  main#mainContent.index section.news .link a {
    color: #333;
    text-decoration: none; }
    @media (max-width: 991.98px) {
      main#mainContent.index section.news .link a {
        font-size: 13px; } }
    @media (min-width: 768px) {
      main#mainContent.index section.news .link a:hover {
        text-decoration: none; } }

main#mainContent.index section.company {
  padding: 0; }
  main#mainContent.index section.company .content {
    margin: 35px 0 0;
    padding: 0; }
    @media (max-width: 991.98px) {
      main#mainContent.index section.company .content {
        margin: 30px 0 0; } }
    @media (max-width: 767.98px) {
      main#mainContent.index section.company .content {
        margin: 25px 0 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.index section.company .content {
        margin: 20px 0 0; } }
    main#mainContent.index section.company .content .link {
      bottom: 0;
      right: 0; }
      @media (max-width: 575.98px) {
        main#mainContent.index section.company .content .link {
          width: 100%; } }
      main#mainContent.index section.company .content .link a {
        display: block;
        width: 390px;
        padding: 2em 0;
        color: #FFF;
        background: linear-gradient(to right, rgba(67, 162, 215, 0.75) 0%, rgba(67, 182, 215, 0.75) 100%);
        transition: opacity 0.2s; }
        @media (max-width: 1399.98px) {
          main#mainContent.index section.company .content .link a {
            width: 320px;
            padding: 1.6em 0; } }
        @media (max-width: 767.98px) {
          main#mainContent.index section.company .content .link a {
            width: 300px;
            padding: 1.4em 0;
            font-size: 15px; } }
        @media (max-width: 575.98px) {
          main#mainContent.index section.company .content .link a {
            width: 100%;
            font-size: 14px; } }
        @media (min-width: 768px) {
          main#mainContent.index section.company .content .link a:hover {
            opacity: 0.66; } }

main#mainContent.service section.intro1 {
  font-size: 14px;
  background: #FFF;
  padding: 120px 0 0 0; }
  @media (max-width: 1399.98px) {
    main#mainContent.service section.intro1 {
      padding: 60px 0; } }
  @media (max-width: 991.98px) {
    main#mainContent.service section.intro1 {
      padding: 50px 0; } }
  @media (max-width: 767.98px) {
    main#mainContent.service section.intro1 {
      padding: 40px 0; } }
  @media (max-width: 575.98px) {
    main#mainContent.service section.intro1 {
      padding: 40px 0; } }
  main#mainContent.service section.intro1:first-child {
    margin-top: 60px; }
    @media (max-width: 1399.98px) {
      main#mainContent.service section.intro1:first-child {
        margin-top: 50px; } }
    @media (max-width: 991.98px) {
      main#mainContent.service section.intro1:first-child {
        margin-top: 40px; } }
    @media (max-width: 767.98px) {
      main#mainContent.service section.intro1:first-child {
        margin-top: 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.service section.intro1:first-child {
        margin-top: 0; } }
  main#mainContent.service section.intro1 .row .cont1 {
    order: 4; }
  main#mainContent.service section.intro1 .row .cont2 {
    order: 5; }
  main#mainContent.service section.intro1 .row .cont3 {
    order: 6; }
  @media (max-width: 767.98px) {
    main#mainContent.service section.intro1 .row .cont1 {
      order: 2; }
    main#mainContent.service section.intro1 .row .cont2 {
      order: 4; }
    main#mainContent.service section.intro1 .row .cont3 {
      order: 6; } }
  main#mainContent.service section.intro1 .point_num {
    display: block;
    text-align: center; }
    main#mainContent.service section.intro1 .point_num img {
      display: block;
      width: 48px;
      height: auto;
      margin: 0 auto; }
    main#mainContent.service section.intro1 .point_num span {
      font-size: 125%;
      display: block;
      margin: 1.25em 0;
      padding: 0;
      line-height: 1.4;
      color: #43a5d7; }
      @media (max-width: 1399.98px) {
        main#mainContent.service section.intro1 .point_num span {
          margin: 1.15em 0; } }
      @media (max-width: 991.98px) {
        main#mainContent.service section.intro1 .point_num span {
          margin: 1em 0;
          font-size: 15px; } }
      @media (max-width: 767.98px) {
        main#mainContent.service section.intro1 .point_num span {
          margin: 1.15em 0;
          font-size: 18px; } }
      @media (max-width: 575.98px) {
        main#mainContent.service section.intro1 .point_num span {
          margin: 1em 0;
          font-size: 16px; } }
  @media (max-width: 767.98px) {
    main#mainContent.service section.intro1 .point_cont {
      margin-bottom: 30px; } }
  @media (max-width: 575.98px) {
    main#mainContent.service section.intro1 .point_cont {
      margin-bottom: 30px; } }
  main#mainContent.service section.intro1 .point_cont .cont_in {
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
    display: block;
    margin: 0;
    background: #FFF;
    border-radius: 18px;
    overflow: hidden;
    height: 100%; }
    @media (max-width: 575.98px) {
      main#mainContent.service section.intro1 .point_cont .cont_in {
        border-radius: 14px; } }
    main#mainContent.service section.intro1 .point_cont .cont_in .txtwrap {
      margin-top: 0;
      display: block;
      padding: 0 2em 1.75em;
      font-size: 14px; }
      @media (max-width: 1399.98px) {
        main#mainContent.service section.intro1 .point_cont .cont_in .txtwrap {
          padding: 0 1.8em 1.75em; } }
      @media (max-width: 991.98px) {
        main#mainContent.service section.intro1 .point_cont .cont_in .txtwrap {
          padding: 1.6em 1.5em; } }
      @media (max-width: 767.98px) {
        main#mainContent.service section.intro1 .point_cont .cont_in .txtwrap {
          padding: 0 1.5em 1.375em; } }
      @media (max-width: 575.98px) {
        main#mainContent.service section.intro1 .point_cont .cont_in .txtwrap {
          padding: 0 1.4em 1.25em; } }
  main#mainContent.service section.intro1 .wrap > dl {
    width: 48.5%;
    padding: 1.2em;
    display: block;
    margin: 0 0 30px;
    background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06); }
    @media (max-width: 991.98px) {
      main#mainContent.service section.intro1 .wrap > dl {
        width: 100%; } }
    @media (max-width: 767.98px) {
      main#mainContent.service section.intro1 .wrap > dl {
        width: 100%; } }
    @media (max-width: 575.98px) {
      main#mainContent.service section.intro1 .wrap > dl {
        width: 100%; } }
    main#mainContent.service section.intro1 .wrap > dl dt {
      margin-bottom: 1.2em; }
      @media (max-width: 575.98px) {
        main#mainContent.service section.intro1 .wrap > dl dt {
          margin-bottom: 0; } }
      main#mainContent.service section.intro1 .wrap > dl dt .q::before {
        content: '';
        display: block;
        width: 44px;
        height: 44px;
        background: url(./img/intro/q.svg);
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
        margin-right: 13px;
        background-position: right; }
    main#mainContent.service section.intro1 .wrap > dl p {
      flex: 1;
      display: block;
      width: auto;
      margin: 0;
      background: #FFF; }
      @media (max-width: 991.98px) {
        main#mainContent.service section.intro1 .wrap > dl p {
          padding: 0.75em 1em;
          font-size: 15px; } }
      @media (max-width: 767.98px) {
        main#mainContent.service section.intro1 .wrap > dl p {
          padding: 0.75em 1em;
          font-size: 14px; } }
  main#mainContent.service section.intro1 .wrap dd .a::before {
    content: '';
    display: block;
    text-align: right;
    width: 44px;
    height: 44px;
    background: url(./img/intro/a.svg);
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: middle;
    margin-right: 13px; }
  main#mainContent.service section.intro1 .wrap p {
    flex: 1;
    display: block;
    width: auto;
    margin: 0;
    background: #FFF; }
    @media (max-width: 991.98px) {
      main#mainContent.service section.intro1 .wrap p {
        padding: 0.75em 1em;
        font-size: 15px; } }
    @media (max-width: 767.98px) {
      main#mainContent.service section.intro1 .wrap p {
        padding: 0.75em 1em;
        font-size: 14px; } }
  main#mainContent.service section.intro1 .flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  main#mainContent.service section.intro1 .arrow {
    width: 100px;
    margin: 0 auto;
    padding: 3em 0; }
    @media (max-width: 991.98px) {
      main#mainContent.service section.intro1 .arrow {
        padding: 2.5em 0; } }
    @media (max-width: 767.98px) {
      main#mainContent.service section.intro1 .arrow {
        padding: 2.5em 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.service section.intro1 .arrow {
        padding: 2em 0; } }
  main#mainContent.service section.intro1 .arrowwrap {
    display: block; }
    main#mainContent.service section.intro1 .arrowwrap p {
      text-align: center;
      color: white;
      font-size: 190%;
      font-weight: 600;
      background-color: #43a5d7;
      padding: 1em 0; }
      @media (max-width: 991.98px) {
        main#mainContent.service section.intro1 .arrowwrap p {
          font-size: 150%; } }
      @media (max-width: 767.98px) {
        main#mainContent.service section.intro1 .arrowwrap p {
          font-size: 130%; } }
      @media (max-width: 575.98px) {
        main#mainContent.service section.intro1 .arrowwrap p {
          font-size: 105%; } }
  main#mainContent.service section.intro1 .fsb {
    font-size: 130%; }

.anima {
  position: relative;
  overflow: hidden; }
  .anima:before {
    background: #43a2d7;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transform: translateX(-100%);
    transition: all .6s 0s ease;
    width: 100%;
    z-index: 1; }
  .anima img {
    display: block;
    position: relative;
    opacity: 0;
    transition: all .5s .3s ease;
    z-index: 0; }
  .anima.active img {
    opacity: 1; }
  .anima.active:before {
    transform: translateX(100%); }
    .anima.active:before img {
      opacity: 1; }

.fdu {
  opacity: 0; }
  .fdu.active {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

main#mainContent.under {
  background: #f5f7f8; }

.link a {
  position: relative;
  display: inline-block;
  transition: .3s;
  font-weight: 900;
  color: #43a5d7; }
  .link a::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 0;
    height: 1px;
    background-color: #43a5d7;
    transition: .3s; }
  .link a:hover::after {
    width: 100%; }

main#mainContent.under .page-head {
  height: 324px;
  background: #f5f7f8; }
  @media (max-width: 1399.98px) {
    main#mainContent.under .page-head {
      height: 300px; } }
  @media (max-width: 991.98px) {
    main#mainContent.under .page-head {
      height: 276px; } }
  @media (max-width: 767.98px) {
    main#mainContent.under .page-head {
      height: 242px; } }
  @media (max-width: 575.98px) {
    main#mainContent.under .page-head {
      height: 218px; } }
  main#mainContent.under .page-head .title {
    text-align: center;
    color: #333;
    font-size: 28px;
    font-weight: 900; }
    @media (max-width: 1399.98px) {
      main#mainContent.under .page-head .title {
        font-size: 27px; } }
    @media (max-width: 991.98px) {
      main#mainContent.under .page-head .title {
        font-size: 26px; } }
    @media (max-width: 767.98px) {
      main#mainContent.under .page-head .title {
        font-size: 24px; } }
    @media (max-width: 575.98px) {
      main#mainContent.under .page-head .title {
        font-size: 22px; } }
    main#mainContent.under .page-head .title small {
      display: block;
      margin: 2em 0 0;
      font-size: 12px;
      font-weight: 700; }
      @media (max-width: 767.98px) {
        main#mainContent.under .page-head .title small {
          font-size: 11px; } }
      @media (max-width: 575.98px) {
        main#mainContent.under .page-head .title small {
          font-size: 10px; } }
    main#mainContent.under .page-head .title:after {
      content: "";
      display: block;
      width: 1.25em;
      height: 5px;
      margin: 1.25em auto 0;
      padding: 0;
      background: #000; }

main#mainContent.under .breadcrumb {
  margin: 0;
  padding: 0;
  background: #FFF; }
  main#mainContent.under .breadcrumb ul {
    margin: 0;
    padding: 2.25em 0;
    list-style: none;
    font-size: 11px; }
    main#mainContent.under .breadcrumb ul li:not(:last-child) a {
      color: #959595; }
    main#mainContent.under .breadcrumb ul li:not(:last-child):after {
      display: inline-block;
      content: ">";
      width: 2em;
      text-align: center;
      color: #333; }
    main#mainContent.under .breadcrumb ul li:last-child a {
      color: #333;
      font-weight: 700; }
  @media (max-width: 767.98px) {
    main#mainContent.under .breadcrumb {
      display: none; } }

main#mainContent.under .underColumn {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
  padding: 45px 0 0; }
  main#mainContent.under .underColumn > .main {
    width: 800px; }
  main#mainContent.under .underColumn > .sub {
    width: 350px;
    position: sticky;
    top: 30px; }
  @media (max-width: 1399.98px) {
    main#mainContent.under .underColumn {
      padding: 40px 0 0; }
      main#mainContent.under .underColumn > .main {
        width: 600px; }
      main#mainContent.under .underColumn > .sub {
        width: 280px; } }
  @media (max-width: 991.98px) {
    main#mainContent.under .underColumn {
      padding: 35px 0 0;
      flex-direction: column; }
      main#mainContent.under .underColumn > .main {
        width: 100%; }
      main#mainContent.under .underColumn > .sub {
        width: 100%;
        margin-top: 50px; } }
  @media (max-width: 767.98px) {
    main#mainContent.under .underColumn {
      padding: 0; }
      main#mainContent.under .underColumn > .sub {
        margin-top: 45px; } }
  @media (max-width: 575.98px) {
    main#mainContent.under .underColumn > .sub {
      margin-top: 40px; } }

main#mainContent.under .underColumn .main .content {
  display: block;
  background: #FFF;
  border-radius: 18px 18px 0 0;
  overflow: hidden; }
  main#mainContent.under .underColumn .main .content .pad {
    padding: 55px 40px; }
  @media (max-width: 1399.98px) {
    main#mainContent.under .underColumn .main .content .pad {
      padding: 45px 36px; } }
  @media (max-width: 767.98px) {
    main#mainContent.under .underColumn .main .content .pad {
      padding: 35px 30px; } }
  @media (max-width: 575.98px) {
    main#mainContent.under .underColumn .main .content .pad {
      padding: 25px 15px; } }

main#mainContent.under .underColumn .main .head .title {
  color: #43a5d7;
  font-size: 24px; }
  @media (max-width: 991.98px) {
    main#mainContent.under .underColumn .main .head .title {
      font-size: 22px; } }
  @media (max-width: 767.98px) {
    main#mainContent.under .underColumn .main .head .title {
      font-size: 20px; } }

main#mainContent.under .underColumn .main .head .client {
  font-size: 16px; }
  @media (max-width: 991.98px) {
    main#mainContent.under .underColumn .main .head .client {
      font-size: 15px; } }

main#mainContent.under .underColumn .main .head .read {
  font-size: 13px; }
  @media (max-width: 991.98px) {
    main#mainContent.under .underColumn .main .head .read {
      font-size: 12px; } }

main#mainContent.under .underColumn .main .body {
  line-height: 2;
  font-size: 14px; }
  main#mainContent.under .underColumn .main .body h4 {
    display: block;
    margin: 2em 0 0;
    padding: 0.125em 0 0.125em 0.5em;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.6;
    border-left: #43a3d7 solid 7px; }
    @media (max-width: 991.98px) {
      main#mainContent.under .underColumn .main .body h4 {
        font-size: 22px;
        border-left-width: 6px; } }
    @media (max-width: 767.98px) {
      main#mainContent.under .underColumn .main .body h4 {
        font-size: 20px;
        border-left-width: 5px; } }
  main#mainContent.under .underColumn .main .body p {
    margin: 2em 0 0; }
    @media (max-width: 767.98px) {
      main#mainContent.under .underColumn .main .body p {
        margin: 1.8em 0 0; } }
  main#mainContent.under .underColumn .main .body figure.wp-block-image {
    margin: 2em 0 0; }
  main#mainContent.under .underColumn .main .body figure.wp-block-embed-youtube {
    margin: 2em 0 0; }
    main#mainContent.under .underColumn .main .body figure.wp-block-embed-youtube .wp-block-embed__wrapper {
      position: relative;
      width: 100%;
      padding-top: 56.25%; }
    main#mainContent.under .underColumn .main .body figure.wp-block-embed-youtube .wp-block-embed__wrapper iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%; }

main#mainContent.under .underColumn .main .foot {
  margin: 50px 0 0;
  /*
	.link {
		a {
			color: #1c75ad;
			font-weight: 700;
			text-decoration: underline;
		}
	}
	*/ }
  @media (max-width: 1399.98px) {
    main#mainContent.under .underColumn .main .foot {
      margin: 45px 0 0; } }
  @media (max-width: 767.98px) {
    main#mainContent.under .underColumn .main .foot {
      margin: 40px 0 0; } }
  main#mainContent.under .underColumn .main .foot .section-link {
    margin: 75px 0 0; }
    @media (max-width: 1399.98px) {
      main#mainContent.under .underColumn .main .foot .section-link {
        margin: 70px 0 0; } }
    @media (max-width: 991.98px) {
      main#mainContent.under .underColumn .main .foot .section-link {
        margin: 60px 0 0; } }
    @media (max-width: 767.98px) {
      main#mainContent.under .underColumn .main .foot .section-link {
        margin: 55px 0 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.under .underColumn .main .foot .section-link {
        margin: 50px 0 0; } }
    main#mainContent.under .underColumn .main .foot .section-link a {
      display: block;
      width: auto;
      max-width: 375px;
      height: auto;
      margin: 0 auto;
      padding: 1.5em 0;
      line-height: 1;
      text-align: center;
      color: #FFF;
      font-size: 18px;
      font-weight: 700;
      border-radius: 100px;
      background: linear-gradient(to right, #43a2d7 0%, #43b6d7 100%);
      position: relative;
      overflow: hidden;
      transition: all 0.3s 0.06s; }
      main#mainContent.under .underColumn .main .foot .section-link a > span {
        position: relative;
        z-index: 1; }
      main#mainContent.under .underColumn .main .foot .section-link a:before, main#mainContent.under .underColumn .main .foot .section-link a:after {
        content: "";
        display: block;
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 0;
        background: #FFF;
        opacity: 0;
        transition: all 0.3s; }
      main#mainContent.under .underColumn .main .foot .section-link a:before {
        transform: translateX(-110%) skew(-45deg);
        left: 0; }
      main#mainContent.under .underColumn .main .foot .section-link a:after {
        transform: translateX(110%) skew(-45deg);
        right: 0; }
      @media (max-width: 1399.98px) {
        main#mainContent.under .underColumn .main .foot .section-link a {
          font-size: 17px; } }
      @media (max-width: 991.98px) {
        main#mainContent.under .underColumn .main .foot .section-link a {
          font-size: 16px; } }
      @media (max-width: 767.98px) {
        main#mainContent.under .underColumn .main .foot .section-link a {
          font-size: 15px; } }
      @media (max-width: 575.98px) {
        main#mainContent.under .underColumn .main .foot .section-link a {
          font-size: 15px; } }
      @media (min-width: 768px) {
        main#mainContent.under .underColumn .main .foot .section-link a:hover {
          color: #43a5d7; }
          main#mainContent.under .underColumn .main .foot .section-link a:hover:before, main#mainContent.under .underColumn .main .foot .section-link a:hover:after {
            transform: translateX(0) skew(5deg);
            opacity: 0.77; } }

main#mainContent.under .underColumn .sub .base {
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0px 1px 15px 0 rgba(0, 0, 0, 0.08); }
  main#mainContent.under .underColumn .sub .base .pad {
    padding: 30px;
    font-size: 13px;
    /*
		.link {
			a {
				color: #1c75ad;
				font-weight: 700;
				text-decoration: underline;
			}
		}
		*/ }
    @media (max-width: 1399.98px) {
      main#mainContent.under .underColumn .sub .base .pad {
        padding: 22px; } }
    @media (max-width: 991.98px) {
      main#mainContent.under .underColumn .sub .base .pad {
        padding: 30px; } }
    @media (max-width: 767.98px) {
      main#mainContent.under .underColumn .sub .base .pad {
        padding: 26px; } }
    @media (max-width: 575.98px) {
      main#mainContent.under .underColumn .sub .base .pad {
        padding: 22px; } }
    main#mainContent.under .underColumn .sub .base .pad .info {
      display: table;
      table-layout: fixed;
      width: 100%; }
      main#mainContent.under .underColumn .sub .base .pad .info > dl {
        display: table-row;
        line-height: 1.4; }
        main#mainContent.under .underColumn .sub .base .pad .info > dl > dt {
          display: table-cell;
          width: 5.5em; }
        main#mainContent.under .underColumn .sub .base .pad .info > dl > dd {
          display: table-cell;
          padding: 0 0 1.25em 0.5em; }
    main#mainContent.under .underColumn .sub .base .pad .detail {
      margin: 2em 0 0;
      line-height: 1.8; }

main#mainContent.under .underColumn .sub .inquiry {
  margin: 40px 0 0; }
  @media (max-width: 1399.98px) {
    main#mainContent.under .underColumn .sub .inquiry {
      margin: 40px 0 0; } }
  @media (max-width: 991.98px) {
    main#mainContent.under .underColumn .sub .inquiry {
      margin: 40px 0 0; } }
  main#mainContent.under .underColumn .sub .inquiry a {
    display: block;
    margin: 0;
    padding: 1.75em 0;
    line-height: 1;
    text-align: center;
    color: #000;
    font-size: 15px;
    font-weight: 700;
    background: #ffcb3e;
    border-radius: 55px;
    position: relative;
    overflow: hidden;
    transition: color 0.16s 0.06s; }
    main#mainContent.under .underColumn .sub .inquiry a:after {
      content: "";
      display: block;
      width: 100%;
      height: 0;
      background: #43a5d7;
      position: absolute;
      top: 50%;
      left: 0;
      transform: rotate(-12deg);
      transition: all 0.16s; }
    main#mainContent.under .underColumn .sub .inquiry a span {
      position: relative;
      z-index: 1; }
    @media (min-width: 768px) {
      main#mainContent.under .underColumn .sub .inquiry a:hover {
        color: #FFF; }
        main#mainContent.under .underColumn .sub .inquiry a:hover:after {
          height: 100%;
          top: 0;
          transform: rotate(0); } }

main#mainContent.under section.case {
  padding: 80px 0;
  background: #f5f7f8; }
  @media (max-width: 1399.98px) {
    main#mainContent.under section.case {
      padding: 70px 0; } }
  @media (max-width: 991.98px) {
    main#mainContent.under section.case {
      padding: 60px 0 0 0; } }
  @media (max-width: 767.98px) {
    main#mainContent.under section.case {
      padding: 50px 0 0 0; } }
  @media (max-width: 575.98px) {
    main#mainContent.under section.case {
      padding: 40px 0 0 0; } }
  main#mainContent.under section.case .section-head {
    color: #333;
    font-size: 28px;
    font-weight: 900; }
    @media (max-width: 1399.98px) {
      main#mainContent.under section.case .section-head {
        font-size: 27px; } }
    @media (max-width: 991.98px) {
      main#mainContent.under section.case .section-head {
        font-size: 26px; } }
    @media (max-width: 767.98px) {
      main#mainContent.under section.case .section-head {
        font-size: 24px; } }
    @media (max-width: 575.98px) {
      main#mainContent.under section.case .section-head {
        font-size: 22px; } }
  main#mainContent.under section.case .row {
    margin-top: 50px; }
    @media (max-width: 1399.98px) {
      main#mainContent.under section.case .row {
        margin-top: 45px; } }
    @media (max-width: 991.98px) {
      main#mainContent.under section.case .row {
        margin-top: 40px; } }
    @media (max-width: 767.98px) {
      main#mainContent.under section.case .row {
        margin-top: 35px; } }
    @media (max-width: 575.98px) {
      main#mainContent.under section.case .row {
        margin-top: 30px; } }
  @media (max-width: 767.98px) {
    main#mainContent.under section.case .item:not(:first-child) {
      margin-top: 30px; } }
  @media (max-width: 575.98px) {
    main#mainContent.under section.case .item:not(:first-child) {
      margin-top: 25px; } }
  main#mainContent.under section.case .item > a {
    box-shadow: 0 0 8px #DDD;
    height: 100%;
    background: #FFF; }
    main#mainContent.under section.case .item > a dl {
      display: block;
      margin: 0;
      padding: 22px 24px; }
      @media (max-width: 1399.98px) {
        main#mainContent.under section.case .item > a dl {
          padding: 20px 22px; } }
      @media (max-width: 991.98px) {
        main#mainContent.under section.case .item > a dl {
          padding: 16px 20px; } }
      @media (max-width: 767.98px) {
        main#mainContent.under section.case .item > a dl {
          padding: 1em 1.5em; } }
      @media (max-width: 575.98px) {
        main#mainContent.under section.case .item > a dl {
          padding: 0.75em 1em; } }
      main#mainContent.under section.case .item > a dl > dt {
        color: #43a5d7; }
        @media (max-width: 1399.98px) {
          main#mainContent.under section.case .item > a dl > dt {
            font-size: 15px; } }
        @media (max-width: 767.98px) {
          main#mainContent.under section.case .item > a dl > dt {
            font-size: 14px; } }
        @media (max-width: 575.98px) {
          main#mainContent.under section.case .item > a dl > dt {
            font-size: 13px; } }
      main#mainContent.under section.case .item > a dl > dd {
        color: #333;
        font-size: 14px;
        font-weight: 900; }
        @media (max-width: 767.98px) {
          main#mainContent.under section.case .item > a dl > dd {
            font-size: 12px; } }
        @media (max-width: 575.98px) {
          main#mainContent.under section.case .item > a dl > dd {
            font-size: 11px; } }
    @media (min-width: 768px) {
      main#mainContent.under section.case .item > a {
        position: relative; }
        main#mainContent.under section.case .item > a:before, main#mainContent.under section.case .item > a:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          box-sizing: border-box;
          position: absolute;
          border-color: #43a5d7;
          border-style: solid;
          opacity: 0;
          transition: all 0.2s; }
        main#mainContent.under section.case .item > a:before {
          top: 0;
          left: 0;
          border-width: 6px 0 0 6px; }
        main#mainContent.under section.case .item > a:after {
          bottom: 0;
          right: 0;
          border-width: 0 6px 6px 0; }
        main#mainContent.under section.case .item > a:hover:before, main#mainContent.under section.case .item > a:hover:after {
          opacity: 1;
          width: 100%;
          height: 100%;
          transition: all 0.3s; } }
  main#mainContent.under section.case .section-link {
    display: block;
    width: auto;
    max-width: 530px;
    margin: 0 auto;
    padding: 80px 0 0; }
    @media (max-width: 1399.98px) {
      main#mainContent.under section.case .section-link {
        padding: 70px 0 0; } }
    @media (max-width: 991.98px) {
      main#mainContent.under section.case .section-link {
        padding: 60px 0 0; } }
    @media (max-width: 767.98px) {
      main#mainContent.under section.case .section-link {
        margin: 0 15px;
        padding: 50px 0 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.under section.case .section-link {
        padding: 40px 0 0; } }

main#mainContent.under section.case_list {
  padding: 80px 0;
  background: #f5f7f8; }
  @media (max-width: 1399.98px) {
    main#mainContent.under section.case_list {
      padding: 70px 0; } }
  @media (max-width: 991.98px) {
    main#mainContent.under section.case_list {
      padding: 60px 0; } }
  @media (max-width: 767.98px) {
    main#mainContent.under section.case_list {
      padding: 0; } }
  @media (max-width: 575.98px) {
    main#mainContent.under section.case_list {
      padding: 0; } }
  main#mainContent.under section.case_list .section-head {
    color: #333;
    font-size: 28px;
    font-weight: 900; }
    @media (max-width: 1399.98px) {
      main#mainContent.under section.case_list .section-head {
        font-size: 27px; } }
    @media (max-width: 991.98px) {
      main#mainContent.under section.case_list .section-head {
        font-size: 26px; } }
    @media (max-width: 767.98px) {
      main#mainContent.under section.case_list .section-head {
        font-size: 24px; } }
    @media (max-width: 575.98px) {
      main#mainContent.under section.case_list .section-head {
        font-size: 22px; } }
  @media (max-width: 1399.98px) {
    main#mainContent.under section.case_list .row {
      margin-top: 0; } }
  @media (max-width: 991.98px) {
    main#mainContent.under section.case_list .row {
      margin-top: 0; } }
  @media (max-width: 767.98px) {
    main#mainContent.under section.case_list .row {
      margin-top: 0; } }
  @media (max-width: 575.98px) {
    main#mainContent.under section.case_list .row {
      margin-top: 0; } }
  @media (max-width: 767.98px) {
    main#mainContent.under section.case_list .item:not(:first-child) {
      margin-top: 30px; } }
  @media (max-width: 575.98px) {
    main#mainContent.under section.case_list .item:not(:first-child) {
      margin-top: 0; } }
  main#mainContent.under section.case_list .item > a {
    box-shadow: 0 0 8px #DDD;
    height: 100%;
    background: #FFF; }
    main#mainContent.under section.case_list .item > a dl {
      display: block;
      margin: 0;
      padding: 22px 24px; }
      @media (max-width: 1399.98px) {
        main#mainContent.under section.case_list .item > a dl {
          padding: 20px 22px; } }
      @media (max-width: 991.98px) {
        main#mainContent.under section.case_list .item > a dl {
          padding: 16px 20px; } }
      @media (max-width: 767.98px) {
        main#mainContent.under section.case_list .item > a dl {
          padding: 1em 1.5em; } }
      @media (max-width: 575.98px) {
        main#mainContent.under section.case_list .item > a dl {
          padding: 0.75em 1em; } }
      main#mainContent.under section.case_list .item > a dl > dt {
        color: #43a5d7; }
        @media (max-width: 1399.98px) {
          main#mainContent.under section.case_list .item > a dl > dt {
            font-size: 15px; } }
        @media (max-width: 767.98px) {
          main#mainContent.under section.case_list .item > a dl > dt {
            font-size: 14px; } }
        @media (max-width: 575.98px) {
          main#mainContent.under section.case_list .item > a dl > dt {
            font-size: 13px; } }
      main#mainContent.under section.case_list .item > a dl > dd {
        color: #333;
        font-size: 14px;
        font-weight: 900; }
        @media (max-width: 767.98px) {
          main#mainContent.under section.case_list .item > a dl > dd {
            font-size: 12px; } }
        @media (max-width: 575.98px) {
          main#mainContent.under section.case_list .item > a dl > dd {
            font-size: 11px; } }
    @media (min-width: 768px) {
      main#mainContent.under section.case_list .item > a {
        position: relative; }
        main#mainContent.under section.case_list .item > a:before, main#mainContent.under section.case_list .item > a:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          box-sizing: border-box;
          position: absolute;
          border-color: #43a5d7;
          border-style: solid;
          opacity: 0;
          transition: all 0.2s; }
        main#mainContent.under section.case_list .item > a:before {
          top: 0;
          left: 0;
          border-width: 6px 0 0 6px; }
        main#mainContent.under section.case_list .item > a:after {
          bottom: 0;
          right: 0;
          border-width: 0 6px 6px 0; }
        main#mainContent.under section.case_list .item > a:hover:before, main#mainContent.under section.case_list .item > a:hover:after {
          opacity: 1;
          width: 100%;
          height: 100%;
          transition: all 0.3s; } }
  main#mainContent.under section.case_list .section-link {
    display: block;
    width: auto;
    max-width: 530px;
    margin: 0 auto;
    padding: 80px 0 0; }
    @media (max-width: 1399.98px) {
      main#mainContent.under section.case_list .section-link {
        padding: 70px 0 0; } }
    @media (max-width: 991.98px) {
      main#mainContent.under section.case_list .section-link {
        padding: 60px 0 0; } }
    @media (max-width: 767.98px) {
      main#mainContent.under section.case_list .section-link {
        margin: 0 15px;
        padding: 50px 0 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.under section.case_list .section-link {
        padding: 40px 0 0; } }

main#mainContent.under section.news_list {
  padding: 80px 0; }
  @media (max-width: 575.98px) {
    main#mainContent.under section.news_list {
      padding: 0; } }
  main#mainContent.under section.news_list .item a {
    background-color: #FFF;
    padding: 20px 30px;
    transition: background-color 0.5s; }
    main#mainContent.under section.news_list .item a:hover {
      background-color: #cfe7f4; }
    main#mainContent.under section.news_list .item a dl {
      margin: 0;
      display: flex; }
      @media (max-width: 991.98px) {
        main#mainContent.under section.news_list .item a dl {
          display: block; } }
      main#mainContent.under section.news_list .item a dl dt {
        width: 15%;
        color: #333; }
        @media (max-width: 1399.98px) {
          main#mainContent.under section.news_list .item a dl dt {
            width: 25%; } }
        @media (max-width: 991.98px) {
          main#mainContent.under section.news_list .item a dl dt {
            width: 100%;
            margin-bottom: 5px; } }
      main#mainContent.under section.news_list .item a dl dd {
        width: 85%; }
        @media (max-width: 1399.98px) {
          main#mainContent.under section.news_list .item a dl dd {
            width: 75%; } }
        @media (max-width: 991.98px) {
          main#mainContent.under section.news_list .item a dl dd {
            width: 100%; } }

main#mainContent.under section.news {
  max-width: 900px;
  margin: 0 auto;
  padding: 80px 0 0 0; }
  @media (max-width: 767.98px) {
    main#mainContent.under section.news {
      padding: 0; } }
  main#mainContent.under section.news article {
    background-color: #FFF;
    padding: 50px;
    line-height: 1.6; }
    @media (max-width: 991.98px) {
      main#mainContent.under section.news article {
        padding: 30px; } }
    main#mainContent.under section.news article figure {
      margin-bottom: 2em; }
      main#mainContent.under section.news article figure figcaption {
        font-size: 0.8em;
        margin-top: 1em;
        color: #555; }
    main#mainContent.under section.news article dl {
      margin-bottom: 20px; }
      main#mainContent.under section.news article dl dt {
        margin-bottom: 10px; }
      main#mainContent.under section.news article dl dd {
        font-size: 1.3em;
        color: #43a2d7;
        font-weight: 900; }
    main#mainContent.under section.news article h4 {
      font-weight: 900;
      font-size: 1.1em;
      margin-bottom: 1em;
      background: #f5f7f8;
      padding: 1em 1.5em; }
    main#mainContent.under section.news article p {
      margin-bottom: 2em; }
      main#mainContent.under section.news article p:last-child {
        margin: 0; }
    main#mainContent.under section.news article ul {
      margin-bottom: 2em; }
      main#mainContent.under section.news article ul.fw-5 {
        font-size: 1.2em; }

main#mainContent.under section.contact {
  padding: 80px 0 0 0; }
  @media (max-width: 767.98px) {
    main#mainContent.under section.contact {
      padding: 0; } }
  main#mainContent.under section.contact .container {
    max-width: 900px;
    background-color: #FFF;
    padding: 50px; }
    @media (max-width: 991.98px) {
      main#mainContent.under section.contact .container {
        padding: 30px; } }
    main#mainContent.under section.contact .container .cp {
      margin-bottom: 3em; }
    main#mainContent.under section.contact .container .inquiry_body {
      word-break: break-all; }
      main#mainContent.under section.contact .container .inquiry_body form .form_table {
        width: 100%; }
        main#mainContent.under section.contact .container .inquiry_body form .form_table .form1, main#mainContent.under section.contact .container .inquiry_body form .form_table .form2 {
          width: 100%; }

.table-row dt {
  margin-bottom: 0.5em; }
  .table-row dt span.required {
    font-size: 0.7em;
    background: #e6562f;
    border-radius: 5px;
    color: #FFF;
    margin-right: 10px;
    padding: 2px 5px; }

input[type="submit"],
input[type="text"],
select,
textarea,
button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: none; }

input[type="text"],
textarea {
  background: #f3f3f3;
  display: block;
  font-size: 16px;
  padding: 12px 15px;
  transition: 0.8s;
  border-radius: 0; }
  input[type="text"].error,
  textarea.error {
    background: #e7f6ff; }

input[type="text"]:focus,
textarea:focus {
  background: #e7f6ff; }
  input[type="text"]:focus.error,
  textarea:focus.error {
    background: #e7f6ff; }

textarea[name="content"] {
  display: inline-block;
  width: 100%;
  height: 200px; }

input::placeholder,
textarea::placeholder {
  color: #ccc; }

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1; }

::-moz-placeholder {
  color: #ccc;
  opacity: 1; }

:-ms-input-placeholder {
  color: #ccc;
  opacity: 1; }

.error {
  color: #cd1e1e;
  font-size: 0.9em; }

.contact-btn-wrap .btn {
  display: block;
  width: 100%;
  max-width: 530px;
  height: 4em;
  margin: 0 auto;
  padding: 0;
  line-height: 4;
  color: #FFF;
  font-size: 18px;
  font-weight: 700;
  background: #1f9cd6; }
  @media (max-width: 991.98px) {
    .contact-btn-wrap .btn {
      font-size: 16px; } }
  @media (max-width: 767.98px) {
    .contact-btn-wrap .btn {
      font-size: 14px; } }
  @media (max-width: 575.98px) {
    .contact-btn-wrap .btn {
      font-size: 13px; } }
  @media (min-width: 992px) {
    .contact-btn-wrap .btn:hover {
      background: #0e86bd;
      transition: all 0.2s; } }

.confirm-btn-wrap {
  justify-content: center;
  align-items: center; }
  @media (max-width: 767.98px) {
    .confirm-btn-wrap {
      flex-direction: column-reverse; } }
  .confirm-btn-wrap .btn-wrap {
    margin: 0 10px; }
    @media (max-width: 767.98px) {
      .confirm-btn-wrap .btn-wrap {
        margin: 0 auto; } }
  .confirm-btn-wrap .btn {
    display: block;
    color: #FFF; }
    .confirm-btn-wrap .btn.send {
      width: 280px;
      height: 4em;
      margin: 0 auto;
      padding: 0;
      line-height: 1;
      font-size: 18px;
      font-weight: 700;
      background: #1e9bd5; }
      @media (max-width: 991.98px) {
        .confirm-btn-wrap .btn.send {
          font-size: 16px; } }
      @media (max-width: 767.98px) {
        .confirm-btn-wrap .btn.send {
          font-size: 14px; } }
      @media (max-width: 575.98px) {
        .confirm-btn-wrap .btn.send {
          font-size: 13px; } }
      @media (min-width: 992px) {
        .confirm-btn-wrap .btn.send:hover {
          background: #0e8ac3;
          transition: all 0.2s; } }
    .confirm-btn-wrap .btn.back {
      height: 3em;
      width: 180px;
      margin: 1em auto;
      line-height: 1;
      font-size: 85%;
      background: #949494; }
      @media (min-width: 992px) {
        .confirm-btn-wrap .btn.back:hover {
          background: #505050;
          transition: all 0.2s; } }

.confirm dl dd {
  background: #EEE;
  padding: 1em; }

section.privacy {
  padding: 80px 0 0 0; }
  @media (max-width: 575.98px) {
    section.privacy {
      padding: 0; } }
  section.privacy .container {
    background-color: #FFF;
    padding: 50px; }
    section.privacy .container .cp {
      margin-bottom: 2em; }
    section.privacy .container dl {
      margin-bottom: 2em; }
      section.privacy .container dl dt {
        margin-bottom: 1em; }
    section.privacy .container .p_area h3 {
      font-size: 1em; }

section.company {
  padding: 80px 0 0 0; }
  @media (max-width: 575.98px) {
    section.company {
      padding: 0; } }
  section.company .container {
    background-color: #FFF;
    padding: 30px; }
    section.company .container .content {
      display: table;
      width: 100%;
      max-width: 100%;
      font-size: .9em; }
      section.company .container .content dl {
        display: table-row;
        line-height: 1.6; }
        section.company .container .content dl:last-child dt, section.company .container .content dl:last-child dd {
          border: none; }
        section.company .container .content dl dt {
          display: table-cell;
          padding: 1.5em;
          width: 15em;
          font-weight: 900;
          border-bottom: 1px solid #3ea3d8; }
          @media (max-width: 991.98px) {
            section.company .container .content dl dt {
              width: 15em; } }
          @media (max-width: 767.98px) {
            section.company .container .content dl dt {
              width: 8em; } }
          @media (max-width: 575.98px) {
            section.company .container .content dl dt {
              width: 6em; } }
        section.company .container .content dl dd {
          display: table-cell;
          padding: 1.5em;
          border-bottom: 1px solid #ccc; }
          section.company .container .content dl dd ul {
            padding: 0; }
            section.company .container .content dl dd ul li {
              list-style-type: none; }
          section.company .container .content dl dd div {
            margin-bottom: 0.5em; }

main#mainContent.under {
  padding-bottom: 120px; }
  @media (max-width: 767.98px) {
    main#mainContent.under {
      padding-bottom: 60px; } }
  main#mainContent.under div.about {
    margin-top: 80px;
    padding: 120px 0 100px;
    background: #FFF; }
    @media (max-width: 1399.98px) {
      main#mainContent.under div.about {
        padding: 80px 0 60px; } }
    @media (max-width: 991.98px) {
      main#mainContent.under div.about {
        padding: 60px 0 20px; } }
    @media (max-width: 767.98px) {
      main#mainContent.under div.about {
        padding: 60px 0 20px;
        margin-top: 0; } }
    main#mainContent.under div.about > .content {
      width: auto;
      max-width: 1000px;
      margin: 0 auto; }
      @media (max-width: 1399.98px) {
        main#mainContent.under div.about > .content {
          max-width: 800px; } }
      @media (max-width: 991.98px) {
        main#mainContent.under div.about > .content {
          max-width: 620px; } }
      @media (max-width: 767.98px) {
        main#mainContent.under div.about > .content {
          max-width: 460px; } }
      @media (max-width: 575.98px) {
        main#mainContent.under div.about > .content {
          max-width: 100%;
          margin: 0 15px; } }
      main#mainContent.under div.about > .content .kv {
        margin-bottom: 3em; }
      main#mainContent.under div.about > .content h2 {
        font-size: 1.5em;
        line-height: 2;
        font-family: TsukuARdGothicStd-E;
        letter-spacing: .1em; }
        @media (max-width: 575.98px) {
          main#mainContent.under div.about > .content h2 {
            font-size: 1.2em; } }
        main#mainContent.under div.about > .content h2 p {
          margin-bottom: 2em; }
        @media (max-width: 575.98px) {
          main#mainContent.under div.about > .content h2 br {
            display: none; } }

main#mainContent.under .farmer {
  margin-top: 80px; }
  @media (max-width: 767.98px) {
    main#mainContent.under .farmer {
      margin: 0; } }
  @media (max-width: 575.98px) {
    main#mainContent.under .farmer {
      margin: 0; } }
  main#mainContent.under .farmer .content .inner {
    background-color: #fff;
    padding: 40px; }
    @media (max-width: 767.98px) {
      main#mainContent.under .farmer .content .inner {
        padding: 20px; } }
    main#mainContent.under .farmer .content .inner .subtitle {
      margin-top: 40px;
      font-weight: 900;
      text-align: center;
      font-size: 30px;
      margin-bottom: 20px; }
      @media (max-width: 991.98px) {
        main#mainContent.under .farmer .content .inner .subtitle {
          margin-top: 20px;
          font-size: 20px; } }
    main#mainContent.under .farmer .content .inner .subdesc {
      margin: 0 auto;
      max-width: 780px; }
    main#mainContent.under .farmer .content .inner .point {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap; }
      main#mainContent.under .farmer .content .inner .point .single {
        width: 25%;
        padding: 0 20px; }
        @media (max-width: 1399.98px) {
          main#mainContent.under .farmer .content .inner .point .single {
            width: 50%; } }
        @media (max-width: 991.98px) {
          main#mainContent.under .farmer .content .inner .point .single {
            padding: 10px; } }
        main#mainContent.under .farmer .content .inner .point .single dl {
          margin-top: 10px; }
          main#mainContent.under .farmer .content .inner .point .single dl dt {
            margin-bottom: 5px;
            font-size: 18px; }
            @media (max-width: 991.98px) {
              main#mainContent.under .farmer .content .inner .point .single dl dt {
                font-size: 16px; } }
    main#mainContent.under .farmer .content .inner .subtitle2 {
      margin-top: 40px;
      font-weight: 900;
      text-align: center;
      font-size: 40px;
      margin-bottom: 20px;
      color: #43a2d7; }
      @media (max-width: 767.98px) {
        main#mainContent.under .farmer .content .inner .subtitle2 br {
          display: none; } }
      @media (max-width: 767.98px) {
        main#mainContent.under .farmer .content .inner .subtitle2 {
          margin-top: 20px;
          font-size: 22px; } }

main#mainContent.under .farmer .content .inner .merit {
  margin-top: 40px; }
  main#mainContent.under .farmer .content .inner .merit .merit_point {
    max-width: 1000px;
    margin: 40px auto 0 auto; }
    main#mainContent.under .farmer .content .inner .merit .merit_point .single {
      display: flex;
      border: 1px solid #ddd;
      margin-bottom: 20px; }
      @media (max-width: 767.98px) {
        main#mainContent.under .farmer .content .inner .merit .merit_point .single {
          display: block; } }
      main#mainContent.under .farmer .content .inner .merit .merit_point .single p {
        width: 600px; }
      main#mainContent.under .farmer .content .inner .merit .merit_point .single dl {
        padding: 40px 40px 0 40px;
        margin: 0; }
        @media (max-width: 1399.98px) {
          main#mainContent.under .farmer .content .inner .merit .merit_point .single dl {
            padding: 20px 20px 0 20px; } }
        @media (max-width: 767.98px) {
          main#mainContent.under .farmer .content .inner .merit .merit_point .single dl {
            padding: 20px; } }
        main#mainContent.under .farmer .content .inner .merit .merit_point .single dl dt {
          font-size: 28px;
          font-weight: 900;
          margin-bottom: 5px;
          color: #43a2d7; }
          @media (max-width: 1399.98px) {
            main#mainContent.under .farmer .content .inner .merit .merit_point .single dl dt {
              font-size: 20px; } }
  main#mainContent.under .farmer .content .inner .merit .check {
    max-width: 1000px;
    margin: 40px auto 0 auto;
    padding: 30px;
    border: 10px solid #c6e2f1;
    border-radius: 50px;
    font-weight: 900;
    text-align: center;
    font-size: 28px;
    color: #43a2d7; }
    @media (max-width: 1399.98px) {
      main#mainContent.under .farmer .content .inner .merit .check br {
        display: none; } }
    @media (max-width: 1399.98px) {
      main#mainContent.under .farmer .content .inner .merit .check {
        font-size: 20px; } }

main#mainContent.under .support {
  max-width: 1000px;
  margin: 60px auto 0 auto;
  background: #FFF;
  padding: 100px 0 60px; }
  @media (max-width: 991.98px) {
    main#mainContent.under .support {
      padding: 70px 0 50px; } }
  @media (max-width: 767.98px) {
    main#mainContent.under .support {
      padding: 50px 0 40px;
      margin: 20px auto 0 auto; } }
  @media (max-width: 575.98px) {
    main#mainContent.under .support {
      padding: 40px 0 30px; } }
  main#mainContent.under .support .section-head {
    display: inline-block;
    margin: 0 auto 1em;
    padding: 0 0 0.5em;
    text-align: center;
    color: #474343;
    font-size: 32px;
    font-weight: 900;
    position: relative;
    text-decoration: underline; }
  main#mainContent.under .support .substance {
    margin: 100px 0 0; }
    @media (max-width: 1399.98px) {
      main#mainContent.under .support .substance {
        margin: 100px 0 0; } }
    @media (max-width: 991.98px) {
      main#mainContent.under .support .substance {
        margin: 80px 0 0; } }
    @media (max-width: 767.98px) {
      main#mainContent.under .support .substance {
        margin: 60px 0 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.under .support .substance {
        margin: 50px 0 0; } }
    main#mainContent.under .support .substance > dl {
      display: block; }
      main#mainContent.under .support .substance > dl:first-child {
        margin: 0 0 45px; }
        @media (max-width: 991.98px) {
          main#mainContent.under .support .substance > dl:first-child {
            margin: 0 0 40px; } }
      main#mainContent.under .support .substance > dl > dt {
        display: block;
        padding: 0.75em 0;
        width: 340px;
        text-align: center;
        color: #FFF;
        font-size: 16px;
        background: #0959a7; }
        @media (max-width: 1399.98px) {
          main#mainContent.under .support .substance > dl > dt {
            width: 320px;
            font-size: 16px; } }
        @media (max-width: 991.98px) {
          main#mainContent.under .support .substance > dl > dt {
            padding: 0.8em 0;
            width: 320px;
            font-size: 15px; } }
        @media (max-width: 767.98px) {
          main#mainContent.under .support .substance > dl > dt {
            width: 100%; } }
      main#mainContent.under .support .substance > dl > dd {
        display: block;
        margin: 0;
        padding: 30px 40px;
        font-size: 20px;
        background: #f5f7f8; }
        main#mainContent.under .support .substance > dl > dd span.red {
          color: #eb1818; }
        @media (max-width: 1399.98px) {
          main#mainContent.under .support .substance > dl > dd {
            padding: 28px 38px;
            font-size: 19px; } }
        @media (max-width: 991.98px) {
          main#mainContent.under .support .substance > dl > dd {
            padding: 22px 34px;
            font-size: 18px; } }
        @media (max-width: 767.98px) {
          main#mainContent.under .support .substance > dl > dd {
            padding: 18px 30px;
            font-size: 17px; } }
        @media (max-width: 575.98px) {
          main#mainContent.under .support .substance > dl > dd {
            padding: 1em 24px;
            font-size: 16px; } }
  main#mainContent.under .support .schedule {
    margin: 50px 0 0 0; }
    main#mainContent.under .support .schedule .bighead {
      margin: 0 0 1em;
      color: #0959a7;
      font-size: 30px; }
      @media (max-width: 1399.98px) {
        main#mainContent.under .support .schedule .bighead {
          font-size: 28px; } }
      @media (max-width: 991.98px) {
        main#mainContent.under .support .schedule .bighead {
          font-size: 26px; } }
      @media (max-width: 767.98px) {
        main#mainContent.under .support .schedule .bighead {
          font-size: 22px; } }
      @media (max-width: 575.98px) {
        main#mainContent.under .support .schedule .bighead {
          font-size: 18px; } }
    main#mainContent.under .support .schedule .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      main#mainContent.under .support .schedule .wrap .column {
        display: block;
        width: calc(50% - 5px); }
        main#mainContent.under .support .schedule .wrap .column .columnin {
          display: block;
          height: 100%;
          background: #f5f7f8; }
        @media (max-width: 767.98px) {
          main#mainContent.under .support .schedule .wrap .column {
            width: 100%; }
            main#mainContent.under .support .schedule .wrap .column:last-child {
              margin-top: 35px; } }
    main#mainContent.under .support .schedule .head {
      padding: 0.75em 0;
      text-align: center;
      color: #FFF;
      font-size: 22px;
      background: #378adb; }
      @media (max-width: 1399.98px) {
        main#mainContent.under .support .schedule .head {
          padding: 0.8em 0;
          font-size: 20px; } }
      @media (max-width: 991.98px) {
        main#mainContent.under .support .schedule .head {
          padding: 0.85em 0;
          font-size: 18px; } }
      @media (max-width: 767.98px) {
        main#mainContent.under .support .schedule .head {
          padding: 0.9em 0;
          font-size: 17px; } }
      @media (max-width: 575.98px) {
        main#mainContent.under .support .schedule .head {
          padding: 0.95em 0;
          font-size: 16px; } }
    main#mainContent.under .support .schedule ul.step {
      display: block;
      margin: 30px 0;
      padding: 0 30px;
      list-style: none;
      position: relative; }
      main#mainContent.under .support .schedule ul.step:after {
        content: "";
        display: block;
        width: 20px;
        height: 100%;
        position: absolute;
        top: 0;
        left: calc(50% - 10px);
        z-index: 0; }
      @media (max-width: 1399.98px) {
        main#mainContent.under .support .schedule ul.step {
          margin: 28px 0;
          padding: 0 28px; }
          main#mainContent.under .support .schedule ul.step:after {
            width: 18px;
            left: calc(50% - 9px); } }
      @media (max-width: 991.98px) {
        main#mainContent.under .support .schedule ul.step {
          margin: 26px 0;
          padding: 0 26px; }
          main#mainContent.under .support .schedule ul.step:after {
            width: 16px;
            left: calc(50% - 6px); } }
      @media (max-width: 767.98px) {
        main#mainContent.under .support .schedule ul.step {
          margin: 20px 0;
          padding: 0 20px; }
          main#mainContent.under .support .schedule ul.step:after {
            width: 14px;
            left: calc(50% - 7px); } }
      @media (max-width: 575.98px) {
        main#mainContent.under .support .schedule ul.step {
          margin: 16px 0;
          padding: 0 16px; } }
      main#mainContent.under .support .schedule ul.step.gr:after {
        background: #d1f7a0; }
      main#mainContent.under .support .schedule ul.step.gr > li:not(.diff) {
        background: #8fd830; }
      main#mainContent.under .support .schedule ul.step.og:after {
        background: #ffe199; }
      main#mainContent.under .support .schedule ul.step.og > li:not(.diff) {
        background: #efb11d; }
      main#mainContent.under .support .schedule ul.step li:not(.diff) {
        position: relative;
        z-index: 1;
        display: block;
        width: 100%;
        padding: 0.8em 0;
        text-align: center;
        line-height: 1;
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        border-radius: 50px; }
        @media (max-width: 1399.98px) {
          main#mainContent.under .support .schedule ul.step li:not(.diff) {
            font-size: 17px; } }
        @media (max-width: 991.98px) {
          main#mainContent.under .support .schedule ul.step li:not(.diff) {
            padding: 1em 0;
            font-size: 14px; } }
        @media (max-width: 767.98px) {
          main#mainContent.under .support .schedule ul.step li:not(.diff) {
            padding: 1em 0;
            font-size: 16px; } }
        @media (max-width: 575.98px) {
          main#mainContent.under .support .schedule ul.step li:not(.diff) {
            font-size: 13px; } }
      main#mainContent.under .support .schedule ul.step li.diff {
        position: relative;
        z-index: 1;
        margin: 26px auto; }
        main#mainContent.under .support .schedule ul.step li.diff p {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 160px;
          line-height: 1;
          text-align: center;
          margin: 0 auto;
          padding: 0.25em 0;
          color: #e11414;
          font-size: 26px;
          font-weight: 900;
          background: #FFF;
          border: #df4c6b solid 2px;
          border-radius: 3px; }
          main#mainContent.under .support .schedule ul.step li.diff p small {
            font-size: 16px;
            font-weight: 900; }
        @media (max-width: 1399.98px) {
          main#mainContent.under .support .schedule ul.step li.diff {
            margin: 24px auto; }
            main#mainContent.under .support .schedule ul.step li.diff p {
              width: 140px;
              padding: 0.2em 0;
              font-size: 22px; }
              main#mainContent.under .support .schedule ul.step li.diff p small {
                font-size: 14px; } }
        @media (max-width: 991.98px) {
          main#mainContent.under .support .schedule ul.step li.diff {
            margin: 22px auto; }
            main#mainContent.under .support .schedule ul.step li.diff p {
              width: 120px;
              padding: 0.3em 0;
              font-size: 18px; }
              main#mainContent.under .support .schedule ul.step li.diff p small {
                font-size: 13px; } }
        @media (max-width: 767.98px) {
          main#mainContent.under .support .schedule ul.step li.diff {
            margin: 20px auto; } }
        @media (max-width: 575.98px) {
          main#mainContent.under .support .schedule ul.step li.diff {
            margin: 18px auto; } }

main#mainContent.under .farmer .contact_up {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px; }
  @media (max-width: 991.98px) {
    main#mainContent.under .farmer .contact_up {
      font-size: 16px; } }

main#mainContent.under .farmer .contact_sec {
  display: flex;
  margin-bottom: 40px; }
  @media (max-width: 991.98px) {
    main#mainContent.under .farmer .contact_sec {
      display: block; } }
  main#mainContent.under .farmer .contact_sec div {
    width: 50%;
    padding: 0 20px;
    text-align: center; }
    @media (max-width: 991.98px) {
      main#mainContent.under .farmer .contact_sec div {
        width: 100%; } }
    main#mainContent.under .farmer .contact_sec div a {
      display: block;
      width: 100%;
      text-align: center;
      padding: 1.25em 0;
      border-radius: 55px;
      font-weight: 900; }
      main#mainContent.under .farmer .contact_sec div a.tel {
        background-color: #43a2d7;
        color: #FFF; }
        @media (max-width: 991.98px) {
          main#mainContent.under .farmer .contact_sec div a.tel {
            display: block;
            margin-bottom: 20px; } }
      main#mainContent.under .farmer .contact_sec div a.form {
        background-color: #ffcb3e;
        color: #000; }
        @media (max-width: 991.98px) {
          main#mainContent.under .farmer .contact_sec div a.form {
            display: block;
            margin-bottom: 20px; } }
      main#mainContent.under .farmer .contact_sec div a:hover {
        opacity: 0.6; }

main#mainContent.service {
  background: #f5f7f8;
  padding-bottom: 120px; }
  @media (max-width: 1399.98px) {
    main#mainContent.service {
      padding-bottom: 100px; } }
  @media (max-width: 991.98px) {
    main#mainContent.service {
      padding-bottom: 80px; } }
  @media (max-width: 767.98px) {
    main#mainContent.service {
      padding-bottom: 70px; } }
  @media (max-width: 575.98px) {
    main#mainContent.service {
      padding-bottom: 60px; } }
  main#mainContent.service .servicecontent {
    margin-top: 60px; }
    @media (max-width: 1399.98px) {
      main#mainContent.service .servicecontent {
        margin-top: 50px; } }
    @media (max-width: 991.98px) {
      main#mainContent.service .servicecontent {
        margin-top: 40px; } }
    @media (max-width: 767.98px) {
      main#mainContent.service .servicecontent {
        margin-top: 0; } }
  main#mainContent.service span.blue {
    color: #0959a7 !important; }
  main#mainContent.service span.light {
    color: #43a3d8 !important; }
  main#mainContent.service span.yellow {
    color: #ebc721 !important; }
  main#mainContent.service span.uline {
    text-decoration: underline;
    text-decoration-color: #43a5d7; }

main#mainContent.service .page-head {
  height: 324px;
  background: #f5f7f8; }
  @media (max-width: 1399.98px) {
    main#mainContent.service .page-head {
      height: 300px; } }
  @media (max-width: 991.98px) {
    main#mainContent.service .page-head {
      height: 276px; } }
  @media (max-width: 767.98px) {
    main#mainContent.service .page-head {
      height: 242px; } }
  @media (max-width: 575.98px) {
    main#mainContent.service .page-head {
      height: 218px; } }
  main#mainContent.service .page-head .title {
    text-align: center;
    color: #333;
    font-size: 28px;
    font-weight: 900; }
    @media (max-width: 1399.98px) {
      main#mainContent.service .page-head .title {
        font-size: 27px; } }
    @media (max-width: 991.98px) {
      main#mainContent.service .page-head .title {
        font-size: 26px; } }
    @media (max-width: 767.98px) {
      main#mainContent.service .page-head .title {
        font-size: 24px; } }
    @media (max-width: 575.98px) {
      main#mainContent.service .page-head .title {
        font-size: 22px; } }
    main#mainContent.service .page-head .title small {
      display: block;
      margin: 2em 0 0;
      font-size: 12px;
      font-weight: 700; }
      @media (max-width: 767.98px) {
        main#mainContent.service .page-head .title small {
          font-size: 11px; } }
      @media (max-width: 575.98px) {
        main#mainContent.service .page-head .title small {
          font-size: 10px; } }
    main#mainContent.service .page-head .title:after {
      content: "";
      display: block;
      width: 1.25em;
      height: 5px;
      margin: 1.25em auto 0;
      padding: 0;
      background: #000; }

main#mainContent.service .breadcrumb {
  margin: 0;
  padding: 0;
  background: #FFF; }
  main#mainContent.service .breadcrumb ul {
    margin: 0;
    padding: 2.25em 0;
    list-style: none;
    font-size: 11px; }
    main#mainContent.service .breadcrumb ul li:not(:last-child) a {
      color: #959595; }
    main#mainContent.service .breadcrumb ul li:not(:last-child):after {
      display: inline-block;
      content: ">";
      width: 2em;
      text-align: center;
      color: #333; }
    main#mainContent.service .breadcrumb ul li:last-child a {
      color: #333;
      font-weight: 700; }
  @media (max-width: 767.98px) {
    main#mainContent.service .breadcrumb {
      display: none; } }

main#mainContent.service section > .content {
  width: auto;
  max-width: 1000px;
  margin: 0 auto; }
  @media (max-width: 1399.98px) {
    main#mainContent.service section > .content {
      max-width: 800px; } }
  @media (max-width: 991.98px) {
    main#mainContent.service section > .content {
      max-width: 620px; } }
  @media (max-width: 767.98px) {
    main#mainContent.service section > .content {
      max-width: 460px; } }
  @media (max-width: 575.98px) {
    main#mainContent.service section > .content {
      max-width: 100%;
      margin: 0 15px; } }

main#mainContent.service section > .content .section-head {
  display: inline-block;
  margin: 0 auto 2em;
  padding: 0 0 0.5em;
  text-align: center;
  color: #474343;
  font-size: 32px;
  font-weight: 900;
  position: relative; }
  main#mainContent.service section > .content .section-head:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #43a5d7; }
  @media (max-width: 1399.98px) {
    main#mainContent.service section > .content .section-head {
      font-size: 27px; } }
  @media (max-width: 991.98px) {
    main#mainContent.service section > .content .section-head {
      font-size: 26px; } }
  @media (max-width: 767.98px) {
    main#mainContent.service section > .content .section-head {
      font-size: 24px; } }
  @media (max-width: 575.98px) {
    main#mainContent.service section > .content .section-head {
      font-size: 22px; } }

main#mainContent.service .intro {
  padding: 120px 0 100px;
  background: #FFF; }
  @media (max-width: 1399.98px) {
    main#mainContent.service .intro {
      padding: 100px 0 60px; } }
  @media (max-width: 991.98px) {
    main#mainContent.service .intro {
      padding: 70px 0 50px; } }
  @media (max-width: 767.98px) {
    main#mainContent.service .intro {
      padding: 50px 0 40px; } }
  @media (max-width: 575.98px) {
    main#mainContent.service .intro {
      padding: 40px 0 30px; } }
  main#mainContent.service .intro .readtxt {
    margin: 0;
    padding: 1.25em;
    background: #f5f7f8;
    border-radius: 0.5em; }
    main#mainContent.service .intro .readtxt p {
      font-size: 14px; }
      @media (max-width: 767.98px) {
        main#mainContent.service .intro .readtxt p {
          font-size: 13px; } }
  main#mainContent.service .intro .shortagewrap {
    margin: 60px 0 0; }
    @media (max-width: 1399.98px) {
      main#mainContent.service .intro .shortagewrap {
        margin: 60px 0 0; } }
    @media (max-width: 991.98px) {
      main#mainContent.service .intro .shortagewrap {
        margin: 45px 0 0; } }
    @media (max-width: 767.98px) {
      main#mainContent.service .intro .shortagewrap {
        margin: 35px 0 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.service .intro .shortagewrap {
        margin: 30px 0 0; } }
    main#mainContent.service .intro .shortagewrap > .head {
      margin: 0;
      padding: 0 0 1.25em;
      font-size: 28px; }
      @media (max-width: 1399.98px) {
        main#mainContent.service .intro .shortagewrap > .head {
          padding: 0 0 1.25em;
          font-size: 26px; } }
      @media (max-width: 991.98px) {
        main#mainContent.service .intro .shortagewrap > .head {
          padding: 0 0 1em;
          font-size: 24px; } }
      @media (max-width: 767.98px) {
        main#mainContent.service .intro .shortagewrap > .head {
          padding: 0 0 1em;
          font-size: 20px; } }
      @media (max-width: 575.98px) {
        main#mainContent.service .intro .shortagewrap > .head {
          padding: 0 0 0.75em;
          font-size: 16px; } }
      main#mainContent.service .intro .shortagewrap > .head span.red {
        color: #e05555; }
  main#mainContent.service .intro .shortagewrap + p {
    text-align: right; }
    @media (max-width: 767.98px) {
      main#mainContent.service .intro .shortagewrap + p {
        text-align: left;
        font-size: 90%; } }
    @media (max-width: 575.98px) {
      main#mainContent.service .intro .shortagewrap + p {
        font-size: 80%; } }
  main#mainContent.service .intro .txtwrap {
    margin: 40px 0 0;
    font-size: 14px; }
    @media (max-width: 767.98px) {
      main#mainContent.service .intro .txtwrap {
        margin: 2em 0 0;
        font-size: 13px; } }
    @media (max-width: 575.98px) {
      main#mainContent.service .intro .txtwrap {
        margin: 1.5em 0 0; } }
  main#mainContent.service .intro .strongwrap {
    margin: 50px 0 0; }
    main#mainContent.service .intro .strongwrap .txt1 {
      margin: 0;
      padding: 0;
      font-size: 22px; }
      main#mainContent.service .intro .strongwrap .txt1 span.red {
        color: #e05555; }
    main#mainContent.service .intro .strongwrap .txt2 {
      margin: 0.75em 0 0;
      padding: 0;
      font-size: 28px; }
    main#mainContent.service .intro .strongwrap .txt3 {
      margin: 0.25em 0 0;
      padding: 0;
      font-size: 36px; }
    main#mainContent.service .intro .strongwrap .txt1 br {
      display: none; }
    main#mainContent.service .intro .strongwrap .txt3 br {
      display: none; }
    @media (max-width: 1399.98px) {
      main#mainContent.service .intro .strongwrap {
        margin: 40px 0 0; }
        main#mainContent.service .intro .strongwrap .txt1 br {
          display: block; }
        main#mainContent.service .intro .strongwrap .txt3 br {
          display: block; } }
    @media (max-width: 991.98px) {
      main#mainContent.service .intro .strongwrap {
        margin: 35px 0 0; }
        main#mainContent.service .intro .strongwrap .txt1 {
          font-size: 20px; }
        main#mainContent.service .intro .strongwrap .txt2 {
          font-size: 26px; }
        main#mainContent.service .intro .strongwrap .txt3 {
          font-size: 30px; } }
    @media (max-width: 767.98px) {
      main#mainContent.service .intro .strongwrap .txt3 br {
        display: none; } }
    @media (max-width: 575.98px) {
      main#mainContent.service .intro .strongwrap .txt1 {
        font-size: 3.6vw; }
      main#mainContent.service .intro .strongwrap .txt2 {
        font-size: 5vw; }
      main#mainContent.service .intro .strongwrap .txt3 {
        font-size: 5.5vw; }
      main#mainContent.service .intro .strongwrap .txt1 br {
        display: block; }
      main#mainContent.service .intro .strongwrap .txt3 br {
        display: none; } }
  main#mainContent.service .intro .graphwrap .fullwrap {
    margin: 80px 0 0; }
    @media (max-width: 1399.98px) {
      main#mainContent.service .intro .graphwrap .fullwrap {
        margin: 60px 0 0; } }
    @media (max-width: 991.98px) {
      main#mainContent.service .intro .graphwrap .fullwrap {
        margin: 55px 0 0; } }
    @media (max-width: 767.98px) {
      main#mainContent.service .intro .graphwrap .fullwrap {
        margin: 50px 0 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.service .intro .graphwrap .fullwrap {
        margin: 40px 0 0; } }
    main#mainContent.service .intro .graphwrap .fullwrap > .head {
      margin: 0;
      padding: 0 0 1em;
      font-size: 28px; }
      @media (max-width: 1399.98px) {
        main#mainContent.service .intro .graphwrap .fullwrap > .head {
          padding: 0 0 1em;
          font-size: 26px; } }
      @media (max-width: 991.98px) {
        main#mainContent.service .intro .graphwrap .fullwrap > .head {
          padding: 0 0 0.9em;
          font-size: 24px; } }
      @media (max-width: 767.98px) {
        main#mainContent.service .intro .graphwrap .fullwrap > .head {
          padding: 0 0 0.8em;
          font-size: 20px; } }
      @media (max-width: 575.98px) {
        main#mainContent.service .intro .graphwrap .fullwrap > .head {
          padding: 0 0 0.75em;
          font-size: 16px; } }
  main#mainContent.service .intro .graphwrap .columnwrap {
    margin: 65px 0 0;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    main#mainContent.service .intro .graphwrap .columnwrap > .head {
      width: 50%;
      padding: 0 0 1.5em;
      font-size: 18px; }
    main#mainContent.service .intro .graphwrap .columnwrap > .graph {
      width: 50%; }
    @media (max-width: 1399.98px) {
      main#mainContent.service .intro .graphwrap .columnwrap {
        margin: 60px 0 0; }
        main#mainContent.service .intro .graphwrap .columnwrap > .head {
          font-size: 16px; } }
    @media (max-width: 991.98px) {
      main#mainContent.service .intro .graphwrap .columnwrap {
        margin: 55px 0 0; }
        main#mainContent.service .intro .graphwrap .columnwrap > .head {
          font-size: 14px; } }
    @media (max-width: 767.98px) {
      main#mainContent.service .intro .graphwrap .columnwrap {
        margin: 0; }
        main#mainContent.service .intro .graphwrap .columnwrap > .head,
        main#mainContent.service .intro .graphwrap .columnwrap > .graph {
          width: 100%;
          font-size: 16px; }
        main#mainContent.service .intro .graphwrap .columnwrap > .head1 {
          order: 1;
          margin: 50px 0 0; }
        main#mainContent.service .intro .graphwrap .columnwrap > .graph1 {
          order: 2; }
        main#mainContent.service .intro .graphwrap .columnwrap > .head2 {
          order: 3;
          margin: 50px 0 0; }
        main#mainContent.service .intro .graphwrap .columnwrap > .graph2 {
          order: 4; } }
    @media (max-width: 575.98px) {
      main#mainContent.service .intro .graphwrap .columnwrap {
        margin: 0; }
        main#mainContent.service .intro .graphwrap .columnwrap > .head,
        main#mainContent.service .intro .graphwrap .columnwrap > .graph {
          font-size: 13px; }
        main#mainContent.service .intro .graphwrap .columnwrap > .head1,
        main#mainContent.service .intro .graphwrap .columnwrap > .head2 {
          margin: 40px 0 0; } }
  main#mainContent.service .intro .graphwrap .columnwrap + p {
    text-align: right; }
    @media (max-width: 767.98px) {
      main#mainContent.service .intro .graphwrap .columnwrap + p {
        text-align: left;
        font-size: 90%; } }
    @media (max-width: 575.98px) {
      main#mainContent.service .intro .graphwrap .columnwrap + p {
        font-size: 80%; } }
  main#mainContent.service .intro .footwrap {
    margin: 65px 0 0; }
    main#mainContent.service .intro .footwrap .txt {
      font-size: 16px; }
      main#mainContent.service .intro .footwrap .txt span.red {
        color: #ff3300; }
    main#mainContent.service .intro .footwrap .strong {
      color: #43a5d7;
      font-size: 28px; }
    @media (max-width: 1399.98px) {
      main#mainContent.service .intro .footwrap {
        margin: 60px 0 0; } }
    @media (max-width: 991.98px) {
      main#mainContent.service .intro .footwrap {
        margin: 55px 0 0; }
        main#mainContent.service .intro .footwrap .txt {
          font-size: 15px; } }
    @media (max-width: 767.98px) {
      main#mainContent.service .intro .footwrap {
        margin: 50px 0 0; }
        main#mainContent.service .intro .footwrap .txt {
          font-size: 14px; }
        main#mainContent.service .intro .footwrap .strong {
          font-size: 24px; } }
    @media (max-width: 575.98px) {
      main#mainContent.service .intro .footwrap {
        margin: 40px 0 0; }
        main#mainContent.service .intro .footwrap .txt {
          font-size: 15px; }
        main#mainContent.service .intro .footwrap .strong {
          font-size: 22px; } }

main#mainContent.service section.advantage {
  padding: 120px 0 100px;
  background: #edf8ff; }
  @media (max-width: 1399.98px) {
    main#mainContent.service section.advantage {
      padding: 100px 0 60px; } }
  @media (max-width: 991.98px) {
    main#mainContent.service section.advantage {
      padding: 70px 0 50px; } }
  @media (max-width: 767.98px) {
    main#mainContent.service section.advantage {
      padding: 50px 0 40px; } }
  @media (max-width: 575.98px) {
    main#mainContent.service section.advantage {
      padding: 40px 0 30px; } }
  main#mainContent.service section.advantage .raisedwrap {
    margin: 0 0 80px; }
    main#mainContent.service section.advantage .raisedwrap .txt {
      font-size: 30px; }
    main#mainContent.service section.advantage .raisedwrap .txt br {
      display: none; }
    @media (max-width: 1399.98px) {
      main#mainContent.service section.advantage .raisedwrap {
        margin: 0 0 60px; }
        main#mainContent.service section.advantage .raisedwrap .txt {
          font-size: 30px; }
        main#mainContent.service section.advantage .raisedwrap .txt br {
          display: block; } }
    @media (max-width: 991.98px) {
      main#mainContent.service section.advantage .raisedwrap {
        margin: 0 0 55px; }
        main#mainContent.service section.advantage .raisedwrap .txt {
          font-size: 28px; } }
    @media (max-width: 767.98px) {
      main#mainContent.service section.advantage .raisedwrap {
        margin: 0 0 50px; }
        main#mainContent.service section.advantage .raisedwrap .txt {
          font-size: 26px; } }
    @media (max-width: 575.98px) {
      main#mainContent.service section.advantage .raisedwrap {
        margin: 0 0 40px; }
        main#mainContent.service section.advantage .raisedwrap .txt {
          font-size: 4.5vw; } }
  main#mainContent.service section.advantage .illwrap {
    width: auto;
    max-width: 660px;
    margin: 0 auto; }
    main#mainContent.service section.advantage .illwrap > div {
      width: 100%;
      bottom: 75px;
      left: 0;
      text-align: center; }
    main#mainContent.service section.advantage .illwrap .txt {
      display: block;
      width: auto;
      max-width: 500px;
      margin: 0 auto;
      padding: 0.75em 0;
      color: #FFF;
      font-size: 30px;
      background: #43a5d7; }
    @media (max-width: 1399.98px) {
      main#mainContent.service section.advantage .illwrap > div {
        bottom: 80px; }
      main#mainContent.service section.advantage .illwrap .txt {
        max-width: 460px;
        padding: 0.7em 0;
        font-size: 28px; } }
    @media (max-width: 991.98px) {
      main#mainContent.service section.advantage .illwrap {
        max-width: 500px; }
        main#mainContent.service section.advantage .illwrap > div {
          bottom: 60px; }
        main#mainContent.service section.advantage .illwrap .txt {
          max-width: 360px;
          padding: 0.6em 0;
          font-size: 24px; } }
    @media (max-width: 767.98px) {
      main#mainContent.service section.advantage .illwrap {
        max-width: 400px; }
        main#mainContent.service section.advantage .illwrap > div {
          bottom: 45px; }
        main#mainContent.service section.advantage .illwrap .txt {
          max-width: 280px;
          padding: 0.5em 0;
          font-size: 22px; } }
    @media (max-width: 575.98px) {
      main#mainContent.service section.advantage .illwrap > div {
        bottom: 45px; }
      main#mainContent.service section.advantage .illwrap .txt {
        max-width: 80%;
        padding: 0.5em 0;
        font-size: 18px; } }
  main#mainContent.service section.advantage .promisewrap {
    margin: 65px 0 0; }
    main#mainContent.service section.advantage .promisewrap .txt {
      font-size: 32px; }
    @media (max-width: 1399.98px) {
      main#mainContent.service section.advantage .promisewrap {
        margin: 60px 0 0; }
        main#mainContent.service section.advantage .promisewrap .txt {
          font-size: 30px; } }
    @media (max-width: 991.98px) {
      main#mainContent.service section.advantage .promisewrap {
        margin: 50px 0 0; }
        main#mainContent.service section.advantage .promisewrap .txt {
          font-size: 28px; } }
    @media (max-width: 767.98px) {
      main#mainContent.service section.advantage .promisewrap {
        margin: 40px 0 0; }
        main#mainContent.service section.advantage .promisewrap .txt {
          font-size: 22px; } }
    @media (max-width: 575.98px) {
      main#mainContent.service section.advantage .promisewrap {
        margin: 30px 0 0; }
        main#mainContent.service section.advantage .promisewrap .txt {
          font-size: 3.8vw; } }
  main#mainContent.service section.advantage .row .num1 {
    order: 1;
    margin-top: 80px; }
  main#mainContent.service section.advantage .row .num2 {
    order: 2;
    margin-top: 80px; }
  main#mainContent.service section.advantage .row .num3 {
    order: 3;
    margin-top: 80px; }
  main#mainContent.service section.advantage .row .cont1 {
    order: 4; }
  main#mainContent.service section.advantage .row .cont2 {
    order: 5; }
  main#mainContent.service section.advantage .row .cont3 {
    order: 6; }
  main#mainContent.service section.advantage .row .num4 {
    order: 7;
    margin-top: 45px; }
  main#mainContent.service section.advantage .row .num5 {
    order: 8;
    margin-top: 45px; }
  main#mainContent.service section.advantage .row .num6 {
    order: 9;
    margin-top: 45px; }
  main#mainContent.service section.advantage .row .cont4 {
    order: 10; }
  main#mainContent.service section.advantage .row .cont5 {
    order: 11; }
  main#mainContent.service section.advantage .row .cont6 {
    order: 12; }
  @media (max-width: 767.98px) {
    main#mainContent.service section.advantage .row .num1 {
      order: 1; }
    main#mainContent.service section.advantage .row .cont1 {
      order: 2; }
    main#mainContent.service section.advantage .row .num2 {
      order: 3;
      margin-top: 40px; }
    main#mainContent.service section.advantage .row .cont2 {
      order: 4; }
    main#mainContent.service section.advantage .row .num3 {
      order: 5;
      margin-top: 40px; }
    main#mainContent.service section.advantage .row .cont3 {
      order: 6; }
    main#mainContent.service section.advantage .row .num4 {
      order: 7;
      margin-top: 40px; }
    main#mainContent.service section.advantage .row .cont4 {
      order: 8; }
    main#mainContent.service section.advantage .row .num5 {
      order: 9;
      margin-top: 40px; }
    main#mainContent.service section.advantage .row .cont5 {
      order: 10; }
    main#mainContent.service section.advantage .row .num6 {
      order: 11;
      margin-top: 40px; }
    main#mainContent.service section.advantage .row .cont6 {
      order: 12; } }
  main#mainContent.service section.advantage .point_num {
    display: block;
    text-align: center; }
    main#mainContent.service section.advantage .point_num img {
      display: block;
      width: 48px;
      height: auto;
      margin: 0 auto; }
    main#mainContent.service section.advantage .point_num span {
      display: block;
      margin: 1.25em 0;
      padding: 0;
      line-height: 1.4;
      color: #43a5d7; }
      @media (max-width: 1399.98px) {
        main#mainContent.service section.advantage .point_num span {
          margin: 1.15em 0; } }
      @media (max-width: 991.98px) {
        main#mainContent.service section.advantage .point_num span {
          margin: 1em 0;
          font-size: 15px; } }
      @media (max-width: 767.98px) {
        main#mainContent.service section.advantage .point_num span {
          margin: 1.15em 0;
          font-size: 18px; } }
      @media (max-width: 575.98px) {
        main#mainContent.service section.advantage .point_num span {
          margin: 1em 0;
          font-size: 16px; } }
  main#mainContent.service section.advantage .point_cont .cont_in {
    display: block;
    margin: 0;
    background: #FFF;
    border-radius: 18px;
    overflow: hidden;
    height: 100%; }
    @media (max-width: 575.98px) {
      main#mainContent.service section.advantage .point_cont .cont_in {
        border-radius: 14px; } }
    main#mainContent.service section.advantage .point_cont .cont_in .txtwrap {
      display: block;
      padding: 2em 1.75em;
      font-size: 14px; }
      @media (max-width: 1399.98px) {
        main#mainContent.service section.advantage .point_cont .cont_in .txtwrap {
          padding: 1.8em 1.75em; } }
      @media (max-width: 991.98px) {
        main#mainContent.service section.advantage .point_cont .cont_in .txtwrap {
          padding: 1.6em 1.5em; } }
      @media (max-width: 767.98px) {
        main#mainContent.service section.advantage .point_cont .cont_in .txtwrap {
          padding: 1.5em 1.375em; } }
      @media (max-width: 575.98px) {
        main#mainContent.service section.advantage .point_cont .cont_in .txtwrap {
          padding: 1.4em 1.25em; } }

main#mainContent.service .support {
  padding: 120px 0 100px;
  background: #FFF; }
  @media (max-width: 1399.98px) {
    main#mainContent.service .support {
      padding: 100px 0 60px; } }
  @media (max-width: 991.98px) {
    main#mainContent.service .support {
      padding: 70px 0 50px; } }
  @media (max-width: 767.98px) {
    main#mainContent.service .support {
      padding: 50px 0 40px; } }
  @media (max-width: 575.98px) {
    main#mainContent.service .support {
      padding: 40px 0 30px; } }
  main#mainContent.service .support .substance {
    margin: 100px 0 0; }
    @media (max-width: 1399.98px) {
      main#mainContent.service .support .substance {
        margin: 100px 0 0; } }
    @media (max-width: 991.98px) {
      main#mainContent.service .support .substance {
        margin: 80px 0 0; } }
    @media (max-width: 767.98px) {
      main#mainContent.service .support .substance {
        margin: 60px 0 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.service .support .substance {
        margin: 50px 0 0; } }
    main#mainContent.service .support .substance > dl {
      display: block; }
      main#mainContent.service .support .substance > dl:first-child {
        margin: 0 0 45px; }
        @media (max-width: 991.98px) {
          main#mainContent.service .support .substance > dl:first-child {
            margin: 0 0 40px; } }
      main#mainContent.service .support .substance > dl > dt {
        display: block;
        padding: 0.75em 0;
        width: 340px;
        text-align: center;
        color: #FFF;
        font-size: 16px;
        background: #0959a7; }
        @media (max-width: 1399.98px) {
          main#mainContent.service .support .substance > dl > dt {
            width: 320px;
            font-size: 16px; } }
        @media (max-width: 991.98px) {
          main#mainContent.service .support .substance > dl > dt {
            padding: 0.8em 0;
            width: 320px;
            font-size: 15px; } }
        @media (max-width: 767.98px) {
          main#mainContent.service .support .substance > dl > dt {
            width: 100%; } }
      main#mainContent.service .support .substance > dl > dd {
        display: block;
        margin: 0;
        padding: 30px 40px;
        font-size: 20px;
        background: #f5f7f8; }
        main#mainContent.service .support .substance > dl > dd span.red {
          color: #eb1818; }
        @media (max-width: 1399.98px) {
          main#mainContent.service .support .substance > dl > dd {
            padding: 28px 38px;
            font-size: 19px; } }
        @media (max-width: 991.98px) {
          main#mainContent.service .support .substance > dl > dd {
            padding: 22px 34px;
            font-size: 18px; } }
        @media (max-width: 767.98px) {
          main#mainContent.service .support .substance > dl > dd {
            padding: 18px 30px;
            font-size: 17px; } }
        @media (max-width: 575.98px) {
          main#mainContent.service .support .substance > dl > dd {
            padding: 1em 24px;
            font-size: 16px; } }
  main#mainContent.service .support .schedule {
    margin: 50px 0 0; }
    main#mainContent.service .support .schedule .bighead {
      margin: 0 0 1em;
      color: #0959a7;
      font-size: 30px; }
      @media (max-width: 1399.98px) {
        main#mainContent.service .support .schedule .bighead {
          font-size: 28px; } }
      @media (max-width: 991.98px) {
        main#mainContent.service .support .schedule .bighead {
          font-size: 26px; } }
      @media (max-width: 767.98px) {
        main#mainContent.service .support .schedule .bighead {
          font-size: 22px; } }
      @media (max-width: 575.98px) {
        main#mainContent.service .support .schedule .bighead {
          font-size: 18px; } }
    main#mainContent.service .support .schedule .wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      main#mainContent.service .support .schedule .wrap .column {
        display: block;
        width: calc(50% - 5px); }
        main#mainContent.service .support .schedule .wrap .column .columnin {
          display: block;
          height: 100%;
          background: #f5f7f8; }
        @media (max-width: 767.98px) {
          main#mainContent.service .support .schedule .wrap .column {
            width: 100%; }
            main#mainContent.service .support .schedule .wrap .column:last-child {
              margin-top: 35px; } }
    main#mainContent.service .support .schedule .head {
      padding: 0.75em 0;
      text-align: center;
      color: #FFF;
      font-size: 22px;
      background: #378adb; }
      @media (max-width: 1399.98px) {
        main#mainContent.service .support .schedule .head {
          padding: 0.8em 0;
          font-size: 20px; } }
      @media (max-width: 991.98px) {
        main#mainContent.service .support .schedule .head {
          padding: 0.85em 0;
          font-size: 18px; } }
      @media (max-width: 767.98px) {
        main#mainContent.service .support .schedule .head {
          padding: 0.9em 0;
          font-size: 17px; } }
      @media (max-width: 575.98px) {
        main#mainContent.service .support .schedule .head {
          padding: 0.95em 0;
          font-size: 16px; } }
    main#mainContent.service .support .schedule ul.step {
      display: block;
      margin: 30px 0;
      padding: 0 30px;
      list-style: none;
      position: relative; }
      main#mainContent.service .support .schedule ul.step:after {
        content: "";
        display: block;
        width: 20px;
        height: 100%;
        position: absolute;
        top: 0;
        left: calc(50% - 10px);
        z-index: 0; }
      @media (max-width: 1399.98px) {
        main#mainContent.service .support .schedule ul.step {
          margin: 28px 0;
          padding: 0 28px; }
          main#mainContent.service .support .schedule ul.step:after {
            width: 18px;
            left: calc(50% - 9px); } }
      @media (max-width: 991.98px) {
        main#mainContent.service .support .schedule ul.step {
          margin: 26px 0;
          padding: 0 26px; }
          main#mainContent.service .support .schedule ul.step:after {
            width: 16px;
            left: calc(50% - 6px); } }
      @media (max-width: 767.98px) {
        main#mainContent.service .support .schedule ul.step {
          margin: 20px 0;
          padding: 0 20px; }
          main#mainContent.service .support .schedule ul.step:after {
            width: 14px;
            left: calc(50% - 7px); } }
      @media (max-width: 575.98px) {
        main#mainContent.service .support .schedule ul.step {
          margin: 16px 0;
          padding: 0 16px; } }
      main#mainContent.service .support .schedule ul.step.gr:after {
        background: #d1f7a0; }
      main#mainContent.service .support .schedule ul.step.gr > li:not(.diff) {
        background: #8fd830; }
      main#mainContent.service .support .schedule ul.step.og:after {
        background: #ffe199; }
      main#mainContent.service .support .schedule ul.step.og > li:not(.diff) {
        background: #efb11d; }
      main#mainContent.service .support .schedule ul.step li:not(.diff) {
        position: relative;
        z-index: 1;
        display: block;
        width: 100%;
        padding: 0.8em 0;
        text-align: center;
        line-height: 1;
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        border-radius: 50px; }
        @media (max-width: 1399.98px) {
          main#mainContent.service .support .schedule ul.step li:not(.diff) {
            font-size: 17px; } }
        @media (max-width: 991.98px) {
          main#mainContent.service .support .schedule ul.step li:not(.diff) {
            padding: 1em 0;
            font-size: 14px; } }
        @media (max-width: 767.98px) {
          main#mainContent.service .support .schedule ul.step li:not(.diff) {
            padding: 1em 0;
            font-size: 16px; } }
        @media (max-width: 575.98px) {
          main#mainContent.service .support .schedule ul.step li:not(.diff) {
            font-size: 13px; } }
      main#mainContent.service .support .schedule ul.step li.diff {
        position: relative;
        z-index: 1;
        margin: 26px auto; }
        main#mainContent.service .support .schedule ul.step li.diff p {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 160px;
          line-height: 1;
          text-align: center;
          margin: 0 auto;
          padding: 0.25em 0;
          color: #e11414;
          font-size: 26px;
          font-weight: 900;
          background: #FFF;
          border: #df4c6b solid 2px;
          border-radius: 3px; }
          main#mainContent.service .support .schedule ul.step li.diff p small {
            font-size: 16px;
            font-weight: 900; }
        @media (max-width: 1399.98px) {
          main#mainContent.service .support .schedule ul.step li.diff {
            margin: 24px auto; }
            main#mainContent.service .support .schedule ul.step li.diff p {
              width: 140px;
              padding: 0.2em 0;
              font-size: 22px; }
              main#mainContent.service .support .schedule ul.step li.diff p small {
                font-size: 14px; } }
        @media (max-width: 991.98px) {
          main#mainContent.service .support .schedule ul.step li.diff {
            margin: 22px auto; }
            main#mainContent.service .support .schedule ul.step li.diff p {
              width: 120px;
              padding: 0.3em 0;
              font-size: 18px; }
              main#mainContent.service .support .schedule ul.step li.diff p small {
                font-size: 13px; } }
        @media (max-width: 767.98px) {
          main#mainContent.service .support .schedule ul.step li.diff {
            margin: 20px auto; } }
        @media (max-width: 575.98px) {
          main#mainContent.service .support .schedule ul.step li.diff {
            margin: 18px auto; } }
  main#mainContent.service .support .manager {
    margin: 50px 30px 0; }
    main#mainContent.service .support .manager dl dt {
      display: block;
      margin: 0 0 0.5em;
      color: #378adb;
      font-size: 18px; }
    main#mainContent.service .support .manager dl dd {
      display: block;
      font-size: 14px; }
    @media (max-width: 1399.98px) {
      main#mainContent.service .support .manager {
        margin: 45px 28px 0; } }
    @media (max-width: 991.98px) {
      main#mainContent.service .support .manager {
        margin: 40px 26px 0; }
        main#mainContent.service .support .manager dl dt {
          margin: 0 0 0.4em;
          font-size: 16px; }
        main#mainContent.service .support .manager dl dd {
          font-size: 13px; } }
    @media (max-width: 767.98px) {
      main#mainContent.service .support .manager {
        margin: 30px 20px 0; }
        main#mainContent.service .support .manager dl dt {
          margin: 0 0 0.3em;
          font-size: 15px; }
        main#mainContent.service .support .manager dl dd {
          font-size: 12px; } }
    @media (max-width: 575.98px) {
      main#mainContent.service .support .manager {
        margin: 25px 16px 0; } }

main#mainContent.service .faq {
  padding: 120px 0;
  background: #edf8ff; }
  @media (max-width: 1399.98px) {
    main#mainContent.service .faq {
      padding: 100px 0 80px; } }
  @media (max-width: 991.98px) {
    main#mainContent.service .faq {
      padding: 70px 0 50px; } }
  @media (max-width: 767.98px) {
    main#mainContent.service .faq {
      padding: 50px 0 35px; } }
  @media (max-width: 575.98px) {
    main#mainContent.service .faq {
      padding: 40px 0 25px; } }
  main#mainContent.service .faq .wrap > dl {
    display: block;
    margin: 0 0 30px;
    background: #FFF; }
    main#mainContent.service .faq .wrap > dl:last-child {
      margin: 0; }
    main#mainContent.service .faq .wrap > dl dt {
      background: #43a5d7;
      border-bottom: #edf8ff solid 1px; }
      main#mainContent.service .faq .wrap > dl dt span {
        display: block;
        width: 72px;
        height: 100%;
        text-align: center;
        line-height: 1;
        color: #FFF;
        font-size: 18px; }
        @media (max-width: 991.98px) {
          main#mainContent.service .faq .wrap > dl dt span {
            width: 68px;
            font-size: 17px; } }
        @media (max-width: 767.98px) {
          main#mainContent.service .faq .wrap > dl dt span {
            width: 60px;
            font-size: 16px; } }
        @media (max-width: 575.98px) {
          main#mainContent.service .faq .wrap > dl dt span {
            width: 50px;
            font-size: 15px; } }
      main#mainContent.service .faq .wrap > dl dt p {
        flex: 1;
        display: block;
        width: auto;
        margin: 0;
        padding: 1.25em 1.5em;
        background: #FFF; }
        @media (max-width: 991.98px) {
          main#mainContent.service .faq .wrap > dl dt p {
            padding: 0.75em 1em;
            font-size: 15px; } }
        @media (max-width: 767.98px) {
          main#mainContent.service .faq .wrap > dl dt p {
            padding: 0.75em 1em;
            font-size: 14px; } }
    main#mainContent.service .faq .wrap > dl dd {
      display: block;
      margin: 0;
      padding: 22px 26px;
      font-size: 14px;
      line-height: 2; }
      @media (max-width: 1399.98px) {
        main#mainContent.service .faq .wrap > dl dd {
          padding: 20px 24px; } }
      @media (max-width: 991.98px) {
        main#mainContent.service .faq .wrap > dl dd {
          padding: 18px 22px; } }
      @media (max-width: 767.98px) {
        main#mainContent.service .faq .wrap > dl dd {
          padding: 16px 20px;
          font-size: 13px; } }
      @media (max-width: 575.98px) {
        main#mainContent.service .faq .wrap > dl dd {
          padding: 1em 1.5em; } }

main#mainContent.service .inquiry {
  background: #FFF; }
  main#mainContent.service .inquiry .wrap {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 100px 0; }
    @media (max-width: 1399.98px) {
      main#mainContent.service .inquiry .wrap {
        max-width: 550px;
        padding: 90px 0; } }
    @media (max-width: 991.98px) {
      main#mainContent.service .inquiry .wrap {
        max-width: 500px;
        padding: 75px 0; } }
    @media (max-width: 767.98px) {
      main#mainContent.service .inquiry .wrap {
        max-width: 500px;
        padding: 50px 0; } }
    @media (max-width: 575.98px) {
      main#mainContent.service .inquiry .wrap {
        width: auto;
        max-width: auto;
        margin: 0 15px;
        padding: 40px 0; } }
    main#mainContent.service .inquiry .wrap a {
      display: block;
      margin: 0;
      padding: 1.75em 0;
      line-height: 1;
      text-align: center;
      color: #000;
      font-size: 20px;
      font-weight: 700;
      background: #ffcb3e;
      border-radius: 55px;
      position: relative;
      overflow: hidden;
      transition: color 0.16s 0.06s; }
      @media (max-width: 1399.98px) {
        main#mainContent.service .inquiry .wrap a {
          padding: 1.75em 0;
          font-size: 18px; } }
      @media (max-width: 991.98px) {
        main#mainContent.service .inquiry .wrap a {
          padding: 1.75em 0;
          font-size: 16px; } }
      @media (max-width: 767.98px) {
        main#mainContent.service .inquiry .wrap a {
          padding: 1.75em 0;
          font-size: 14px; } }
      @media (max-width: 575.98px) {
        main#mainContent.service .inquiry .wrap a {
          padding: 1.75em 0;
          font-size: 13px; } }
      main#mainContent.service .inquiry .wrap a:after {
        content: "";
        display: block;
        width: 100%;
        height: 0;
        background: #43a5d7;
        position: absolute;
        top: 50%;
        left: 0;
        transform: rotate(-12deg);
        transition: all 0.16s; }
      main#mainContent.service .inquiry .wrap a span {
        position: relative;
        z-index: 1; }
      @media (min-width: 768px) {
        main#mainContent.service .inquiry .wrap a:hover {
          color: #FFF; }
          main#mainContent.service .inquiry .wrap a:hover:after {
            height: 100%;
            top: 0;
            transform: rotate(0); } }
