// ==========================================

// line-height

// ==========================================
.lh-10 { line-height: 1; }
.lh-12 { line-height: 1.2; }
.lh-14 { line-height: 1.4; }
.lh-16 { line-height: 1.6; }
.lh-18 { line-height: 1.8; }
.lh-20 { line-height: 2.0; }
.lh-22 { line-height: 2.2; }
.lh-24 { line-height: 2.4; }
.lh-26 { line-height: 2.6; }
.lh-28 { line-height: 2.8; }
.lh-30 { line-height: 3.0; }
.lh-32 { line-height: 3.2; }
.lh-34 { line-height: 3.4; }
.lh-36 { line-height: 3.6; }
.lh-38 { line-height: 3.8; }
