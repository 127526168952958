// ==========================================

// フッター

// ==========================================
footer#footer {
    display: block;
    margin: 0;
    padding: 0;
}

@include media-breakpoint-down(lg) {}
@include media-breakpoint-down(md) {}
@include media-breakpoint-down(sm) {}
@include media-breakpoint-down(xs) {}


// ==========================================

// CONTACT

// ==========================================
footer#footer .inquirybg {
    background-image: url(./img/footer/bg.jpg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    .inquirywrap {
        display: block;
        margin: 0;
        padding: 90px 0 120px;
        color: #FFF;
        text-align: center;
        @include media-breakpoint-down(lg) { padding: 80px 0 100px;}
        @include media-breakpoint-down(md) { padding: 70px 0 90px;}
        @include media-breakpoint-down(sm) { padding: 48px 0 60px;}
        @include media-breakpoint-down(xs) { padding: 36px 0 48px;}
    }
    // 見出し
    dl.head {
        margin-bottom: 55px;
        > dt { font-size: 36px; }
        > dd { font-size: 18px; }

        @include media-breakpoint-down(lg) {
            margin-bottom: 50px;
            > dt { font-size: 34px; }
            > dd { font-size: 16px; }
        }
        @include media-breakpoint-down(md) {
            margin-bottom: 40px;
            > dt { font-size: 32px; }
            > dd { font-size: 16px; }
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: 36px;
            > dt { font-size: 30px; }
            > dd { font-size: 14px; }
        }
        @include media-breakpoint-down(xs) {
            margin-bottom: 24px;
            > dt { font-size: 28px; }
            > dd { font-size: 14px; }
        }
    }
    // 共通
    .typehead {
        > span {
            width: 33px;
            @include media-breakpoint-down(md) { width: 30px;}
            @include media-breakpoint-down(sm) { width: 27px;}
            @include media-breakpoint-down(xs) { width: 24px;}
        }
        > p {
            margin: 0 0 0 1em;
            padding: 0 2px 0.4em;
            position: relative;
            font-size: 16px;
            @include media-breakpoint-down(md) {font-size: 15px; margin: 0 0 0 0.8em; padding: 0 2px 0.35em;}
            @include media-breakpoint-down(sm) {font-size: 14px; margin: 0 0 0 0.6em; padding: 0 1px 0.3em;}
            @include media-breakpoint-down(xs) {font-size: 13px; margin: 0 0 0 0.4em; padding: 0 1px 0.25em;}
            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                background: #FFF;
            }
        }
    }
}

// ==========================================

// お電話でのお問い合わせ

// ==========================================
footer#footer .call_content {
    display: block;
    margin: 0;
    padding: 20px 40px 30px;
    @include media-breakpoint-down(lg) {
        padding: 20px 30px 30px;
    }
    @include media-breakpoint-down(md) {
        padding: 20px 20px 30px;
    }
    @include media-breakpoint-down(sm) {
        padding: 15px 15px 25px;
    }
    @include media-breakpoint-down(xs) {}

    .callnum {
        justify-content: center;
        align-items: center;
        padding: 18px 24px;
        @include media-breakpoint-down(lg) {
            padding: 16px 18px;
            flex-wrap: wrap;
        }
        @include media-breakpoint-down(md) {
        }
        @include media-breakpoint-down(sm) {}
        @include media-breakpoint-down(xs) {}

        .area {
                width: 80%;
                margin: 0 auto;
            > span {
                display: block;
                padding: 1em 2em;
                line-height: 1.2;
                color: #115c85;
                font-size: 14px;
                background: #FFF;
                border-radius: 30px;
                @include media-breakpoint-down(lg) {
                    padding: 1em 0.8em;
                    font-size: 14px;
                }
                @include media-breakpoint-down(md) {
                    padding: 0.75em 0;
                }
                @include media-breakpoint-down(sm) {
                    padding: 0.5em 0;
                    font-size: 13px;
                }
                @include media-breakpoint-down(xs) {}
            }
        }
        .num {
            margin: 0 0 0 1em;
            font-size: 36px;
                width: 100%;
                margin: 0;
                text-align: center;
                font-size: 30px;
            @include media-breakpoint-down(lg) {
                margin: 0.5em 0 0;
            }
            @include media-breakpoint-down(xs) {
                font-size: 28px;
            }
            a {
                color: #FFF;
            }
        }
    }
}

// ==========================================

// お問い合わせフォームでのお問い合わせ

// ==========================================
footer#footer .form_content {
    display: block;
    margin: 0;
    padding: 30px 40px;
    .typehead {
        justify-content: flex-end;
    }
    .to_form {
        text-align: left;
        > a {
            display: inline-block;
            line-height: 1;
            margin: 0;
            padding: 1.25em 6em;
            color: #000;
            font-size: 15px;
            background: #ffcb3e;
            border-radius: 55px;
            position: relative;
            overflow: hidden;
            transition: color 0.16s 0.06s;
            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 0;
                background: $keyColor;
                position: absolute;
                top: 50%;
                left: 0;
                transform: rotate(-12deg);
                transition: all 0.16s;
            }
            span { position: relative; z-index: 1;}
            @include media-breakpoint-up(md) {
                &:hover {
                    color: #FFF;
                    &:after {
                        height: 100%;
                        top: 0;
                        transform: rotate(0);
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        padding: 20px;
        .typehead {
            justify-content: center;
        }
        .to_form {
            margin: 20px 0 0;
            text-align: center;
        }
    }
    @include media-breakpoint-down(sm) {
        padding: 20px 15px;
        .to_form {
            a {
                display: block;
                margin: 0 1em;
                padding: 1.25em 0;
            }
        }
    }
}

// ==========================================

// GLOBAL FOOTER

// ==========================================
footer#footer .footerwrap {
    padding: 100px 0 0;
    background: $baseColor;
    @include media-breakpoint-down(lg) {padding: 90px 0 0;}
    @include media-breakpoint-down(md) {padding: 80px 0 0;}
    @include media-breakpoint-down(sm) {padding: 50px 0 0;}
    @include media-breakpoint-down(xs) {padding: 36px 0 0;}
    .content {
        justify-content: space-between;
        align-items: center;
        @include media-breakpoint-down(lg) {}
        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
            > .id { order: 1;}
            > .gnav { order: 3;}
            > .social { order: 2;}
        }
        @include media-breakpoint-down(sm) {}
        @include media-breakpoint-down(xs) {
            > .id { order: 1;}
            > .gnav { order: 2;}
            > .social { order: 3;}
        }
    }
}

// ==========================================

// ロゴ

// ==========================================
footer#footer .footerwrap .content .id {
    width: 190px;
    margin-right: auto;
    @include media-breakpoint-down(lg) { width: 175px;}
    @include media-breakpoint-down(md) { width: 175px;}
    @include media-breakpoint-down(sm) { width: 150px;}
    @include media-breakpoint-down(xs) { 
        width: 36%;
        margin: 0 auto;
    }
}

// ==========================================

// グローバルナビ

// ==========================================
footer#footer .footerwrap .content .gnav{
    margin: 0 auto;
    @include media-breakpoint-down(lg) {}
    @include media-breakpoint-down(md) {
        width: 100%;
        margin: 50px auto 0;
    }
    @include media-breakpoint-down(sm) {
        margin: 35px auto 0;
    }
    @include media-breakpoint-down(xs) {
        margin: 20px auto 0;
    }
    > ul {
        display: flex;
        justify-content: center;
        width: auto;
        margin: 0;
        padding: 0;
        list-style: none;
        @include media-breakpoint-down(md) {
            justify-content: space-around;
        }
        @include media-breakpoint-down(xs) {
            flex-wrap: wrap;
        }
        > li {
            display: block;
            margin: 0;
            padding: 0;
            @include media-breakpoint-down(xs) { width: 100%;}
            a {
                display: block;
                margin: 0 0.5em;
                padding: 0.25em 1.5em;
                color: #333;
                font-size: 13px;
                font-weight: 700;
                position: relative;
                transition: color 0.2s 0.06s;
                &:after {
                    content: "";
                    display: block;
                    width: 0;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: $keyColor;
                    transition: width 0.2s;
                }
                > span { position: relative; z-index: 1; }


                @include media-breakpoint-up(md) {
                    &:hover {
                        color: #FFF;
                        &:after {
                            width: 100%;
                        }
                    }
                }

                @include media-breakpoint-down(lg) {
                    margin: 0 0.25em;
                    padding: 0.25em 1em;
                }
                @include media-breakpoint-down(md) {
                    margin: 0;
                    padding: 1em 0;
                    font-size: 12px;
                }
                @include media-breakpoint-down(xs) {
                    margin: 0;
                    padding: 0.75em 0;
                    text-align: center;
                    font-size: 14px;
                }
            }
        }
    }
}

// ==========================================

// ソーシャルボタン

// ==========================================
footer#footer .footerwrap .content .social{
    margin-left: auto;
    > ul {
        display: flex;
        width: auto;
        margin: 0;
        padding: 0;
        list-style: none;
        > li {
            display: block;
            margin: 0;
            padding: 0;
            a {
                display: block;
                width: 34px;
                padding: 4px;
                transition: opacity 0.2s;
                @include media-breakpoint-up(md) {
                    &:hover { opacity: 0.33;}
                }
            }
        }
    }
    @include media-breakpoint-down(xs) {
        width: 100%;
        margin: 36px auto 0;
        > ul {
            justify-content: center;
            > li {
                a {
                    margin: 0 10px;
                    width: 36px;
                    padding: 2px;
                }
            }
        }
    }
}

// ==========================================

// Copyright

// ==========================================
footer#footer .footerwrap .cr {
    margin: 0;
    padding: 100px 0;
    text-align: center;
    line-height: 1;
    color: #989897;
    font-size: 10px;
    font-weight: 700;
    @include media-breakpoint-down(lg) {padding: 90px 0;}
    @include media-breakpoint-down(md) {padding: 80px 0;}
    @include media-breakpoint-down(sm) {padding: 60px 0;}
    @include media-breakpoint-down(xs) {padding: 50px 0;}
}