// ==========================================

// サービスページ

// ==========================================
main#mainContent.service {
	background: $baseColor;
	padding-bottom: 120px;
	@include media-breakpoint-down(lg) { padding-bottom: 100px;}
	@include media-breakpoint-down(md) { padding-bottom: 80px;}
	@include media-breakpoint-down(sm) { padding-bottom: 70px;}
	@include media-breakpoint-down(xs) { padding-bottom: 60px;}
	.servicecontent {
		margin-top: 60px;
		@include media-breakpoint-down(lg) { margin-top: 50px;}
		@include media-breakpoint-down(md) { margin-top: 40px;}
		@include media-breakpoint-down(sm) { margin-top: 0;}
		@include media-breakpoint-down(xs) {}
	}
	span.blue { color: #0959a7!important;}
	span.light { color: #43a3d8!important;}
	span.yellow { color: #ebc721!important;}
	span.uline { text-decoration: underline; text-decoration-color: $keyColor; }
}


// ==========================================

// コンテンツ見出し

// ==========================================
main#mainContent.service .page-head{
	height: 324px;
	background: $baseColor;
	@include media-breakpoint-down(lg) { height: 300px;}
	@include media-breakpoint-down(md) { height: 276px;}
	@include media-breakpoint-down(sm) { height: 242px;}
	@include media-breakpoint-down(xs) { height: 218px;}
	.title {
		text-align: center;
		color: #333;
		font-size: 28px;
		font-weight: 900;
		@include media-breakpoint-down(lg) { font-size: 27px;}
		@include media-breakpoint-down(md) { font-size: 26px;}
		@include media-breakpoint-down(sm) { font-size: 24px;}
		@include media-breakpoint-down(xs) { font-size: 22px;}
		small {
			display: block;
			margin: 2em 0 0;
			font-size: 12px;
			font-weight: 700;
			@include media-breakpoint-down(sm) { font-size: 11px;}
			@include media-breakpoint-down(xs) { font-size: 10px;}
		}
		&:after {
			content: "";
			display: block;
			width: 1.25em;
			height: 5px;
			margin: 1.25em auto 0;
			padding: 0;
			background: #000;
		}
	}
}


// ==========================================

// パンくず

// ==========================================
main#mainContent.service .breadcrumb {
    margin: 0;
    padding: 0;
    background: #FFF;
    ul {
		margin: 0;
        padding: 2.25em 0;
        list-style: none;
        font-size: 11px;
        li {
            &:not(:last-child) {
				a {color: #959595;}
				&:after {
					display: inline-block;
					content: ">";
					width: 2em;
					text-align: center;
					color: #333;
				}
			}
			&:last-child {
				a {
					color: #333;
					font-weight: 700;
				}
			}
        }
    }
    @include media-breakpoint-down(sm) { display: none;}
}


// ==========================================

// コンテンツ ベースレイアウト

// ==========================================
main#mainContent.service {
	section {
		> .content {
			width: auto;
			max-width: 1000px;
			margin: 0 auto;
			@include media-breakpoint-down(lg) { max-width: 800px;}
			@include media-breakpoint-down(md) { max-width: 620px;}
			@include media-breakpoint-down(sm) { max-width: 460px;}
			@include media-breakpoint-down(xs) { max-width: 100%; margin: 0 15px;}
		}
	}
}

// ==========================================

// セクションタイトル

// ==========================================
main#mainContent.service {
	section {
		> .content {
			.section-head {
				display: inline-block;
				margin: 0 auto 2em;
				padding: 0 0 0.5em;
				text-align: center;
				color: #474343;
				font-size: 32px;
				font-weight: 900;
				position: relative;
				&:after {
					content: "";
					display: block;
					width: 100%;
					height: 1px;
					position: absolute;
					bottom: 0;
					left: 0;
					background: $keyColor;
				}
				@include media-breakpoint-down(lg) { font-size: 27px;}
				@include media-breakpoint-down(md) { font-size: 26px;}
				@include media-breakpoint-down(sm) { font-size: 24px;}
				@include media-breakpoint-down(xs) { font-size: 22px;}
			}
		}
	}
}


// ==========================================

//  はじめに

// ==========================================
main#mainContent.service .intro {
	padding: 120px 0 100px;
	background: #FFF;
	@include media-breakpoint-down(lg) { padding: 100px 0 60px;}
	@include media-breakpoint-down(md) { padding: 70px 0 50px;}
	@include media-breakpoint-down(sm) { padding: 50px 0 40px;}
	@include media-breakpoint-down(xs) { padding: 40px 0 30px;}
	.readtxt {
		margin: 0;
		padding: 1.25em;
		background: $baseColor;
		border-radius: 0.5em;
		p {
			font-size: 14px;
			@include media-breakpoint-down(lg) {}
			@include media-breakpoint-down(md) {}
			@include media-breakpoint-down(sm) { font-size: 13px;}
			@include media-breakpoint-down(xs) {}
		}
	}
	// 
	.shortagewrap {
		margin: 60px 0 0;
		@include media-breakpoint-down(lg) { margin: 60px 0 0;}
		@include media-breakpoint-down(md) { margin: 45px 0 0;}
		@include media-breakpoint-down(sm) { margin: 35px 0 0;}
		@include media-breakpoint-down(xs) { margin: 30px 0 0;}
		> .head {
			margin: 0;
			padding: 0 0 1.25em;
			font-size: 28px;
			@include media-breakpoint-down(lg) { padding: 0 0 1.25em; font-size: 26px;}
			@include media-breakpoint-down(md) { padding: 0 0 1em; font-size: 24px;}
			@include media-breakpoint-down(sm) { padding: 0 0 1em; font-size: 20px;}
			@include media-breakpoint-down(xs) { padding: 0 0 0.75em; font-size: 16px;}
			span.red { color: #e05555;}
		}
	}
	//
	.shortagewrap + p {
		text-align: right;
		@include media-breakpoint-down(lg) {}
		@include media-breakpoint-down(md) {}
		@include media-breakpoint-down(sm) { text-align: left; font-size: 90%;}
		@include media-breakpoint-down(xs) { font-size: 80%;}
	}
	//
	.txtwrap {
		margin: 40px 0 0;
		font-size: 14px;
		@include media-breakpoint-down(lg) {}
		@include media-breakpoint-down(md) {}
		@include media-breakpoint-down(sm) { margin: 2em 0 0; font-size: 13px;}
		@include media-breakpoint-down(xs) { margin: 1.5em 0 0;}
	}
	// 2020年現在でも人手不足が深...
	// ですが、
	// 日本で働いてくれている外国...
	.strongwrap {
		margin: 50px 0 0;
		.txt1 {
			margin: 0;
			padding: 0;
			font-size: 22px;
			span.red { color: #e05555;}
		}
		.txt2 {
			margin: 0.75em 0 0;
			padding: 0;
			font-size: 28px;
		}
		.txt3 {
			margin: 0.25em 0 0;
			padding: 0;
			font-size: 36px;
		}
		.txt1 br { display: none;}
		.txt3 br { display: none;}
		@include media-breakpoint-down(lg) {
			margin: 40px 0 0;
			.txt1 br { display: block;}
			.txt3 br { display: block;}
		}
		@include media-breakpoint-down(md) {
			margin: 35px 0 0;
			.txt1 { font-size: 20px;}
			.txt2 { font-size: 26px;}
			.txt3 { font-size: 30px;}
		}
		@include media-breakpoint-down(sm) {
			.txt3 br { display: none;}
		}
		@include media-breakpoint-down(xs) {
			.txt1 { font-size: 3.6vw;}
			.txt2 { font-size: 5vw;}
			.txt3 { font-size: 5.5vw;}
			.txt1 br { display: block;}
			.txt3 br { display: none;}
		}
	}
	//x
	.graphwrap {
		// 縦棒・折れ線グラフ
		.fullwrap {
			margin: 80px 0 0;
			@include media-breakpoint-down(lg) { margin: 60px 0 0;}
			@include media-breakpoint-down(md) { margin: 55px 0 0;}
			@include media-breakpoint-down(sm) { margin: 50px 0 0;}
			@include media-breakpoint-down(xs) { margin: 40px 0 0;}
			> .head {
				margin: 0;
				padding: 0 0 1em;
				font-size: 28px;
				@include media-breakpoint-down(lg) { padding: 0 0 1em; font-size: 26px;}
				@include media-breakpoint-down(md) { padding: 0 0 0.9em; font-size: 24px;}
				@include media-breakpoint-down(sm) { padding: 0 0 0.8em; font-size: 20px;}
				@include media-breakpoint-down(xs) { padding: 0 0 0.75em; font-size: 16px;}
			}
		}
	
		// 円グラフ/横棒グラフ
		.columnwrap {
			margin: 65px 0 0;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			> .head {
				width: 50%;
				padding: 0 0 1.5em;
				font-size: 18px;
			}
			> .graph { width: 50%; }
			@include media-breakpoint-down(lg) {
				margin: 60px 0 0;
				> .head { font-size: 16px; }
			}
			@include media-breakpoint-down(md) {
				margin: 55px 0 0;
				> .head { font-size: 14px; }
			}
			@include media-breakpoint-down(sm) {
				margin: 0;
				> .head,
				> .graph {
					width: 100%;
					font-size: 16px;
				}
				> .head1 { order: 1; margin: 50px 0 0;}
				> .graph1 { order: 2;}
				> .head2 { order: 3; margin: 50px 0 0;}
				> .graph2 { order: 4;}
			}
			@include media-breakpoint-down(xs) {
				margin: 0;
				> .head,
				> .graph { font-size: 13px;}
				> .head1,
				> .head2 { margin: 40px 0 0;}
			}
		}
		// 注釈
		.columnwrap + p {
			text-align: right;
			@include media-breakpoint-down(lg) {}
			@include media-breakpoint-down(md) {}
			@include media-breakpoint-down(sm) { text-align: left; font-size: 90%;}
			@include media-breakpoint-down(xs) { font-size: 80%;}
		}
	}
	// 
	.footwrap {
		margin: 65px 0 0;
		.txt {
			font-size: 16px;
			span.red { color: #ff3300;}
		}
		.strong {
			color: $keyColor;
			font-size: 28px;
		}
		@include media-breakpoint-down(lg) { margin: 60px 0 0;}
		@include media-breakpoint-down(md) {
			margin: 55px 0 0;
			.txt { font-size: 15px; }
		}
		@include media-breakpoint-down(sm) {
			margin: 50px 0 0;
			.txt { font-size: 14px; }
			.strong { font-size: 24px; }
		}
		@include media-breakpoint-down(xs) {
			margin: 40px 0 0;
			.txt { font-size: 15px; }
			.strong { font-size: 22px; }
		}
	}
}


// ==========================================

// erの強み

// ==========================================
main#mainContent.service section.advantage {
	padding: 120px 0 100px;
	background: $ligheBlue;
	@include media-breakpoint-down(lg) { padding: 100px 0 60px;}
	@include media-breakpoint-down(md) { padding: 70px 0 50px;}
	@include media-breakpoint-down(sm) { padding: 50px 0 40px;}
	@include media-breakpoint-down(xs) { padding: 40px 0 30px;}

	// 外国人が日本で働くためには「就労可能なビザ」の取得が必要です
	.raisedwrap {
		margin: 0 0 80px;
		.txt { font-size: 30px; }
		.txt br { display: none;}
		@include media-breakpoint-down(lg) {
			margin: 0 0 60px;
			.txt { font-size: 30px; }
			.txt br { display: block;}
		}
		@include media-breakpoint-down(md) {
			margin: 0 0 55px;
			.txt { font-size: 28px; }
		}
		@include media-breakpoint-down(sm) {
			margin: 0 0 50px;
			.txt { font-size: 26px; }
		}
		@include media-breakpoint-down(xs) {
			margin: 0 0 40px;
			.txt { font-size: 4.5vw; }
		}
	}

	// イラスト
	.illwrap {
		width: auto;
		max-width: 660px;
		margin: 0 auto;
		> div {
			width: 100%;
			bottom: 75px;
			left: 0;
			text-align: center;
		}
		.txt {
			display: block;
			width: auto;
			max-width: 500px;
			margin: 0 auto;
			padding: 0.75em 0;
			color: #FFF;
			font-size: 30px;
			background: $keyColor;
		}
		@include media-breakpoint-down(lg) {
			> div { bottom: 80px;}
			.txt {
				max-width: 460px;
				padding: 0.7em 0;
				font-size: 28px;
			}
		}
		@include media-breakpoint-down(md) {
			max-width: 500px;
			> div { bottom: 60px;}
			.txt {
				max-width: 360px;
				padding: 0.6em 0;
				font-size: 24px;
			}
		}
		@include media-breakpoint-down(sm) {
			max-width: 400px;
			> div { bottom: 45px;}
			.txt {
				max-width: 280px;
				padding: 0.5em 0;
				font-size: 22px;
			}
		}
		@include media-breakpoint-down(xs) {
			> div { bottom: 45px;}
			.txt {
				max-width: 80%;
				padding: 0.5em 0;
				font-size: 18px;
			}
		}
	}

	// erなら外国人就労者をお探しのお客さまの、
	// 多様なニーズにお応えできます。
	.promisewrap {
		margin: 65px 0 0;
		.txt { font-size: 32px; }	
		@include media-breakpoint-down(lg) {
			margin: 60px 0 0;
			.txt { font-size: 30px; }	
		}
		@include media-breakpoint-down(md) {
			margin: 50px 0 0;
			.txt { font-size: 28px; }	
		}
		@include media-breakpoint-down(sm) {
			margin: 40px 0 0;
			.txt { font-size: 22px; }	
		}
		@include media-breakpoint-down(xs) {
			margin: 30px 0 0;
			.txt { font-size: 3.8vw; }	
		}
	}
	.row {
		.num1 { order: 1; margin-top: 80px;}
		.num2 { order: 2; margin-top: 80px;}
		.num3 { order: 3; margin-top: 80px;}
		.cont1 { order: 4;}
		.cont2 { order: 5;}
		.cont3 { order: 6;}
		.num4 { order: 7; margin-top: 45px;}
		.num5 { order: 8; margin-top: 45px;}
		.num6 { order: 9; margin-top: 45px;}
		.cont4 { order: 10;}
		.cont5 { order: 11;}
		.cont6 { order: 12;}
		@include media-breakpoint-down(sm) {
			.num1 { order: 1;}
			.cont1 { order: 2;}
			.num2 { order: 3; margin-top: 40px;}
			.cont2 { order: 4;}
			.num3 { order: 5; margin-top: 40px;}
			.cont3 { order: 6;}
			.num4 { order: 7; margin-top: 40px;}
			.cont4 { order: 8;}
			.num5 { order: 9; margin-top: 40px;}
			.cont5 { order: 10;}
			.num6 { order: 11; margin-top: 40px;}
			.cont6 { order: 12;}
		}
	}
	.point_num {
		display: block;
		text-align: center;
		img {
			display: block;
			width: 48px;
			height: auto;
			margin: 0 auto;
		}
		span {
			display: block;
			margin: 1.25em 0;
			padding: 0;
			line-height: 1.4;
			color: $keyColor;
			@include media-breakpoint-down(lg) { margin: 1.15em 0;}
			@include media-breakpoint-down(md) { margin: 1em 0; font-size: 15px;}
			@include media-breakpoint-down(sm) { margin: 1.15em 0; font-size: 18px;}
			@include media-breakpoint-down(xs) { margin: 1em 0; font-size: 16px;}
		}
	}
	.point_cont {
		.cont_in {
			display: block;
			margin: 0;
			background: #FFF;
			border-radius: 18px;
			overflow: hidden;
			height: 100%;
			@include media-breakpoint-down(xs) { border-radius: 14px;}
			.txtwrap {
				display: block;
				padding: 2em 1.75em;
				font-size: 14px;
				@include media-breakpoint-down(lg) { padding: 1.8em 1.75em;}
				@include media-breakpoint-down(md) { padding: 1.6em 1.5em;}
				@include media-breakpoint-down(sm) { padding: 1.5em 1.375em;}
				@include media-breakpoint-down(xs) { padding: 1.4em 1.25em;}
			}
		}
	}
}


// ==========================================

//  独自のサポート体制

// ==========================================
main#mainContent.service .support {
	padding: 120px 0 100px;
	background: #FFF;
	@include media-breakpoint-down(lg) { padding: 100px 0 60px;}
	@include media-breakpoint-down(md) { padding: 70px 0 50px;}
	@include media-breakpoint-down(sm) { padding: 50px 0 40px;}
	@include media-breakpoint-down(xs) { padding: 40px 0 30px;}

	// 
	.substance {
		margin: 100px 0 0;
		@include media-breakpoint-down(lg) { margin: 100px 0 0;}
		@include media-breakpoint-down(md) { margin: 80px 0 0;}
		@include media-breakpoint-down(sm) { margin: 60px 0 0;}
		@include media-breakpoint-down(xs) { margin: 50px 0 0;}
		> dl {
			display: block;
			&:first-child {
				margin: 0 0 45px;
				@include media-breakpoint-down(md) { margin: 0 0 40px;}
			}
			> dt {
				display: block;
				padding: 0.75em 0;
				width: 340px;
				text-align: center;
				color: #FFF;
				font-size: 16px;
				background: #0959a7;
				@include media-breakpoint-down(lg) {
					width: 320px;
					font-size: 16px;
				}
				@include media-breakpoint-down(md) {
					padding: 0.8em 0;
					width: 320px;
					font-size: 15px;
				}
				@include media-breakpoint-down(sm) { width: 100%; }
			}
			> dd {
				display: block;
				margin: 0;
				padding: 30px 40px;
				font-size: 20px;
				background: $baseColor;
				span.red { color: #eb1818;}
				@include media-breakpoint-down(lg) {
					padding: 28px 38px;
					font-size: 19px;
				}
				@include media-breakpoint-down(md) {
					padding: 22px 34px;
					font-size: 18px;
				}
				@include media-breakpoint-down(sm) {
					padding: 18px 30px;
					font-size: 17px;
				}
				@include media-breakpoint-down(xs) {
					padding: 1em 24px;
					font-size: 16px;
				}
			}
		}
	}

	// 配属までのスケジュール
	.schedule {
		margin: 50px 0 0;
		.bighead {
			margin: 0 0 1em;
			color: #0959a7;
			font-size: 30px;
			@include media-breakpoint-down(lg) { font-size: 28px;}
			@include media-breakpoint-down(md) { font-size: 26px;}
			@include media-breakpoint-down(sm) { font-size: 22px;}
			@include media-breakpoint-down(xs) { font-size: 18px;}
		}
		.wrap {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.column {
				display: block;
				width: calc(50% - 5px);
				.columnin {
					display: block;
					height: 100%;
					background: $baseColor;
				}
				@include media-breakpoint-down(sm) {
					width: 100%;
					&:last-child { margin-top: 35px;}
				}
			}
		}
		.head {
			padding: 0.75em 0;
			text-align: center;
			color: #FFF;
			font-size: 22px;
			background: #378adb;
			@include media-breakpoint-down(lg) {
				padding: 0.8em 0;
				font-size: 20px;
			}
			@include media-breakpoint-down(md) {
				padding: 0.85em 0;
				font-size: 18px;
			}
			@include media-breakpoint-down(sm) {
				padding: 0.9em 0;
				font-size: 17px;
			}
			@include media-breakpoint-down(xs) {
				padding: 0.95em 0;
				font-size: 16px;
			}
		}
		ul.step {
			display: block;
			margin: 30px 0;
			padding: 0 30px;
			list-style: none;
			position: relative;
			&:after {
				content: "";
				display: block;
				width: 20px;
				height: 100%;
				position: absolute;
				top: 0;
				left: calc(50% - 10px);
				z-index: 0;
			}
			@include media-breakpoint-down(lg) {
				margin: 28px 0;
				padding: 0 28px;
				&:after { width: 18px; left: calc(50% - 9px); }
			}
			@include media-breakpoint-down(md) {
				margin: 26px 0;
				padding: 0 26px;
				&:after { width: 16px; left: calc(50% - 6px); }
			}
			@include media-breakpoint-down(sm) {
				margin: 20px 0;
				padding: 0 20px;
				&:after { width: 14px; left: calc(50% - 7px); }
			}
			@include media-breakpoint-down(xs) {
				margin: 16px 0;
				padding: 0 16px;
			}

			&.gr {
				&:after { background: #d1f7a0;}
				> li:not(.diff) { background: #8fd830; }
			}
			&.og {
				&:after { background: #ffe199;}
				> li:not(.diff) { background: #efb11d; }
			}
			li:not(.diff) {
				position: relative;
				z-index: 1;
				display: block;
				width: 100%;
				padding: 0.8em 0;
				text-align: center;
				line-height: 1;
				color: #FFF;
				font-size: 20px;
				font-weight: 700;
				border-radius: 50px;
				@include media-breakpoint-down(lg) {
					font-size: 17px;
				}
				@include media-breakpoint-down(md) {
					padding: 1em 0;
					font-size: 14px;
				}
				@include media-breakpoint-down(sm) {
					padding: 1em 0;
					font-size: 16px;
				}
				@include media-breakpoint-down(xs) {
					font-size: 13px;
				}
			}
			li.diff {
				position: relative;
				z-index: 1;
				margin: 26px auto;
				p {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 160px;
					line-height: 1;
					text-align: center;
					margin: 0 auto;
					padding: 0.25em 0;
					color: #e11414;
					font-size: 26px;
					font-weight: 900;
					background: #FFF;
					border: #df4c6b solid 2px;
					border-radius: 3px;
					small {
						font-size: 16px;
						font-weight: 900;
					}
				}
				@include media-breakpoint-down(lg) {
					margin: 24px auto;
					p {
						width: 140px;
						padding: 0.2em 0;
						font-size: 22px;
						small { font-size: 14px; }
					}
				}
				@include media-breakpoint-down(md) {
					margin: 22px auto;
					p {
						width: 120px;
						padding: 0.3em 0;
						font-size: 18px;
						small { font-size: 13px; }
					}
				}
				@include media-breakpoint-down(sm) { margin: 20px auto;}
				@include media-breakpoint-down(xs) { margin: 18px auto;}
			}
		}
	}

	//マネージャークラス
	.manager {
		margin: 50px 30px 0;
		dl {
			dt {
				display: block;
				margin: 0 0 0.5em;
				color: #378adb;
				font-size: 18px;
			}
			dd {
				display: block;
				font-size: 14px;
			}
		}
		@include media-breakpoint-down(lg) { margin: 45px 28px 0;}
		@include media-breakpoint-down(md) {
			margin: 40px 26px 0;
			dl {
				dt {
					margin: 0 0 0.4em;
					font-size: 16px;
				}
				dd {
					font-size: 13px;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			margin: 30px 20px 0;
			dl {
				dt {
					margin: 0 0 0.3em;
					font-size: 15px;
				}
				dd {
					font-size: 12px;
				}
			}
		}
		@include media-breakpoint-down(xs) {
			margin: 25px 16px 0;
		}
	}
}


// ==========================================

// よくあるご質問

// ==========================================
main#mainContent.service .faq {
	padding: 120px 0;
	background: $ligheBlue;
	@include media-breakpoint-down(lg) { padding: 100px 0 80px;}
	@include media-breakpoint-down(md) { padding: 70px 0 50px;}
	@include media-breakpoint-down(sm) { padding: 50px 0 35px;}
	@include media-breakpoint-down(xs) { padding: 40px 0 25px;}
	.wrap {
		> dl {
			display: block;
			margin: 0 0 30px;
			background: #FFF;
			&:last-child{ margin: 0;}
			dt {
				background: $keyColor;
				border-bottom: $ligheBlue solid 1px;
				span {
					display: block;
					width: 72px;
					height: 100%;
					text-align: center;
					line-height: 1;
					color: #FFF;
					font-size: 18px;
					@include media-breakpoint-down(md) { width: 68px; font-size: 17px;}
					@include media-breakpoint-down(sm) { width: 60px; font-size: 16px;}
					@include media-breakpoint-down(xs) { width: 50px; font-size: 15px;}
				}
				p {
					flex: 1;
					display: block;
					width: auto;
					margin: 0;
					padding: 1.25em 1.5em;
					background: #FFF;
					@include media-breakpoint-down(md) { padding: 0.75em 1em; font-size: 15px;}
					@include media-breakpoint-down(sm) { padding: 0.75em 1em; font-size: 14px;}
				}
			}
			dd {
				display: block;
				margin: 0;
				padding: 22px 26px;
				font-size: 14px;
				line-height: 2;
				@include media-breakpoint-down(lg) { padding: 20px 24px;}
				@include media-breakpoint-down(md) { padding: 18px 22px;}
				@include media-breakpoint-down(sm) { padding: 16px 20px; font-size: 13px;}
				@include media-breakpoint-down(xs) { padding: 1em 1.5em;}
			}
		}
	}
}


// ==========================================

// 資料請求・お問い合わせはこちら

// ==========================================
main#mainContent.service .inquiry {
	background: #FFF;
	.wrap {
		width: 100%;
		max-width: 600px;
		margin: 0 auto;
		padding: 100px 0;
		@include media-breakpoint-down(lg) { max-width: 550px; padding: 90px 0;}
		@include media-breakpoint-down(md) { max-width: 500px; padding: 75px 0;}
		@include media-breakpoint-down(sm) { max-width: 500px; padding: 50px 0;}
		@include media-breakpoint-down(xs) { width:auto; max-width: auto; margin: 0 15px; padding: 40px 0;}
		a {
			display: block;
            margin: 0;
            padding: 1.75em 0;
			line-height: 1;
			text-align: center;
            color: #000;
			font-size: 20px;
			font-weight: 700;
            background: #ffcb3e;
            border-radius: 55px;
            position: relative;
            overflow: hidden;
			transition: color 0.16s 0.06s;
			@include media-breakpoint-down(lg) {
				padding: 1.75em 0;
				font-size: 18px;
			}
			@include media-breakpoint-down(md) {
				padding: 1.75em 0;
				font-size: 16px;
			}
			@include media-breakpoint-down(sm) {
				padding: 1.75em 0;
				font-size: 14px;
			}
			@include media-breakpoint-down(xs) {
				padding: 1.75em 0;
				font-size: 13px;
			}
            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 0;
                background: $keyColor;
                position: absolute;
                top: 50%;
                left: 0;
                transform: rotate(-12deg);
                transition: all 0.16s;
            }
            span { position: relative; z-index: 1;}
            @include media-breakpoint-up(md) {
                &:hover {
                    color: #FFF;
                    &:after {
                        height: 100%;
                        top: 0;
                        transform: rotate(0);
                    }
                }
            }
        }
	}
}
